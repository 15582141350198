import axios from 'axios';
import { AES } from 'crypto-js';

let urlendpoint = (process.env.REACT_APP_API_PAYMENT_ENDPOINT || window.REACT_APP_API_PAYMENT_ENDPOINT);
const secretKey = "invitegt";

if (process.env.REACT_APP_ENV === 'development') {
  urlendpoint = "https://sandboxpayments.qpaypro.com";
}

const getPaymentPage = async (body) => {
  let compose_endpoint = "";
  let updatedBody = body;
  if (process.env.REACT_APP_ENV === 'development')
  {
    compose_endpoint = urlendpoint + "/checkout/api_v1";
  }else
  {
    compose_endpoint = urlendpoint + "/payment/managePay";
    updatedBody = await getEncryptedString(updatedBody);
    updatedBody = { "data": updatedBody };
  }
  return await axios.post(
    compose_endpoint, updatedBody)
    .then(res => {
      return res.data;
    })
    .catch(error => error.response.data);
}

const getPaymentObject = async (
  userInformation,
  weddinInformation,
  paymentInformation,
  successUrl,
) => {
  return {
    "x_login": "visanetgt_qpay",
    "x_private_key": "88888888888",
    "x_api_secret": "99999999999",
    "x_description": "Invitation number: " + weddinInformation.wedding,
    "x_amount": weddinInformation.price_amount,
    "x_freight": "0.00",
    "x_currency_code": "GTQ",
    "x_product_id": weddinInformation.wedding,
    "x_audit_number": "123456",
    "x_line_item": "Invitacion: " + userInformation.billingInformation.firstName + " " + userInformation.billingInformation.lastName,
    "x_email": userInformation.billingInformation.email,
    "x_fp_sequence": "123456",
    "x_fp_timestamp": "123456",
    "x_invoice_num": "123456",
    "x_first_name": userInformation.billingInformation.firstName,
    "x_last_name": userInformation.billingInformation.lastName,
    "x_company": "C/F",
    "x_address": userInformation.billingInformation.address1,
    "x_city": userInformation.billingInformation.city,
    "x_state": userInformation.billingInformation.state,
    "x_zip": userInformation.billingInformation.zip,
    "x_country": userInformation.billingInformation.country,
    "x_ship_to_first_name": userInformation.billingInformation.firstName,
    "x_ship_to_last_name": userInformation.billingInformation.lastName,
    "x_phone": userInformation.billingInformation.phone,
    "x_ship_to_address": userInformation.billingInformation.address1,
    "x_ship_to_city": userInformation.billingInformation.city,
    "x_ship_to_state": userInformation.billingInformation.state,
    "x_ship_to_zip": userInformation.billingInformation.zip,
    "x_ship_to_country": userInformation.billingInformation.country,
    "x_relay_response": true,
    "x_relay_url": successUrl,
    "x_type": "AUTH_ONLY",
    "x_method": "CC",
    "visaencuotas": "no",
    "cc_number": paymentInformation.ccNumber,
    "cc_exp": paymentInformation.ccExpiration,
    "cc_cvv2": paymentInformation.ccCVV,
    "cc_name": paymentInformation.nameOnCard,
    "cc_type": "visa",
  };
}

const getEncryptedString = async (body) => {
  const encryptedMessage = await AES.encrypt(JSON.stringify(body), secretKey).toString();
  return encryptedMessage;
}

const paymentMethods = { 
  getPaymentObject,
  getEncryptedString,
  getPaymentPage,
}

export default paymentMethods
