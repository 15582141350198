import React from 'react'
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  updateWeddingDAte,
  updateBoyfrindName,
  updateBoyfriendLastname,
  updateGirlfriendName,
  updateGirlfriendLastname,
  updateWeddingCity,
  updateWeddingConfirmationDate
} from '../../../features/wedding-state/wedding-state';
import Tooltip from '@mui/material/Tooltip';

const AdminSidebarBasicInformation = () => {
  const subimtHandler = (e) => {
    e.preventDefault();
  }

  const basicInformationSection = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  const updateBasicInformation = (e) => {
    if (e.id === "infobasic_date") {
      dispatch(updateWeddingDAte(e.value))
    } else if (e.id === "infobasic_boyName") {
      dispatch(updateBoyfrindName(e.value))
    } else if (e.id === "infobasic_boyLast") {
      dispatch(updateBoyfriendLastname(e.value))
    } else if (e.id === "infobasic_girlName") {
      dispatch(updateGirlfriendName(e.value))
    } else if (e.id === "infobasic_girlLast") {
      dispatch(updateGirlfriendLastname(e.value))
    } else if (e.id === "infobasic_city") {
      dispatch(updateWeddingCity(e.value))
    } else if (e.id === "infobasic_confirmation_date") {
      dispatch(updateWeddingConfirmationDate(e.value))
    }
  }

  return (
    <div>
      <form onSubmit={subimtHandler}>
        <div>
          <input id="infobasic_date" type="text" className="form-control" placeholder="dd/mm/aaaa"
            value={(basicInformationSection.wedding_date) ? basicInformationSection.wedding_date : ''}
            onChange={(e) => updateBasicInformation(e.target)}
            style={{ width: '185px' }}
            disabled
          />
        </div>
        <div>
          <input id="infobasic_boyName" type="text" className="form-control" placeholder="Name boyfriend"
            value={(basicInformationSection.boy_name) ? basicInformationSection.boy_name : ''}
            onChange={(e) => updateBasicInformation(e.target)}
            style={{ width: '185px' }}
          />
        </div>
        <div>
          <input id="infobasic_boyLast" type="text" className="form-control" placeholder="Lastname boyfriend"
            value={(basicInformationSection.boy_last) ? basicInformationSection.boy_last : ''}
            onChange={(e) => updateBasicInformation(e.target)}
            style={{ width: '185px' }}
          />
        </div>
        <div>
          <input id="infobasic_girlName" type="text" className="form-control" placeholder="Name girlfriend"
            value={(basicInformationSection.girl_name) ? basicInformationSection.girl_name : ''}
            onChange={(e) => updateBasicInformation(e.target)}
            style={{ width: '185px' }}
          />
        </div>
        <div>
          <input id="infobasic_girlLast" type="text" className="form-control" placeholder="Lastname girlfriend"
            value={(basicInformationSection.girl_last) ? basicInformationSection.girl_last : ''}
            onChange={(e) => updateBasicInformation(e.target)}
            style={{ width: '185px' }}
          />
        </div>
        <div>
          <input id="infobasic_city" type="text" className="form-control" placeholder="City"
            value={(basicInformationSection.city) ? basicInformationSection.city : ''}
            onChange={(e) => updateBasicInformation(e.target)}
            style={{ width: '185px' }}
          />
        </div>
        <div>
          <span>Fecha limite de confirmación</span>
          <Tooltip title="Fecha limite de confirmación para tus invitados" placement='top'>
            <input id="infobasic_confirmation_date" type="date" className="form-control" placeholder="dd/MM/yyyy"
              value={(basicInformationSection.wedding_confirmation_date) ? basicInformationSection.wedding_confirmation_date : ''}
              onChange={(e) => updateBasicInformation(e.target)}
              style={{ width: '185px' }}
            />
          </Tooltip>
        </div>
      </form>
    </div>
  )
}

export default AdminSidebarBasicInformation
