import Navbar from '../../../components/Navbar';
import SimpleSlider from '../../../components/Examples/hero';
import Footer from '../../../components/footer';
import ExampleComponent from '../../../components/NewTemplateStyles/Style1/components/Example';
import { useLocation } from 'react-router-dom';
import YTComponent from '../../../components/ReactPlayer';

const ExampleStyle5 = () => {
  const location = useLocation();
  const ytid = "https://www.youtube.com/watch?v=kPhpHvnnn0Q"

  return (
    <div>
      <Navbar onPreview={true} />
      <SimpleSlider />
      <YTComponent
        url={ytid}
        autoplay={true} onPreview={true}
      />      
      <ExampleComponent />
      {
        (location.pathname.startsWith('/ejemplo'))? "" : <Footer />
      }
    </div>
  )
}

export default ExampleStyle5;
