import { useAppSelector } from '../../app/hooks';
import 'react-fancybox/lib/fancybox.css'
import Sectiontitle from '../section-title'
import './style.css'

import GalleryList from '../NewTemplateStyles/Style2/components/GalleryList';

const Gallery = () => {

  const galleryobject = useAppSelector((state) => (state.wedding));

  return (
    <div id="home" className="Gallery-section section-padding pb-70 h-gallery">
      <Sectiontitle section={'Nuestra Galería'} />
      <div className="container">
        <GalleryList weddingInformation={galleryobject} />
      </div>
    </div>
  );
}

export default Gallery;
