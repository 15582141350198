import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StepperAdmin from '../../components/Stepper'
import { useAppSelector } from '../../app/hooks';
import Couple from '../../components/couple';
import Couple2 from '../../components/couple2';
import SimpleSlider from '../../components/hero';
import PreviewHero from '../../components/hero3';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import People from '../../components/people';
import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar'
import PersonalForm from '../../components/personal-form'
import Saveday from '../../components/countdown'
import Footer from '../../components/footer';
import GuestList from '../../components/GuestList';
import SidebarCountdown from '../../components/SidebarCountdown';
import WeddingCeremony from '../../components/locationCeremony/weddingCeremony';
import WeddingParty from '../../components/locationReception/WeddingParty';
import WeddingGifts from '../../components/locationGifts/WeddingGifts';
import { useTour } from '@reactour/tour'
import Music from '../../components/AdminSidebarComponents/MusicInformation';
import Dashboard from '../../components/Dashboard';
import SidebarStyles from '../../components/SidebarStyles';
import DashboardMobileMenu from '../../components/DashboardMobileMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import CoupleStyle5 from '../../components/NewTemplateStyles/Style1/components/Couple';
import StoryStyle5 from '../../components/NewTemplateStyles/Style1/components/Story';
import { EventCeremony, EventParty, EventGift } from '../../components/NewTemplateStyles/Style1/components/Events/EventSections';
import SampleTemplateStyle5 from '../../components/AdminSidebarComponents/SampleTemplateStyle5';
import EventStyle6 from '../../components/NewTemplateStyles/Style2/components/Events';
import SimpeSliderStyle6 from '../../components/NewTemplateStyles/Style2/components/Slider';
import CoupleStyle6 from '../../components/NewTemplateStyles/Style2/components/Couple';
import StoryStyle6 from '../../components/NewTemplateStyles/Style2/components/Story';
import Collaborator from '../../components/Collaborators';

let obj_steps = {
  1: <PersonalForm />,
  2: {
    0: <SimpleSlider />,
    1: <SimpeSliderStyle6 />,
    2: <SimpleSlider />,
    3: <PreviewHero />,
  },
  3: <Saveday />,
  4: {
    0: <Couple />,
    1: <CoupleStyle6 />,
    2: <CoupleStyle5 />,
    3: <Couple2 />,
  },
  5: <Welcome />,
  6: {
    0: <Story />,
    1: <StoryStyle6 />,
    2: <StoryStyle5 />,
    3: <Story />,
  },
  7: <People />,
  8: <Location />,
  9: <Gallery />,
  10: <Rsvp />,
  11: <Gift />,
  12: <Footer />,
  13: <GuestList />,
  14: <SidebarCountdown />,
  15: {
    0: <WeddingCeremony />,
    1: <EventStyle6 showParty={false} />,
    2: <SampleTemplateStyle5 message={"Ceremonia de bodas"} component={<EventCeremony/>} />,
    3: <WeddingCeremony />,
  },
  16: {
    0: <WeddingParty />,
    1: <EventStyle6 showCeremony={false} />,
    2: <SampleTemplateStyle5 message={"Fiesta de bodas"} component={<EventParty/>} />,
    3: <WeddingParty />,
  },
  17: {
    0: <WeddingGifts />,
    1: <WeddingGifts />,
    2: <SampleTemplateStyle5 message={"Regalos de bodas"} component={<EventGift/>} />,
    3: <WeddingGifts />,
  },
  18: <Music />,
  19: <SidebarStyles />,
  20: <Collaborator />
}

const Adminpage = () => {
  let template = useAppSelector((state) => state.wedding.template);
  let userInformation = useAppSelector((state) => state.user);
  let counterStep = useAppSelector((state) => state.counterStep.value);
  const checkifmobilemediaquery = useMediaQuery('(max-width: 991px)');
  const { setIsOpen } = useTour();

  useEffect(() => {
    if (userInformation && userInformation.weddingArray && userInformation.weddingArray.length === 0) {
      setIsOpen(true);
    }
  }, [userInformation, setIsOpen]);

  const location = useLocation();

  const getReturnPath = () => {
    if (template === 1 || template === "1") {
      return '/home6'; //fancy
    } else if (template === 2 || template === "2") {
      return '/home5'; // minimalista
    } else if (template === 3 || template === "3") {
      return '/home3'; // festivo
    }else{
      return '/home';
    }
  }

  return (
    <div key={obj_steps[1]}>
      <Navbar />
      <StepperAdmin />
      <div className='back-to-home-arrow'>
        <ul>
          {
          location.pathname === '/Admin'
            ? 
            (
                <li>
                  <Link className='home-link' to={getReturnPath()}>
                    <i className="fa fa-arrow-left" title='Regresar a inicio'>
                    </i>
                  </Link>
                </li>
            )
            : ""
          }
        </ul>
      </div>
      {
        (checkifmobilemediaquery)? <DashboardMobileMenu /> : ""
      }
      <div className="blog-pg-section">
        <div>
          <Dashboard
            elementInvite={
              (
                (counterStep === 2) 
                || (counterStep === 4) 
                || (counterStep === 6)
                || (counterStep === 15)
                || (counterStep === 16)
                || (counterStep === 17)
              )
              ? obj_steps[counterStep][template] 
              : obj_steps[counterStep]}
            checkifmobilemediaquery
          />
        </div>
      </div>
    </div>
  )
}

export default Adminpage;
