import React from 'react';
import './style.css';
import story1 from '../../NewTemplateStyles/Style2/images/couple-1.jpg';
import story2 from '../../NewTemplateStyles/Style2/images/couple-2.jpg';
import story3 from '../../NewTemplateStyles/Style2/images/couple-3.jpg';

const Story = () => {
  return (
    <div id="story" className="story-area section-padding">
      <div className="container">
        <div className="col-l2">
          <div className="section-title text-center">
            <h2>Nuestra Historia</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="story clearfix">
              <div className="story-top"></div>
              <div className="content-wrapper">

                <div className="item h-story">
                  <div className="story-icon">
                    <span className="flaticon-birds-in-love"></span>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className={'single-image-wrap'}>
                        <img src={story1} alt="" className="single-image" />
                      </div>
                    </div>
                    <h2>{"Nuestra Primera Cita"}</h2>
                    <span className="date">{"Diciembre 25, 2025"}</span>
                    {
                      <p className="paragraph">
                        <span className='pspan'>{
                          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some,"
                        }</span></p>
                    }
                    <div className="story-button">
                      <button className="readmore-btn">{"Leer más"}</button>
                    </div>
                  </div>
                </div>

                <div className="item h-story">
                  <div className="story-icon">
                    <span className="flaticon-birds-in-love"></span>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className={'single-image-wrap'}>
                        <img src={story2} alt="" className="single-image" />
                      </div>
                    </div>
                    <h2>{"Nuestra Primer Beso"}</h2>
                    <span className="date">{"Enero 25, 2026"}</span>
                    {
                      <p className="paragraph">
                        <span className='pspan'>{
                          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some,"
                        }</span></p>
                    }
                    <div className="story-button">
                      <button className="readmore-btn">{"Leer más"}</button>
                    </div>
                  </div>
                </div>

                <div className="item h-story">
                  <div className="story-icon">
                    <span className="flaticon-birds-in-love"></span>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className={'single-image-wrap'}>
                        <img src={story3} alt="" className="single-image" />
                      </div>
                    </div>
                    <h2>{"Nuestro Compromiso"}</h2>
                    <span className="date">{"Febrero 25, 2026"}</span>
                    {
                      <p className="paragraph">
                        <span className='pspan'>{
                          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some,"
                        }</span></p>
                    }
                    <div className="story-button">
                      <button className="readmore-btn">{"Leer más"}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="story-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Story;

