import React from "react";
import Stack from '@mui/material/Stack';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { updateToggleDrawer } from '../../features/wedding-state/wedding-state';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Link, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import sectionsImages from '../../features/loadobjects';
import { updateLoadPreview, loadingComponent } from '../../features/wedding-state/wedding-state';

const DashboardMobileMenu = () => {
  const dispatch = useAppDispatch();
  const toggleWeddingInformation = useAppSelector((state) => (state.wedding));

  const toggleDrawer = () => {
    dispatch(updateToggleDrawer(!toggleWeddingInformation.toggle_drawer));
  };
  const { route } = useAuthenticator(context => [context.route]);
  const location = useLocation();
  const currentPath = location.pathname;

  const loadAllSections = async () => {
    try {
      dispatch(loadingComponent(true));
      dispatch(updateLoadPreview(true));
      await sectionsImages.getAllSections(toggleWeddingInformation, dispatch);
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(updateLoadPreview(false));
      dispatch(loadingComponent(false));
    }
  }

  return (
    <div style={{ position: 'absolute', left: '40%', right: '10%' }}>
      <Stack direction="row" spacing={1}>
        <IconButton className='arrow-drawer' aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        {
          (
            route === 'authenticated'
            && currentPath === '/Admin'
            && toggleWeddingInformation.wedding !== 0
            && toggleWeddingInformation.idTransaction === ''
          ) ?
            <Link to="/shopping">
              <IconButton color="primary" onClick={async () => { await loadAllSections() }} aria-label="add to shopping cart">
                <AddShoppingCartIcon />
              </IconButton>
            </Link> : null
        }
        {
          (
            route === 'authenticated'
            && currentPath === '/Admin'
            && toggleWeddingInformation.wedding !== 0
          ) ?
            <Link to='/complete'>
              <IconButton color="secondary" onClick={async () => { await loadAllSections() }} aria-label="add an alarm">
                <VisibilityIcon />
              </IconButton>
            </Link>
            : null
        }
      </Stack>
    </div>
  );
}

export default DashboardMobileMenu;
