import React, { useState } from 'react';
import '../rsvp/style.css'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';

const Policies = () => {

  const [isLanguage, setIsLanguage] = useState('es');

  const handleChange = (event) => {
    setIsLanguage(event.target.value);
  };

  return (
    <div id="Policies" className="rsvp-area go-rsvp-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-2 col-md-10 offset-md-1">
            <div className="rsvp-wrap">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={isLanguage}
                label="Language"
                onChange={handleChange}
              >
                <MenuItem value='es'>Español</MenuItem>
                <MenuItem value='en'>English</MenuItem>
              </Select>
              <div className="col-12">
                <div className="section-title section-title4 text-center">
                  <h2>Por parte del equipo Invitegt</h2>
                  <p>Gracias por incluirnos en este gran dia</p>
                </div>
              </div>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {
                    (isLanguage === 'es') ?
                      <Card>
                        <CardContent>
                          <Typography variant="h4">Términos de Servicio</Typography>
                          <Typography variant="h6">
                            Política de Privacidad de Invitegt
                          </Typography>
                          <Typography variant="h6">
                            Fecha de entrada en vigencia: 2023
                          </Typography>

                          <Typography variant="body1">
                            Bienvenido a Invitegt. Esta Política de Privacidad explica cómo recopilamos, usamos, compartimos y protegemos la información personal de nuestros usuarios. Al utilizar Invitegt, aceptas estas prácticas.
                          </Typography>

                          <Typography variant="h6">
                            Recopilación de Información:
                          </Typography>
                          <Typography variant="body1">
                            Recopilamos información personal que proporcionas al usar nuestros servicios, como tu nombre, dirección de correo electrónico, fecha de boda, preferencias y otros detalles del evento. Utilizamos APIs de Google para ciertas funcionalidades, pero no recopilamos información financiera.
                          </Typography>

                          <Typography variant="h6">
                            Uso de la Información:
                          </Typography>
                          <Typography variant="body1">
                            Utilizamos tus datos para personalizar tu experiencia y ofrecer planes e ideas para tu boda. Tus datos no se comparten con terceros para fines de marketing.
                          </Typography>

                          <Typography variant="h6">
                            Protección de Datos:
                          </Typography>
                          <Typography variant="body1">
                            Tomamos medidas para proteger tu privacidad, incluyendo medidas de seguridad físicas y electrónicas, aunque ninguna transmisión por Internet es completamente segura.
                          </Typography>

                          <Typography variant="h6">
                            Acceso y Control:
                          </Typography>
                          <Typography variant="body1">
                            Tienes derecho a acceder, corregir o eliminar tus datos personales. Contáctanos en fran.rene.dev@gmail.com para ejercer tus derechos.
                          </Typography>

                          <Typography variant="h6">
                            No Uso de Datos para Otros Fines:
                          </Typography>
                          <Typography variant="body1">
                            Nos comprometemos a no utilizar su información personal para ningún otro propósito que no sea proporcionar los servicios relacionados con su boda. No vendemos, alquilamos ni compartimos su información con terceros para fines de publicidad.
                          </Typography>

                          <Typography variant="h6">
                            Cambios en la Política de Privacidad:
                          </Typography>
                          <Typography variant="body1">
                            Esta Política de Privacidad puede actualizarse ocasionalmente. Cualquier cambio se publicará en esta página y la fecha de entrada en vigencia se actualizará.
                          </Typography>

                          <Typography variant="h6">
                            Cumplimiento con COPPA:
                          </Typography>
                          <Typography variant="body1">
                            Cumplimos con la Ley de Protección de la Privacidad Online de los Niños (COPPA) para usuarios menores de 13 años.
                          </Typography>

                          <Typography variant="body1">
                            Le agradecemos por confiar en Invitegt para ser parte de su día especial. Si tiene alguna pregunta sobre esta Política de Privacidad, no dude en contactarnos a través de fran.rene.dev@gmail.com.
                          </Typography>

                        </CardContent>
                      </Card> :

                      <Card>
                        <CardContent>
                          <Typography variant="h4">Terms of Service</Typography>
                          <Typography variant="h6">
                            Privacy Policy of Invitegt
                          </Typography>
                          <Typography variant="h6">
                            Effective Date: 2023
                          </Typography>

                          <Typography variant="body1">
                            Welcome to Invitegt. This Privacy Policy describes our practices regarding data collection, usage, and protection of personal information for our users. By using Invitegt, you agree to these practices.
                          </Typography>

                          <Typography variant="h6">
                            Collection of Information:
                          </Typography>
                          <Typography variant="body1">                            
                            We collect personal information you provide directly, including your name, email address, wedding date, preferences, and other event-related details. We use Google APIs for functionalities like sign-in but do not collect financial information, such as credit card numbers.
                          </Typography>

                          <Typography variant="h6">
                            Use of Information:
                          </Typography>
                          <Typography variant="body1">
                            Your data is used to personalize your experience with Invitegt, specifically for generating plans and ideas for your wedding. We disclose our use of Google APIs for these purposes. We do not share your data with third parties for marketing.
                          </Typography>

                          <Typography variant="h6">
                            Data Protection:
                          </Typography>
                          <Typography variant="body1">
                            We are committed to protecting your privacy and implement physical and electronic security measures. However, no internet transmission or electronic storage method is completely secure.
                          </Typography>

                          <Typography variant="h6">
                            Access and Control:
                          </Typography>
                          <Typography variant="body1">                            
                            You have rights to access, correct, or delete your personal information. Contact us at fran.rene.dev@gmail.com for these requests.
                          </Typography>

                          <Typography variant="h6">
                            No Use of Data for Other Purposes:
                          </Typography>
                          <Typography variant="body1">
                            We commit to not using your personal information for any purpose other than providing services related to your wedding. We do not sell, rent, or share your information with third parties for advertising purposes.
                          </Typography>

                          <Typography variant="h6">
                            Changes to Privacy Policy:
                          </Typography>
                          <Typography variant="body1">
                            This Privacy Policy may be updated occasionally. Any changes will be posted on this page, and the effective date will be updated.
                          </Typography>

                          <Typography variant="h6">
                            COPPA Compliance:
                          </Typography>
                          <Typography variant="body1">
                            We comply with the Children's Online Privacy Protection Act for users under 13.
                          </Typography>

                          <Typography variant="body1">
                            We appreciate your trust in Invitegt to be a part of your special day. If you have any questions about this Privacy Policy, feel free to contact us at fran.rene.dev@gmail.com.
                          </Typography>

                        </CardContent>
                      </Card>
                  }
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Policies;
