import StoryComponent from "../StoryComponent";
import { useAppSelector } from '../../../../../app/hooks';

const Story = () => {
  let weddingObj = useAppSelector((state) => state.wedding);
  return (
    <div id="story" className="story-box main-timeline-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box">
              <h2>Nuestra Historia</h2>
              <p>Se parte de nuestra historia </p>
            </div>
          </div>
        </div>
        {
          weddingObj.timeline_file_info.map((storyObject, index)=>(
            <StoryComponent key={"storeComponente"+index} storyObject={storyObject} index={index} />
          ))
        }
      </div>
    </div>
  )
}

export default Story;