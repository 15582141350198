import 'react-fancybox/lib/fancybox.css'
import Sectiontitle from '../../section-title'
import './style.css'

import GalleryList from '../../NewTemplateStyles/Style2/components/Example/galleryExample/index.js';

const Gallery = () => {
  return (
    <div id="gallery" className="Gallery-section section-padding pb-70 h-gallery">
      <Sectiontitle section={'Nuestra Galería'} />
      <div className="container">
        <GalleryList />
      </div>
    </div>
  );
}

export default Gallery;
