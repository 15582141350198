import React from 'react'
import './style.css';
import CreateNewGuestForm from '../CreateNewGuestForm';


const GuestList = () => {
  return (
    <div name="GuestList" className="guestlist-form-area">
      <div className="container">
        <div>
          <CreateNewGuestForm />
        </div>
      </div>
    </div>
  )
}

export default GuestList

