import { useAppSelector } from '../../../../../app/hooks';
import GalleryList from '../GalleryList';

const Gallery = () => {
  const weddingInformation = useAppSelector((state) => (state.wedding));

  return (
    <div id="fh5co-gallery" className="fh5co-section-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <h2>Galería</h2>
            <p>Nuestros Recuerdos.</p>
          </div>
        </div>
        <GalleryList weddingInformation={weddingInformation} />
      </div>
    </div>
  );
}

export default Gallery;
