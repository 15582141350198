import React from 'react';
import { useEffect } from 'react';
import CountDown from 'react-countdown';
import './style.css'
import { useAppSelector } from '../../app/hooks';
import countDownImage from '../../images/3.jpg'

const Saveday = () => {
  let countDownDate = useAppSelector((state) => state.wedding);
  useEffect(() => {
    let idObject = "count-down-img"
    if (countDownDate.countdown_file[0].url && countDownDate.countdown_file[0].url !== "") {
      document.getElementById(idObject).style.backgroundImage = `url(${countDownDate.countdown_file[0].url})`
    }else{
      document.getElementById(idObject).style.backgroundImage = `url(${countDownImage})`
    }
  });

  const formatNumber = (number) => String(number).padStart(2, '0');

  return (
    <div className="count-down-area h-countdown">
      <div className="count-down-sectionparallax">
        <div className="container">
          <div className="count-down-item count-down-item2">
            <div className="row">
              <div className="col-12 col-md-4 section-area" id="count-down-img">
                <div className="section-sub">
                </div>
                <h2 className="big"><span>Estamos a la espera.....</span> El gran día</h2>
              </div>
              <div className="col-12 col-md-8 clock-area">
                <div className="count-down-clock">
                  <CountDown
                    date={new Date((countDownDate.wedding_date) ? countDownDate.wedding_date : '2024-12-31')}
                    renderer={
                      ({ days, hours, minutes, seconds }) => {
                        const totalHours = (days * 24) + hours;
                        return (
                          <div>
                            <ul className="react-countdown">
                              <li><p className="digit">{totalHours}</p><p className="text">hour</p></li>
                              <li><p className="digit">{formatNumber(minutes)}</p><p className="text">min</p></li>
                              <li><p className="digit">{formatNumber(seconds)}</p><p className="text">Sec</p></li>
                            </ul>
                          </div>
                        )
                      }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Saveday;