import React from 'react'
import {
  useAppSelector,
  useAppDispatch
} from '../../../app/hooks';
import {
  updateFrontTittle,
  updateFrontImage,
  updateOldWedding,
  loadingComponent,
} from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api';
import docdbMethods from '../../../features/apis/docdb';
import FrontComponent from '../FrontComponent';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';
import '../MusicInformation/style.css'


const successAlert = (mensaje) => {
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}


const AdminSidebarFront = () => {

  const submitHandler = async () => {
    try {
      dispatch(loadingComponent(true));
      for (let i = 0; i < frontSection.front_file_info.length; i++) {
        if (document.getElementById("front-fileupload" + i).files.length === 1) {
          await uploadImageWedding(
            frontSection.front_file_info[i].filename,
            document.getElementById("front-fileupload" + i).files[0]
          )
        }
      }
      const updateObj = await docdbMethods.getDifferenceObj(frontSection, frontSection.previousdoc)
      await docdbMethods.updateDocument(frontSection.wedding, updateObj).then(res => {
        if (res.status === "error") {
          errorAlert('Error al salvar los cambios.')
          return;
        }
        dispatch(updateOldWedding(updateObj));
        successAlert("Cambios salvados correctamente.")
      })
    } catch (error) {
      alert("Error al salvar los cambios", error.message);
    } finally {
      dispatch(loadingComponent(false));
    }
  }

  const uploadImageWedding = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const frontSection = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  const updateFrontSection = (e) => {
    if (e.id === "front_title") {
      dispatch(updateFrontTittle(e.value))
    }
  }

  const updateFile = async (e) => {
    var obj_file = await s3request.buildObjImageFront(e, frontSection.wedding);
    var inputId = String(e.target.id).replace("front-fileupload", "");
    var copyArray = [...frontSection.front_file_info];
    copyArray[inputId] = obj_file;
    dispatch(updateFrontImage(copyArray))
  }

  const addingNewFront = () => {
    let newFrontObject = {
      filename: "",
      url: "",
      contenttype: "",
    }
    var copyArray = [...frontSection.front_file_info];
    copyArray.push(newFrontObject);
    dispatch(updateFrontImage(copyArray))
  }

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div>
          <input
            style={{ width: '185px' }}
            autoComplete="off"
            id='front_title'
            type="text"
            className="form-control"
            placeholder="Title front"
            value={
              (frontSection.front_tittle)
                ? frontSection.front_tittle
                : ''
            }
            onChange={(e) => updateFrontSection(e.target)}
          />
        </div>
        {
          frontSection.front_file_info.map((component, indexComponent) => (
            <FrontComponent
              key={"frontObject" + indexComponent}
              filename={component.filename}
              index={indexComponent}
              uploadMethod={updateFile}
            />
          ))
        }
        <br></br>
        <div className="text-center">
          <button
            id="newFront"
            type="button"
            style={{ position: "relative", borderRadius: "50%" }}
            onClick={() => addingNewFront()}
          ><i className="fa fa-plus"></i>
          </button>
        </div>
        <br></br>
        <div>
          <div className="text-center">
            <Tooltip
              title="Guardar cambios"
            >
              <CloudUploadTwoToneIcon
                onClick={() => submitHandler()}
                style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
              />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AdminSidebarFront
