import React, { useState } from 'react';
import './style.css'
import { useAppSelector } from '../../app/hooks';
import WeddingList from '../WeddingList';


const SearchById = () => {

  const [wedding, setWedding] = useState({
    wedding: '',
  });


  let disable_fields = [
    {
      name: "boy_name",
      placeholder: "Boyfriend's name",
      value: useAppSelector((state) => state.wedding.boy_name)
    },
    {
      name: "boy_last",
      placeholder: "Boyfriend's lastName",
      value: useAppSelector((state) => state.wedding.boy_last)
    },
    {
      name: "girl_name",
      placeholder: "Girlfriend's name",
      value: useAppSelector((state) => state.wedding.girl_name)
    },
    {
      name: "girl_last",
      placeholder: "Girlfriend's lastname",
      value: useAppSelector((state) => state.wedding.girl_last)
    },
  ]

  return (
    <div id="SearchById" className="row">
      <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="personal-form-wrap">
          <div className="col-12">
            <div className="section-title section-title4 text-center">
              <h2>Busca tu evento</h2>
              <p>Elige tu boda.</p>
            </div>
          </div>
          <form autoComplete="off" className="wedding-id-form">
            <div className="contact-form form-style">
              <WeddingList />
              <div className="row">
                {
                  disable_fields.map((newText) => (
                    <div key={'div_' + newText.name} className="col-12  col-sm-6">
                      <input type="text"
                        placeholder={newText.placeholder}
                        onChange={(e) => { setWedding({ ...wedding, [newText.name]: e.target.value }) }}
                        value={newText.value}
                        id={newText.name}
                        disabled
                        name={newText.name} />
                    </div>
                  ))
                }
              </div>
              <div className="col-12 text-center">
                <button id="submit" type="submit" className="submit">Buscar Evento</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SearchById;
