import React, { useEffect } from 'react';
import '../../css/style.css';
import '../../css/bootstrap.css';
import '../../css/icomoon.css';
import imgSliderSample from '../../images/2.jpg'
import { useAppSelector } from '../../../../../app/hooks';
import CountDown from 'react-countdown';
import ConfirmationButton from '../../../../ConfirmationButton';

const Slider = () => {
  const weddingInformation = useAppSelector((state) => (state.wedding));
  const rsvpReduxState = useAppSelector((state) => state.guest);

  useEffect(() => {
    let idObject = "fh5co-header";
    let url = (weddingInformation.front_file_info[0].url)
      ? weddingInformation.front_file_info[0].url
      : imgSliderSample;
    if (url && url !== "") {
      document.getElementById(idObject).style.background = `url(${url}) center center/cover`
    }
  });

  const formatNumber = (number) => String(number).padStart(2, '0');
  
  return (
    <div
      id="fh5co-header"
      className="fh5co-cover"
      role="banner"
      style={{
        backgroundImage: `url(${(
          weddingInformation.front_file_info[0].url
          && weddingInformation.front_file_info[0].url
        )
          ? weddingInformation.front_file_info[0].url
          : imgSliderSample
          })`
      }}
      data-stellar-background-ratio="0.5">
      <div id='home' className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <div className="display-t">
              <div className="display-tc animate-box" data-animate-effect="fadeIn">
                <h1>
                  {
                    (weddingInformation.boy_name)
                      ? weddingInformation.boy_name + ' '
                      : 'Harry '
                  }
                  &amp;
                  {
                    (weddingInformation.girl_name)
                      ? ' ' + weddingInformation.girl_name
                      : ' Nancy'
                  }
                </h1>
                <h2>
                  {
                    weddingInformation.front_tittle
                      ? weddingInformation.front_tittle + ' ' + rsvpReduxState.name
                      : 'Nos Vamos A Casar'
                  }
                </h2>
                <CountDown
                  date={new Date((weddingInformation.wedding_date) ? weddingInformation.wedding_date : '2024-12-31')}
                  renderer={({ days, hours, minutes, seconds }) =>
                    <div className="simply-countdown simply-countdown-one">
                      <div className="simply-section simply-days-section"><div><span className="simply-amount">{formatNumber(days)}</span><span className="simply-word">days</span></div></div>
                      <div className="simply-section simply-hours-section"><div><span className="simply-amount">{formatNumber(hours)}</span><span className="simply-word">hours</span></div></div>
                      <div className="simply-section simply-minutes-section"><div><span className="simply-amount">{formatNumber(minutes)}</span><span className="simply-word">minute</span></div></div>
                      <div className="simply-section simply-seconds-section"><div><span className="simply-amount">{formatNumber(seconds)}</span><span className="simply-word">seconds</span></div></div>
                    </div>
                  }
                />
                <p>
                  <ConfirmationButton />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider;
