import React from 'react';
import imgBg3 from '../../images/img_bg_3.jpg';
import { useAppSelector } from '../../../../../app/hooks';

const Event = ({ showCeremony = true, showParty = true }) => {

  const weddingInformation = useAppSelector((state) => (state.wedding));

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  function formatearHora(hora) {
    const partes = hora.split(":");
    const horas = partes[0];
    const minutos = partes[1].length === 1 ? "0" + partes[1] : partes[1];
    return horas + ":" + minutos;
  }

  return (
    <div id="fh5co-event" className="fh5co-bg"
      style={{ backgroundImage: `url(${imgBg3})` }}
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <span>Nuestros Eventos Especiales</span>
            <h2>Eventos</h2>
          </div>
        </div>
        <div className="row">
          <div className="display-t">
            <div className="display-tc">
              <div className="col-md-10 col-md-offset-1">
                {
                  (showCeremony) ?
                    <div className="col-md-6 col-sm-6 text-center">
                      <div className="event-wrap animate-box">
                        <h3>Ceremonia de Bodas</h3>
                        <div className="event-col">
                          <i className="icon-clock"></i>
                          <span>
                            {
                              (weddingInformation.ceremony_hour)
                                ? weddingInformation.ceremony_hour
                                : "10:00"
                            }
                          </span>
                        </div>
                        <div className="event-col">
                          <i className="icon-calendar"></i>
                          <span>
                            {
                              (weddingInformation.wedding_date)
                                ? formatDate(weddingInformation.wedding_date)
                                : "25 Julio 2025"
                            }
                          </span>
                          {/* <span>November, 2016</span> */}
                        </div>
                        <p>
                          {
                            (weddingInformation.ceremony_name)
                              ? weddingInformation.ceremony_name
                              : "Salon de eventos"
                          }
                        </p>
                        <p>
                          {
                            (weddingInformation.ceremony_adress)
                              ? weddingInformation.ceremony_adress
                              : "Majadas zona 11"
                          }
                        </p>
                      </div>
                    </div>
                    : null
                }
                {
                  (showParty) ?
                    <div className="col-md-6 col-sm-6 text-center">
                      <div className="event-wrap animate-box">
                        <h3>Fiesta de Bodas</h3>
                        <div className="event-col">
                          <i className="icon-clock"></i>
                          <span>
                            {
                              (weddingInformation.reception_hour)
                                ? formatearHora(weddingInformation.reception_hour)
                                : "10:00"
                            }
                          </span>
                          {/* <span>12:00 AM</span> */}
                        </div>
                        <div className="event-col">
                          <i className="icon-calendar"></i>
                          <span>
                            {
                              (weddingInformation.wedding_date)
                                ? formatDate(weddingInformation.wedding_date)
                                : "25 Julio 2025"
                            }
                          </span>
                          {/* <span>November, 2016</span> */}
                        </div>
                        <p>
                          {
                            (weddingInformation.reception_name)
                              ? weddingInformation.reception_name
                              : "Salon de eventos"
                          }
                        </p>
                        <p>
                          {
                            (weddingInformation.reception_adress)
                              ? weddingInformation.reception_adress
                              : "Majadas zona 11"
                          }
                        </p>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Event;
