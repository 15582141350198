import React, { useEffect } from 'react'
import './style.css'
import { useAppSelector } from '../../app/hooks'
import defaultImageWelcome from '../../images/1.jpg'

const Welcome = () => {
  const weddingObj = useAppSelector((state) => state.wedding)

  useEffect(() => {
    let idObject = "welcomeArea"
    if (weddingObj.welcome_file.length > 0 && weddingObj.welcome_file[0].url !== "") {
      document.getElementById(idObject).style.background = `url(${weddingObj.welcome_file[0].url}) center center/cover`
    }else{
      document.getElementById(idObject).style.background = `url(${defaultImageWelcome}) center center/cover`
    }
  })

  const isLocation = weddingObj.welcome_location

  return (
    <div id="welcomeArea" className="welcome-area h-welcome">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="welcome-content">
              <h2>
                {(weddingObj.welcome_tittle) ? weddingObj.welcome_tittle : "Bienvenidos a nuestro gran día"}
              </h2>
              <p>
                {(weddingObj.welcome_text) ? weddingObj.welcome_text : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or less normal distribution of letters"}
              </p>
              <div className="btn-location">
                {isLocation ? (
                  <a 
                  href={isLocation} 
                  target="_blank" 
                  rel='noreferrer'
                  style={{
                    pointerEvents: isLocation ? 'auto' : 'none',
                    textDecoration: isLocation ? 'underline' : 'none'
                  }}
                  >
                  Locación del Evento
                 </a>
                ):(
                  <a 
                  target="_blank" 
                  href='/' 
                  title='Add Event location on the left'
                  style={{
                    pointerEvents: isLocation ? 'auto' : 'none',
                    textDecoration: isLocation ? 'underline' : 'none'
                  }}                  
                  >
                 Locación del Evento
                </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Welcome;
