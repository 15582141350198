import React, {useEffect} from 'react';

const StoryComponent = ({ imgSample, storyObject, index }) => {

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  useEffect(() => {
    let idObject = "timeline-badge"
    if (imgSample && imgSample !== "") {
      if(index !== 0) idObject += index
      document.getElementById(idObject).style.background = `url(${imgSample}) center center/cover`
    }
  },[imgSample, index]);

  return (
    <li className={`animate-box ${(!(index % 2)) ? 'timeline-inverted' : ''}`}>
      <div
        className="timeline-badge"
        id={index !== 0 ? "timeline-badge" + index : "timeline-badge"}
        style={{ backgroundImage: `url(${imgSample})` }}>

      </div>
      <div className="timeline-panel">
        <div className="timeline-heading">
          <h3 className="timeline-title">{(storyObject.tittle) ? storyObject.tittle : 'Primera Cita'}</h3>
          <span className="date">
            {
              (storyObject.date)
                ? formatDate(storyObject.date)
                : formatDate("04-04-2025")
            }
          </span>
        </div>
        <div className="timeline-body">
          <p>
            {
              (storyObject.speech)
                ? storyObject.speech
                : 'We met at the wedding of our close friends and immediately found a common language, so a year later our first date happened.'
            }
          </p>
        </div>
      </div>
    </li>
  );
}

export default StoryComponent;
