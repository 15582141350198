import React, { useState } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setIsLoginActive } from '../../features/user-state/user-state';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';


const MobileMenu2 = () => {

  const [isMenuShow, setMenuShow] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isOpenProfile, setOpenProfile] = useState(null);
  const dispatch = useAppDispatch();

  const menuHandler = () => {
    setMenuShow(!isMenuShow);
  }

  const setIsOpen = () => {
    setOpen(!isOpen);
  }

  const weddingObj = useAppSelector((state) => state.wedding);
  const userObj = useAppSelector((state) => state.user);
  const { route } = useAuthenticator(context => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const HandleProfileClick = () => {
    setOpenProfile(!isOpenProfile);
  }

  const OpenLogin = () => {
    dispatch(setIsLoginActive(!userObj.showLogin));
    setMenuShow(!isMenuShow);
  };

  return (
    <div>
      <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
        <div className="logo2">
          <h2><Link to='/home'>
            {
              (weddingObj.boy_name) ? `${weddingObj.boy_name} & ${weddingObj.girl_name}` : 'Harry & Nancy'
            }
          </Link></h2>
        </div>
        <ul className="responsivemenu">
          {
            (route !== 'authenticated') ?
              <div>
                <li>
                  <Link onClick={OpenLogin}>Login</Link>
                </li>
              </div>
              :
              <div>
                <li>
                  <Link onClick={HandleProfileClick}>
                    <AccountCircleIcon />
                    {
                      (user.attributes.email)
                        ? ` ${user.attributes.email.split("@")[0].toLowerCase()}`
                        : ` ${user.username.split("@")[0].toLowerCase()}`
                    }
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </li>
                <Collapse isOpen={isOpenProfile}>
                  <Card>
                    <CardBody>
                      <li><Link onClick={signOut}>signOut</Link></li>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
          }
          {
            (route === 'authenticated' && !userObj.weddingError) ? (<li><Link className='create-wedding' to='/Admin'><AddCircleIcon />{' Crear'}</Link></li>) : ('')
          }          
          <li><p onClick={setIsOpen}>Home<i className="fa fa-angle-right" aria-hidden="true"></i></p></li>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                <li><Link to='/home'>Clasico</Link></li>
                <li><Link to='/home6'>Fancy</Link></li>
                <li><Link to='/home3'>Festivo</Link></li>
                <li><Link to='/home5'>Minimalista</Link></li>
              </CardBody>
            </Card>
          </Collapse>
          {
            (route === 'authenticated' && !userObj.weddingError) ? (<li><Link className='create-wedding' to='/Admin'>Crear</Link></li>) : ('')
          }
          <li><Link to='/home'>Couple</Link></li>
          <li><Link to='/home'>Story</Link></li>
          <li><Link to='/home'>People</Link></li>
          <li><Link to='/home'>Events</Link></li>
          <li><Link to='/home'>Gallery</Link></li>
          <li><Link to='/home'>Rsvp</Link></li>
        </ul>

      </div>

      <div className="showmenu" onClick={menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
    </div>
  )
}

export default MobileMenu2;
