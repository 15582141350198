import React, { useState } from 'react';
import './style.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  updateOldWedding,
  deleteGuestObjet,
  loadingComponent,
  updateAllowedKids,
  updateInvitationType,
  updateGuestObject,
} from '../../features/wedding-state/wedding-state';
import docdbMethods from '../../features/apis/docdb';
import alertMethods from '../../features/alerts/alerts';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import InputModal from './guestModal';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import BestWishes from '../BestWishes';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import InvitationSummary from './guestSummary';

const successAlert = (mensaje) => {
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const CreateNewGuestForm = () => {

  const submitHandler = async (e) => {
    try {
      dispatch(loadingComponent(true));
      e.preventDefault();
      const onePhone = guestInformation.guests.map((item) => item["phone"]).filter((value, index, self) => self.indexOf(value) === index && self.indexOf(value) !== "");
      const originalPhone = guestInformation.guests.map((item) => item["phone"])
      const originalPhoneOne = originalPhone.filter(element => element !== "")
      const newPhone = onePhone.filter(element => element !== "")
      if (newPhone.length === onePhone.length && newPhone.length === originalPhone.length && newPhone.length === originalPhoneOne.length) {
        const updateObj = await docdbMethods.getDifferenceObj(guestInformation, guestInformation.previousdoc)
        docdbMethods.updateDocument(guestInformation.wedding, updateObj).then(res => {
          dispatch(updateOldWedding(updateObj))
          successAlert('Tus invitados fueron agregados exitosamente.')
        })
      }
      else {
        errorAlert('Por favor elimina los contactos repetidos o campos vacios')
      }
    } catch (error) {
      alert("Error al salvar los cambios", error.message)
    } finally {
      dispatch(loadingComponent(false));
    }
  }

  const handleChange = (e) => {
    if (e.target.id.includes("name")) {
      setNameInput(e.target.value);
    } else {
      const phoneNumber = e.target.value.replace(/\D/g, '');
      const limitedPhoneNumber = phoneNumber.slice(0, 10);
      e.target.value = limitedPhoneNumber
      setPhoneInput(limitedPhoneNumber);
    }
  }

  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [openWishes, setOpenWishes] = useState(false);

  const addNewGuest = () => {
    const copyOfGuests = [...guestInformation.guests];
    copyOfGuests.push({
      name: nameInput,
      phone: phoneInput,
      confirmed: false,
      rsvp: '1',
      guestNames: [{ name: '', confirmed: false }]
    });
    dispatch(updateGuestObject(copyOfGuests));
    setNameInput('');
    setPhoneInput('');
  }

  const updateGuest = (index, newRsvp, newNameGuests) => {
    const copyOfGuests = [...guestInformation.guests];
    const copyOfObj = { ...copyOfGuests[(page * rowsPerPage) + index] };
    copyOfObj.rsvp = newRsvp;
    copyOfObj.guestNames = newNameGuests;
    copyOfGuests[(page * rowsPerPage) + index] = copyOfObj;
    dispatch(updateGuestObject(copyOfGuests));
  }

  const deleteGuest = (index) => {
    dispatch(deleteGuestObjet(index))
  }

  const guestInformation = useAppSelector(state => state.wedding);
  const dispatch = useAppDispatch();

  const updateKidsAllowedStatus = (e) => {
    dispatch(updateAllowedKids(e.target.checked));
  }

  const updateInvitationTypeStatus = (e) => {
    dispatch(updateInvitationType(e.target.checked));
  }

  const noKidsString = "Si seleccionas esta opcion, indicas que los invitados pueden llegar con niños pequeños.";
  const openOrCloseInvitation = "Si seleccionas esta opcion, cualquier persona con el link podrá ver tu invitacion, de lo contrario solo podran ver tu invitacion los invitados que registres."

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openWishesModal = () => {
    setOpenWishes(true);
  }

  const closeWishesModal = () => {
    setOpenWishes(false);
  }

  const columns = [
    { id: 'name', label: 'Nombre', minWidth: 100 },
    { id: 'phone', label: 'Telefono', minWidth: 50 },
    { id: 'confirmed', label: 'Confirmación', minWidth: 80, align: 'right' },
    { id: 'rsvp', label: 'Reserva', minWidth: 50, align: 'right' },
    { id: 'remove', label: 'Eliminar', minWidth: 70, align: 'right' },
  ];

  return (
    <div id="GuestList" className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
          <div className="personal-form-wrap" >
            <div className="col-12">
              <div className="section-title section-title4 text-center">
                <h2>Agrega invitados a tu evento!</h2>
              </div>
            </div>
            <Modal
              open={openWishes}
              onClose={closeWishesModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <BestWishes />
              </Box>
            </Modal>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <Tooltip title={noKidsString} placement="top">
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        onChange={updateKidsAllowedStatus}
                        checked={guestInformation.kids}
                      />
                    }
                    label="pueden llegar niños"
                    labelPlacement="start"
                  />
                </Tooltip>

                <Tooltip title={openOrCloseInvitation} placement="top">
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        onChange={updateInvitationTypeStatus}
                        checked={guestInformation.open_wedding}
                      />
                    }
                    label="Boda abierta"
                    labelPlacement="start"
                  />
                </Tooltip>
                <InvitationSummary guests={guestInformation.guests} />
              </FormGroup>
            </FormControl>
            <form>
              <div className="contact-form form-style">
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <input
                      autoComplete='off'
                      placeholder='nombre'
                      type='text'
                      id="name_input"
                      onChange={(e) => handleChange(e)}
                      value={nameInput}
                    />
                  </div>
                  <div className="col-12  col-sm-4">
                    <input
                      autoComplete='off'
                      placeholder='telefono'
                      type='text'
                      id="phone_input"
                      onChange={(e) => handleChange(e)}
                      value={phoneInput}
                    />
                  </div>
                  <div className="new_guest col-12 col-sm-4">
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Agregar invitado" placement="top">
                        <IconButton aria-label="add-guest" onClick={addNewGuest}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Guardar cambios" placement="top">
                        <IconButton aria-label="save-guest" onClick={submitHandler}>
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Deseos" placement="top">
                        <IconButton aria-label="wishes" onClick={openWishesModal}>
                          <SpeakerNotesIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </div>
                </div>
              </div>
            </form>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guestInformation.guests
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) =>
                        <TableRow hover role="checkbox" tabIndex={-1} key={"row_" + index}>
                          <TableCell key={"cell_name" + index}>
                            {row.name}
                          </TableCell>
                          <TableCell key={"cell_phone" + index}>
                            {row.phone}
                          </TableCell>
                          <TableCell key={"cell_confirmed" + index} align="right">
                            {
                              (row.confirmed) ? "Confirmado" : "Sin confirmar"
                            }
                          </TableCell>
                          <TableCell key={"cell_rsvp" + index} align="right">
                            <InputModal
                              index={index}
                              rsvpValue={row.rsvp}
                              confirmed={row.confirmed}
                              saveMethod={updateGuest}
                              arrMembers={row.guestNames}
                            />
                          </TableCell>
                          <TableCell key={"cell_delete" + index} align="right">
                            <IconButton onClick={() => deleteGuest((page * rowsPerPage) + index)} aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={guestInformation.guests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateNewGuestForm
