import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../hero/style.css';
import HeroComponent from "../../heroComponents/hero";
import slideImage01 from '../../NewTemplateStyles/Style1/images/slider-01.jpg';
import slideImage02 from '../../NewTemplateStyles/Style1/images/slider-02.jpg';
import slideImage03 from '../../NewTemplateStyles/Style1/images/slider-03.jpg';

const SimpleSlider = () => {

  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true
  };


  return (
    <Slider {...settings}>
      <HeroComponent
        key={"herocomponent_0"}
        id={"herocomponent_0"}
        subtitleDescription='NOS VAMOS A CASAR'
        slideDescription='Reserva la fecha'
        slideDate='25 Diciembre 2025'
        url={slideImage01}
      />
      <HeroComponent
        key={"herocomponent_1"}
        id={"herocomponent_1"}
        subtitleDescription='NOS VAMOS A CASAR'
        slideDescription='Reserva la fecha'
        slideDate='25 Diciembre 2025'
        url={slideImage02}
      />
      <HeroComponent
        key={"herocomponent_2"}
        id={"herocomponent_2"}
        subtitleDescription='NOS VAMOS A CASAR'
        slideDescription='Reserva la fecha'
        slideDate='25 Diciembre 2025'
        url={slideImage03}
      />      
    </Slider>
  );
}

export default SimpleSlider;
