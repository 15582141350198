import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MainListItems from './listItems';
import '../AdminSidebar/style.css';
import { useAppSelector } from '../../app/hooks';
import DrawerFunctions from './DrawerFunctions';
import SwipeableEdgeDrawer from './SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://invitegt.com">
        https://invitegt.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const Dashboard = ({ elementInvite }) => {
  const { open, toggleDrawer, Drawer, defaultTheme } = DrawerFunctions();
  const fileInformation = useAppSelector((state) => (state.wedding));
  const checkifmobilemediaquery = useMediaQuery('(max-width: 991px)');

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {
          (!checkifmobilemediaquery) ?
            <Drawer variant="permanent" open={open} >
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <IconButton className='arrow-drawer' onClick={toggleDrawer}>
                  {(fileInformation.toggle_drawer) ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Toolbar>
              <Divider />
              <List
                component="nav"
                className=
                {
                  `tour-sidebar ${fileInformation.wedding !== 0 ? 'admin-sidebar' : 'bartoggle'
                  }`
                }
                sx={{ width: '285px' }}
              >
                <MainListItems />
              </List>
            </Drawer> : ""
        }
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container style={{ paddingLeft: '0px', paddingRight: '0px' }} maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {elementInvite}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      {
        (checkifmobilemediaquery) ? <SwipeableEdgeDrawer /> : ""
      }
    </ThemeProvider>
  );
}

export default Dashboard;

