import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import './style.css'
import CreateNew from '../createNew/'
import SearchById from '../searchById';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PersonalForm = () => {

  const [activeTab, setActiveTab] = useState('1');
  const [openErrorNonSecureBrowser, setOpenErrorNonSecureBrowser] = React.useState(false);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const { route } = useAuthenticator(context => [context.route]);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/Instagram/.test(userAgent) || /FBAN/.test(userAgent) || /FBAV/.test(userAgent) || /Twitter/.test(userAgent)) {
      setOpenErrorNonSecureBrowser(true);
    }
  }, []);

  const handleClose = () => {
    setOpenErrorNonSecureBrowser(false);
  };

  return (
    <div id="PersonalForm" className="personal-form-area go-personal-form-area section-padding">
      <div className="container">
        <div className="create-search person-btn">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Crear
              </NavLink>
            </NavItem>
            {
              (route === 'authenticated') ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Buscar
                  </NavLink>
                </NavItem>
                : null
            }
          </Nav>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CreateNew />
          </TabPane>
          <TabPane tabId="2">
            <SearchById />
          </TabPane>
        </TabContent>
      </div>
      <Dialog
        open={openErrorNonSecureBrowser}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"🔒 ¡Atención! Parece que estás usando un explorador no seguro o estás accediendo a través de una red social."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Para garantizar tu seguridad y la mejor experiencia posible,
            te recomendamos abrir nuestra página en un navegador seguro antes de iniciar sesión. 
            Esto no solo protegerá tus datos, sino que también te asegurará de disfrutar todas las funcionalidades de nuestro sitio sin interrupciones.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PersonalForm;
