import aboutImg01 from '../../images/about-img-01.jpg';
import aboutImg02 from '../../images/about-img-02.jpg';
import family01 from '../../images/family-01.jpg';
import family02 from '../../images/family-02.jpg';
import family03 from '../../images/family-03.jpg';
import family04 from '../../images/family-04.jpg';
import family05 from '../../images/family-05.jpg';
import family06 from '../../images/family-06.jpg';
import gallery01 from '../../images/gallery-01.jpg';
import gallery02 from '../../images/gallery-02.jpg';
import gallery03 from '../../images/gallery-03.jpg';
import gallery04 from '../../images/gallery-04.jpg';
import gallery05 from '../../images/gallery-05.jpg';
import gallery06 from '../../images/gallery-06.jpg';
import gallery07 from '../../images/gallery-07.jpg';
import gallery08 from '../../images/gallery-08.jpg';
import event01 from '../../images/event-img-01.jpg';
import event02 from '../../images/event-img-02.jpg';
import event03 from '../../images/event-img-03.jpg';
import attendingImg from '../../../Style2/images/img_bg_4.jpg';


import '../../css/style.css';

const Style5Example = () => {
  return (
    <div>
      <div id="about" className="about-box">
        <div className="about-a1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-box">
                  <h2>Leida <span>&</span> Dominic</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row align-items-center about-main-info">
                  <div className="col-lg-8 col-md-6 col-sm-12">
                    <h2> About <span>Leida</span></h2>
                    <p>Fusce convallis ante id purus sagittis malesuada. Sed erat ipsum, suscipit sit amet auctor quis,
                      vehicula ut leo. Maecenas felis nulla, tincidunt ac blandit a, consectetur quis elit. Nulla ut magna
                      eu purus cursus sagittis. Praesent fermentum tincidunt varius. Proin sit amet tempus magna. Fusce
                      pellentesque vulputate urna. </p>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="about-img">
                      <img className="img-fluid rounded" src={aboutImg01} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center about-main-info">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="about-img">
                      <img className="img-fluid rounded" src={aboutImg02} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-12">
                    <h2> About <span>Dominic</span></h2>
                    <p>Fusce convallis ante id purus sagittis malesuada. Sed erat ipsum, suscipit sit amet auctor quis,
                      vehicula ut leo. Maecenas felis nulla, tincidunt ac blandit a, consectetur quis elit. Nulla ut magna
                      eu purus cursus sagittis. Praesent fermentum tincidunt varius. Proin sit amet tempus magna. Fusce
                      pellentesque vulputate urna. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="story" className="story-box main-timeline-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-box">
                <h2>Our Story</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
            </div>
          </div>
          <div className="row timeline-element separline">
            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
              <div className="time-line-date-content">
                <p className="mbr-timeline-date mbr-fonts-style display-font">
                  1 March 2018
                </p>
              </div>
            </div>
            <span className="iconBackground"></span>
            <div className="col-xs-12 col-md-6 align-left">
              <div className="timeline-text-content">
                <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-font">First meet</h4>
                <p className="mbr-timeline-text mbr-fonts-style display-7">
                  We met at the wedding of our close friends and immediately found a common language, so a year later our
                  first date happened.
                </p>
              </div>
            </div>
          </div>
          <div className="row timeline-element reverse separline">
            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
              <div className="time-line-date-content">
                <p className="mbr-timeline-date mbr-fonts-style display-font">
                  2 April 2018
                </p>
              </div>
            </div>
            <span className="iconBackground"></span>
            <div className="col-xs-12 col-md-6 align-right">
              <div className="timeline-text-content">
                <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-font">First date</h4>
                <p className="mbr-timeline-text mbr-fonts-style display-7">
                  We met at the wedding of our close friends and immediately found a common language, so a year later our
                  first date happened.
                </p>
              </div>
            </div>
          </div>
          <div className="row timeline-element separline">
            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
              <div className="time-line-date-content">
                <p className="mbr-timeline-date mbr-fonts-style display-font">
                  1 May 2018
                </p>
              </div>
            </div>
            <span className="iconBackground"></span>
            <div className="col-xs-12 col-md-6 align-left">
              <div className="timeline-text-content">
                <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-font">Proposal</h4>
                <p className="mbr-timeline-text mbr-fonts-style display-7">
                  Fusce convallis ante id purus sagittis malesuada. Sed erat ipsum, suscipit sit amet auctor quis, vehicula
                  ut leo. Maecenas felis nulla, tincidunt ac blandit a, consectetur quis elit.
                </p>
              </div>
            </div>
          </div>
          <div className="row timeline-element reverse separline">
            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
              <div className="time-line-date-content">
                <p className="mbr-timeline-date mbr-fonts-style display-font">
                  2 June 2018
                </p>
              </div>
            </div>
            <span className="iconBackground"></span>
            <div className="col-xs-12 col-md-6 align-right">
              <div className="timeline-text-content">
                <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-font">Engagement</h4>
                <p className="mbr-timeline-text mbr-fonts-style display-7">
                  Proin tempus felis quis justo pretium interdum. Praesent sollicitudin lectus eu mattis egestas. Praesent
                  luctus magna at dignissim placerat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="family" className="family-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-box">
                <h2>Family</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-member">
                <div className="family-img">
                  <img className="img-fluid" src={family01} alt="" />
                </div>
                <div className="family-info">
                  <h4>Mr. Alonso Wiles </h4>
                  <p>{"Leida’s Father" }</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-member">
                <div className="family-img">
                  <img className="img-fluid" src={family02} alt="" />
                </div>
                <div className="family-info">
                  <h4>Mr. Evon Wiles </h4>
                  <p>{"Leida’s Mother" }</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-member">
                <div className="family-img">
                  <img className="img-fluid" src={family03} alt="" />
                </div>
                <div className="family-info">
                  <h4>Mr. Chris Wiles </h4>
                  <p>{"Leida’s Brother" }</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-member">
                <div className="family-img">
                  <img className="img-fluid" src={family04} alt="" />
                </div>
                <div className="family-info">
                  <h4>Mr. Adina Wiles </h4>
                  <p>{"Leida’s Sister" }</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-member">
                <div className="family-img">
                  <img className="img-fluid" src={family05} alt="" />
                </div>
                <div className="family-info">
                  <h4>Mr. Annetta Wiles </h4>
                  <p>{"Leida’s Sister" }</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-member">
                <div className="family-img">
                  <img className="img-fluid" src={family06} alt="" />
                </div>
                <div className="family-info">
                  <h4>Mr. Ladonna Wiles </h4>
                  <p>{"Leida’s Sister" }</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="gallery" className="gallery-box">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-box">
                <h2>Gallery</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <ul className="popup-gallery clearfix">
              <li>
                <a href={gallery01}>
                  <img className="img-fluid" src={gallery01} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
              <li>
                <a href={gallery02}>
                  <img className="img-fluid" src={gallery02} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
              <li>
                <a href={gallery03}>
                  <img className="img-fluid" src={gallery03} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
              <li>
                <a href={gallery04}>
                  <img className="img-fluid" src={gallery04} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
              <li>
                <a href={gallery05}>
                  <img className="img-fluid" src={gallery05} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
              <li>
                <a href={gallery06}>
                  <img className="img-fluid" src={gallery06} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
              <li>
                <a href={gallery07}>
                  <img className="img-fluid" src={gallery07} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
              <li>
                <a href={gallery08}>
                  <img className="img-fluid" src={gallery08} alt="single"/>
                    <span className="overlay"><i className="fa fa-heart-o" aria-hidden="true"></i></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="fh5co-started" className="fh5co-bg" 
      style={{
        background : `url(${attendingImg})`
      }}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row animate-box">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2>Se nuestro Invitado</h2>
              <p>Por favor llena la siguiente información para confirmar tu asistencia</p>
            </div>
          </div>
          <div className="row animate-box">
            <div className="col-md-10 col-md-offset-1">
              <form className="form-inline">
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label for="name" className="sr-only">Nombre</label>
                    <input type="name" className="form-control" id="name" placeholder="Name" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label for="email" className="sr-only">Correo</label>
                    <input type="email" className="form-control" id="email" placeholder="Email" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <button disabled type="submit" className="btn btn-default btn-block">Asistiré</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="events" className="events-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-box">
                <h2>Events</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="event-inner">
                <div className="event-img">
                  <img className="img-fluid" src={event01} alt="" />
                </div>
                <h2>2 June 2018 Engagement</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard </p>
                <a href="/">See location </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="event-inner">
                <div className="event-img">
                  <img className="img-fluid" src={event02} alt="" />
                </div>
                <h2>3 June 2018 Main Ceremony </h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard </p>
                <a href="/">See location </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="event-inner">
                <div className="event-img">
                  <img className="img-fluid" src={event03} alt="" />
                </div>
                <h2>4 June 2018 Wedding party </h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard </p>
                <a href="/">See location </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Style5Example;
