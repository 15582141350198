import axios from 'axios';
import alertMethods from '../alerts/alerts';

const urlendpoint = (process.env.REACT_APP_API_S3_ENDPOINT || window.REACT_APP_API_S3_ENDPOINT)
const urlCdnReadEnpoint = (process.env.REACT_APP_CDN_READ_ENDPOINT || window.REACT_APP_CDN_READ_ENDPOINT)

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const uploadImage = async (filename, content_type, fileBody) => {
  var objRequestSignedUrl = {
    method: 'post',
    url: urlendpoint + "/api/",
    params: {
      filename: filename,
      contenttype: content_type,
      method: "PUT"
    },
  }
  var objSignedUrl = await weddingRequest(objRequestSignedUrl);
  if (objSignedUrl && objSignedUrl["uploadURL"]) {
    var objRequestPutFile = {
      method: 'put',
      url: objSignedUrl["uploadURL"],
      data: fileBody,
      headers: { 'Content-Type': content_type }
    }
    const result = await weddingRequest(objRequestPutFile);
    if (result && result["status"] === "error") return result;
    return filename;
  } else {
    return { status: "error", message: "Not able to reach API" }
  }
}

const getImage = async (filename, content_type = "image/jpeg") => {
  try {
    var urlFile = urlCdnReadEnpoint + filename
    return (content_type !== "application/json")
      ? urlFile
      : await readAsText(urlFile);
  } catch (e) {
    return { status: "error", message: e.message }
  }
}

const weddingRequest = async (objRequest) => {
  return await axios(objRequest)
    .then((response) => response.data)
    .catch(e => {
      return {
        status: "error", message: e.message
      }
    }
    );
}

const readAsText = async (file) => {
  var objRequestGetFile = {
    method: 'get',
    url: file,
    headers: { 'Content-Type': "application/json" },
    responseType: 'blob',
  }
  let getFile = await weddingRequest(objRequestGetFile);
  return new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = function () {
      return resolve(fileReader.result);
    }
    fileReader.readAsText(getFile);
  })
}

const buildObjImageFront = (e, weddingId) => {
  return new Promise((resolve, reject) => {
    if ((e.target.files) && (e.target.files.length > 0)) {
      var file = e.target.files[0]
      var filename = String(e.target.files[0].name).replace(/[^A-Z0-9.]/ig, "_");
      var finalname = "wedding/" + weddingId + "/" + filename
      var content_type = (e.target.files[0].type);
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var obj_file = {
          filename: finalname,
          url: reader.result,
          contenttype: content_type
        };
        resolve(obj_file);
      }
      reader.onerror = function (error) {
        reject(error);
      };
    }
  });
}

const maxSizeInBytes = 10 * 1024 * 1024; // 15 MB

const buildObjImage = (e, weddingId) => {
  return new Promise((resolve, reject) => {
    var file = e.target.files[0]
    if (file && file.size > maxSizeInBytes) {
      errorAlert('El archivo es demasiado grande. El tamaño máximo permitido es 15 MB.');
      e.target.value = ''; // Limpia el input
      return;
    } else if ((e.target.files) && (e.target.files.length > 0)) {
      var filename = String(e.target.files[0].name).replace(/[^A-Z0-9.]/ig, "_");
      var finalname = "wedding/" + weddingId + "/" + filename
      var content_type = (e.target.files[0].type);
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var obj_file = {
          filename: finalname,
          url: reader.result,
          contenttype: content_type
        };
        resolve(obj_file);
      }
      reader.onerror = function (error) {
        reject(error);
      };
    }
  });
}

const buildCropObjImage = (originalFileName, cropFile, weddingId) => {
  return new Promise((resolve, reject) => {
    if (cropFile) {
      var file = cropFile
      var filename = String(originalFileName).replace(/[^A-Z0-9.]/ig, "_");
      var finalname = "wedding/" + weddingId + "/" + filename
      var content_type = (cropFile.type);
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var obj_file = {
          filename: finalname,
          url: reader.result,
          contenttype: content_type
        };
        resolve(obj_file);
      }
      reader.onerror = function (error) {
        reject(error);
      };
    }
  });
}

const buildObjUserProfileImage = (e, userName) => {
  return new Promise((resolve, reject) => {
    var file = e.target.files[0]
    if (file && file.size > maxSizeInBytes) {
      errorAlert('El archivo es demasiado grande. El tamaño máximo permitido es 15 MB.');
      e.target.value = ''; // Limpia el input
      return;
    } else if ((e.target.files) && (e.target.files.length > 0)) {
      var filename = String(userName).split("@")[0];
      var finalname = "users/" + filename;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var obj_file = {
          filename: finalname,
          url: reader.result,
        };
        resolve(obj_file);
      }
      reader.onerror = function (error) {
        reject(error);
      };
    }
  });
}

const s3Methods = { 
  uploadImage, 
  getImage, 
  buildObjImageFront, 
  buildObjImage, 
  buildCropObjImage,
  buildObjUserProfileImage 
}

export default s3Methods
