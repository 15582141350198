import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  updateCoupleBoyfriendPhrase,
  updateCoupleGirlfriendPhrase,
  updateCoupleBoyfriendImage,
  updateCoupleGirlfriendImage,
  updateCouplepictureimg,
  updateOldWedding,
  loadingComponent,
} from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';

const successAlert = (mensaje) => {
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const AdminSidebarCouple = () => {

  const submitHandler = async () => {
    try {
      dispatch(loadingComponent(true));
      if (document.getElementById("boyfriend-fileupload").files.length === 1) {
        await uploadImageWedding(
          fileCoupleInformation.couple_boy_file[0].filename,
          document.getElementById("boyfriend-fileupload").files[0]
        )
      }
      if (document.getElementById("girlfriend-fileupload").files.length === 1) {
        await uploadImageWedding(
          fileCoupleInformation.couple_girl_file[0].filename,
          document.getElementById("girlfriend-fileupload").files[0]
        )
      }

      if (document.getElementById("couple-fileupload").files.length === 1) {
        await uploadImageWedding(
          fileCoupleInformation.couple_pick_file[0].filename,
          document.getElementById("couple-fileupload").files[0]
        )
      }

      const updateObj = await docdbMethods.getDifferenceObj(fileCoupleInformation, fileCoupleInformation.previousdoc)
      docdbMethods.updateDocument(fileCoupleInformation.wedding, updateObj).then(res => {
        dispatch(updateOldWedding(updateObj))
        successAlert("Cambios salvados correctamente.")
      }).catch(error => {
        errorAlert('Error al salvar los cambios.')
      })
    } catch (error) {
      errorAlert('Error al salvar los cambios.')

    } finally {
      dispatch(loadingComponent(false));
    }
  }

  const uploadImageWedding = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const updateboyIntroduction = (e) => {
    (e.id === "boyspeech")
      ? dispatch(updateCoupleBoyfriendPhrase(e.value))
      : dispatch(updateCoupleGirlfriendPhrase(e.value))
  }

  const updateFile = async (e) => {
    var obj_file = await s3request.buildObjImage(e, fileCoupleInformation.wedding);
    var inputId = e.target.id;
    (inputId === "boyfriend-fileupload")
      ? dispatch(updateCoupleBoyfriendImage([obj_file]))
      : inputId === "girlfriend-fileupload"
        ? dispatch(updateCoupleGirlfriendImage([obj_file]))
        : dispatch(updateCouplepictureimg([obj_file]))
  }

  const dispatch = useAppDispatch();
  const fileCoupleInformation = useAppSelector((state) => (state.wedding));

  return (
    <div>
      <form onSubmit={submitHandler}>
        <p className='couple-img-p'>Información de la novia </p>
        <div style={{ display: 'flex' }}>
          <input
            style={{ width: '185px' }}
            type="text" disabled
            className="form-control" placeholder="girlfriend image"
            value={(fileCoupleInformation.couple_girl_file.length > 0 && fileCoupleInformation.couple_girl_file[0].filename)
              ? fileCoupleInformation.couple_girl_file[0].filename.split('\\').pop().split('/').pop()
              : "" || ''}
          />
          <label htmlFor="girlfriend-fileupload" className="custom-file-upload">
            <i className="fa fa-upload"></i>
          </label>
          <input
            type="file" accept="image/*" className="form-control"
            id="girlfriend-fileupload" onChange={(e) => updateFile(e)}
          />
        </div>
        <div className="form-textarea">
          <textarea
            id="girlspeech" placeholder='Hi I am ....'
            value={fileCoupleInformation.couple_girl_phrase || ''}
            onChange={(e) => updateboyIntroduction(e.target)}
          />
        </div>
        <div className="couple-picture">
          <p className='couple-img-p'>Foto de la pareja</p>
          <div style={{ display: 'flex' }}>
            <input
              style={{ width: '185px' }}
              type="text" disabled
              className="form-control" placeholder="Foto de la pareja"
              value={(fileCoupleInformation.couple_pick_file.length > 0 && fileCoupleInformation.couple_pick_file[0].filename)
                ? fileCoupleInformation.couple_pick_file[0].filename.split('\\').pop().split('/').pop()
                : "" || ''}
            />
            <label htmlFor="couple-fileupload" className="custom-file-upload">
              <i className="fa fa-upload"></i>
            </label>
            <input
              type="file" accept="image/*" className="form-control"
              id="couple-fileupload" onChange={(e) => updateFile(e)}
            />
          </div>
        </div>
        <p className='couple-img-p'>Información del novio </p>
        <div style={{ display: 'flex' }}>
          <input
            style={{ width: '185px' }}
            type="text" disabled
            className="form-control" placeholder="boyfriend image"
            value={(fileCoupleInformation.couple_boy_file.length > 0 && fileCoupleInformation.couple_boy_file[0].filename)
              ? fileCoupleInformation.couple_boy_file[0].filename.split('\\').pop().split('/').pop()
              : "" || ''}
          />
          <label htmlFor="boyfriend-fileupload" className="custom-file-upload">
            <i className="fa fa-upload"></i>
          </label>
          <input
            type="file" accept="image/*" className="form-control"
            id="boyfriend-fileupload" onChange={(e) => updateFile(e)}
          />
        </div>
        <div className="form-textarea">
          <textarea
            id="boyspeech" placeholder='Hi I am ....'
            value={fileCoupleInformation.couple_boy_phrase || ''}
            onChange={(e) => updateboyIntroduction(e.target)}
          />
        </div>
        <div>
          <div className="text-center">
            <Tooltip
              title="Guardar cambios"
            >
              <CloudUploadTwoToneIcon
                onClick={() => submitHandler()}
                style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
              />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AdminSidebarCouple
