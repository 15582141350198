import { useEffect } from 'react';

const Landingpage = () => {
  useEffect(() => {
    window.location.href = '/landing/index.html'; 
  }, []);
  return null;
};

export default Landingpage;
