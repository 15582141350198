import React from 'react'

const FrontComponent = ({ filename, index, uploadMethod }) => {

  return (
    <div style={{ display: 'flex' }}>
      <input type="text"
        disabled
        className="form-control"
        style={{ width: '185px' }}
        placeholder="Front image"
        value={
          (filename)
            ? filename.split('\\').pop().split('/').pop()
            : "" || ''
        }
      />
      <label htmlFor={"front-fileupload" + index}
        className="custom-file-upload">
        <i className="fa fa-upload"></i>
      </label>
      <input
        type="file"
        accept="image/*"
        className="form-control"
        id={"front-fileupload" + index}
        onChange={(e) => uploadMethod(e)}
      />
    </div>
  )
}

export default FrontComponent
