import { configureStore } from "@reduxjs/toolkit";
import counterStepReducer from "../features/step_counter/step-slice";
import weddingReducer from "../features/wedding-state/wedding-state";
import userReducer from "../features/user-state/user-state";
import payReducer from "../features/pay-state/pay-state";
import guestReducer from "../features/guest_state/guest_state";

const isDevelopment = process.env.REACT_APP_INVITEGT_ENV === 'development';
export const store =  configureStore({
  reducer: {
    counterStep: counterStepReducer,
    wedding: weddingReducer,
    user: userReducer,
    pay: payReducer,
    guest: guestReducer,
  },
  devTools: isDevelopment
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>
