import './index.css';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../app/hooks';
import { deleteAlbumObject } from '../../../../../features/wedding-state/wedding-state';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';

const GalleryComponent = ({ galleryImg, index }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const deleteAlbumPhoto = (index) => {
    dispatch(deleteAlbumObject(index))
  }

  return (
    <LazyLoadComponent>
      <li
        key={"GalleryComponent" + index}
        id={"GalleryComponent" + index}
        className={`one-third animate-box` + ((index === 0) ? " h-gallery-mob" : '')}
        data-animate-effect="fadeIn"
        style={{ backgroundImage: `url(${galleryImg})` }}>
        {
          location.pathname === '/Admin'
            ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => deleteAlbumPhoto(index)
                  }>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            )
            : ""
        }
      </li>
    </LazyLoadComponent>
  );
}

export default trackWindowScroll(GalleryComponent);
