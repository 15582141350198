import React, { useState } from 'react';
import './style.css';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  setGuestInfo,
} from '../../features/guest_state/guest_state';
import alertMethods from '../../features/alerts/alerts';
import docdbMethods from '../../features/apis/docdb';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import BestWishes from '../BestWishes';
import ConfirmationButton from '../ConfirmationButton';

const Rsvp = () => {

  const successAlert = (mensaje) => {
    alertMethods.generalAlert('', 'success', mensaje, 3000, true)
  }

  const errorAlert = (mensaje) => {
    alertMethods.generalAlert('', 'error', mensaje, 3000, true)
  }

  const dispatch = useAppDispatch();

  const [stateForm, setStateForm] = useState({
    error: {}
  });

  const [confirmedGuest, setConfirmedGuest] = useState(false);

  const changeHandler = (e) => {
    const error = stateForm.error;
    error[e.target.name] = '';
    dispatch(setGuestInfo({ key: e.target.name, value: e.target.value }));
  }

  const saveGuestsConfirmation = async (guestObj) => {
    try {
      docdbMethods.confirmationGuest(welcomeObject.wedding, guestObj).then(res => {
        successAlert('Tu mensaje fue agregado correctamente.');
        setConfirmedGuest(true);
      })
    } catch (error) {
      errorAlert('Error al salvar los cambios' + error.message);
    } finally { }
  }


  const submitHandler = (e) => {
    e.preventDefault();
    if (!welcomeObject.show_final_assistance) return;
    const { error } = stateForm;

    if (rsvpReduxState.name === '') {
      error.name = "Ingresa tu nombre";
    }

    if (error) {
      setStateForm({
        error
      })
    }
    if (rsvpReduxState.name !== '') {
      const guestInfo = { ...rsvpReduxState };
      guestInfo.confirmed = true;
      delete guestInfo.error;
      delete guestInfo.celebration;
      saveGuestsConfirmation(guestInfo);
    } else {
      return
    }
  }

  const welcomeObject = useAppSelector((state) => state.wedding);
  const rsvpReduxState = useAppSelector((state) => state.guest);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  return (
    <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
      {
        (rsvpReduxState.phone !== "") ?
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div className="rsvp-wrap">
                  <div className="col-12">
                    <div className="section-title section-title4 text-center">
                      <h2>Aquí puedes confirmar tu asistencia.</h2>
                      <p>Por favor envia antes de {' '}
                        {
                          (welcomeObject.wedding_confirmation_date !== '') ?
                            formatDate(welcomeObject.wedding_confirmation_date) :
                            (welcomeObject.wedding_date) ?
                              formatDate(welcomeObject.wedding_date) :
                              formatDate('2026-10-10')
                        } .
                      </p>
                      <ConfirmationButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> : ''
      }
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div className="rsvp-wrap">
              <div className="col-12">
                <div className="section-title section-title4 text-center">
                  <h2>Envía tus mejores deseos a la pareja</h2>
                  {
                    (!welcomeObject.kids) ?
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="info">Respetuosamente no niños</Alert>
                      </Stack>
                      : ''
                  }
                  {
                    (confirmedGuest) ?
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="success">Tu mensaje ha sido enviado</Alert>
                      </Stack>
                      : ''
                  }
                </div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="contact-form form-style">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        value={rsvpReduxState.name}
                        onChange={changeHandler}
                        placeholder="Tu Nombre*"
                        id="fname"
                        name="name"
                        autoComplete='name'
                      />
                      <p>{stateForm.error.name ? stateForm.error.name : ''}</p>
                    </div>
                    <div className="col-12 col-sm-12">
                      <textarea className="contact-textarea" value={rsvpReduxState.notes} onChange={changeHandler} placeholder="Envia tus mejores deseos" name="notes"></textarea>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      id="submit"
                      type="submit"
                      className="submit"
                      disabled={welcomeObject.show_final_assistance && rsvpReduxState.phone === ''}
                    >Enviar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {
        (rsvpReduxState.confirmed)
          ? <BestWishes /> : ''
      }
    </div>
  )
}

export default Rsvp;
