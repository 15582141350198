import img_bg2 from '../../images/img_bg_2.jpg';
import '../../css/style.css';
import '../../css/bootstrap.css';
import '../../css/icomoon.css';
import groom1 from '../../images/groom.jpg';
import bride1 from '../../images/bride.jpg';
import imgBg3 from '../../images/img_bg_3.jpg';
import imgCouple1 from '../../images/couple-1.jpg';
import imgCouple2 from '../../images/couple-2.jpg';
import imgCouple3 from '../../images/couple-3.jpg';
import GalleryExample from './galleryExample';
import CountDown from 'react-countdown';
import attendingImg from '../../images/img_bg_4.jpg';
import YTComponent from '../../../../ReactPlayer';

const Example = () => {
  const ytid = "https://www.youtube.com/watch?v=kPhpHvnnn0Q";
  const formatNumber = (number) => String(number).padStart(2, '0');
  return (
    <div>
      <div
        id="fh5co-header"
        className="fh5co-cover"
        role="banner"
        style={{ backgroundImage: `url(${img_bg2})` }}
        data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container" style={{ backgroundImage: img_bg2 }} >
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center">
              <div className="display-t">
                <div className="display-tc animate-box" data-animate-effect="fadeIn">
                  <h1>Harry &amp; Nancy</h1>
                  <h2>Nos Vamos A Casar</h2>
                  <CountDown
                    date={new Date('2026-12-31')}
                    renderer={({ days, hours, minutes, seconds }) =>
                      <div className="simply-countdown simply-countdown-one">
                        <div className="simply-section simply-days-section"><div><span className="simply-amount">{formatNumber(days)}</span><span className="simply-word">days</span></div></div>
                        <div className="simply-section simply-hours-section"><div><span className="simply-amount">{formatNumber(hours)}</span><span className="simply-word">hours</span></div></div>
                        <div className="simply-section simply-minutes-section"><div><span className="simply-amount">{formatNumber(minutes)}</span><span className="simply-word">minute</span></div></div>
                        <div className="simply-section simply-seconds-section"><div><span className="simply-amount">{formatNumber(seconds)}</span><span className="simply-word">seconds</span></div></div>
                      </div>
                    }
                  />
                  <p>
                    <a
                      href="/"
                      style={{ pointerEvents: 'none', textDecoration: 'none' }}
                      className="btn btn-default btn-sm">
                      Reserva la fecha
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <YTComponent
        url={ytid}
        autoplay={true} onPreview={true}
      />

      <div id="fh5co-couple">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <h2>Hello!</h2>
              <h3>November 28th, 2016 New York, USA</h3>
              <p>We invited you to celebrate our wedding</p>
            </div>
          </div>
          <div className="couple-wrap animate-box">
            <div className="couple-half">
              <div className="groom">
                <img src={groom1} alt="groom" className="img-responsive" />
              </div>
              <div className="desc-groom">
                <h3>Joefrey Mahusay</h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                  blind texts. Separated they live in Bookmarksgrove</p>
              </div>
            </div>
            <p className="heart text-center"><i className="icon-heart2"></i></p>
            <div className="couple-half">
              <div className="bride">
                <img src={bride1} alt="groom" className="img-responsive" />
              </div>
              <div className="desc-bride">
                <h3>Sheila Mahusay</h3>
                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                  blind texts. Separated they live in Bookmarksgrove</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="fh5co-event" className="fh5co-bg"
        style={{ backgroundImage: `url(${imgBg3})` }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <span>Our Special Events</span>
              <h2>Wedding Events</h2>
            </div>
          </div>
          <div className="row">
            <div className="display-t">
              <div className="display-tc">
                <div className="col-md-10 col-md-offset-1">
                  <div className="col-md-6 col-sm-6 text-center">
                    <div className="event-wrap animate-box">
                      <h3>Main Ceremony</h3>
                      <div className="event-col">
                        <i className="icon-clock"></i>
                        <span>4:00 PM</span>
                        <span>6:00 PM</span>
                      </div>
                      <div className="event-col">
                        <i className="icon-calendar"></i>
                        <span>Monday 28</span>
                        <span>November, 2016</span>
                      </div>
                      <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there
                        live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a
                        large language ocean.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 text-center">
                    <div className="event-wrap animate-box">
                      <h3>Wedding Party</h3>
                      <div className="event-col">
                        <i className="icon-clock"></i>
                        <span>7:00 PM</span>
                        <span>12:00 AM</span>
                      </div>
                      <div className="event-col">
                        <i className="icon-calendar"></i>
                        <span>Monday 28</span>
                        <span>November, 2016</span>
                      </div>
                      <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there
                        live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a
                        large language ocean.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="fh5co-couple-story">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <span>We Love Each Other</span>
              <h2>Our Story</h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                blind texts.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-md-offset-0">
              <ul className="timeline animate-box">
                <li className="animate-box">
                  <div className="timeline-badge" style={{ backgroundImage: `url(${imgCouple1})` }}></div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h3 className="timeline-title">First We Meet</h3>
                      <span className="date">December 25, 2015</span>
                    </div>
                    <div className="timeline-body">
                      <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there
                        live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a
                        large language ocean.</p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted animate-box">
                  <div className="timeline-badge" style={{ backgroundImage: `url(${imgCouple2})` }}></div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h3 className="timeline-title">First Date</h3>
                      <span className="date">December 28, 2015</span>
                    </div>
                    <div className="timeline-body">
                      <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there
                        live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a
                        large language ocean.</p>
                    </div>
                  </div>
                </li>
                <li className="animate-box">
                  <div className="timeline-badge" style={{ backgroundImage: `url(${imgCouple3})` }}></div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h3 className="timeline-title">In A Relationship</h3>
                      <span className="date">January 1, 2016</span>
                    </div>
                    <div className="timeline-body">
                      <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there
                        live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a
                        large language ocean.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="fh5co-started" className="fh5co-bg"
        style={{
          background: `url(${attendingImg})`
        }}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row animate-box">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2>Se nuestro Invitado</h2>
              <p>Por favor llena la siguiente información para confirmar tu asistencia</p>
            </div>
          </div>
          <div className="row animate-box">
            <div className="col-md-10 col-md-offset-1">
              <form className="form-inline">
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label for="name" className="sr-only">Nombre</label>
                    <input type="name" className="form-control" id="name" placeholder="Name" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label for="email" className="sr-only">Correo</label>
                    <input type="email" className="form-control" id="email" placeholder="Email" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <button disabled type="submit" className="btn btn-default btn-block">Asistiré</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <GalleryExample />

    </div>
  );
}

export default Example;
