import { useAppSelector } from '../../../../../app/hooks';
import FamilyComponent from '../FamilyComponent';

const Family = () => {

  const weddingInformation = useAppSelector((state) => (state.wedding));

  return (
    <div id="family" className="family-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box">
              <h2>Familia</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {
            weddingInformation.groomsman_file_info.map((family, index) => (
              <FamilyComponent family={family} key={index} />
            ))
          }
          {
            weddingInformation.bridemaids_file_info.map((family, index) => (
              <FamilyComponent family={family} key={index} />
            ))
          }          
        </div>
      </div>
    </div>
  )
}

export default Family;