import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import { useAppSelector } from "../../app/hooks";
import HeroComponent from "../heroComponents/hero";

const SimpleSlider = () => {
  
  var settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true
  };

  const weddingObj = useAppSelector((state) => state.wedding);
  const rsvpReduxState = useAppSelector((state) => state.guest);

  return (
    <Slider {...settings}>
      {
        weddingObj.front_file_info.map((component, indexComponent) => (
          <HeroComponent
            key={"herocomponent_" + indexComponent}
            id={indexComponent}
            subtitleDescription={
              weddingObj.front_tittle
                ? weddingObj.front_tittle + ' ' + rsvpReduxState.name
                : 'NOS VAMOS A CASAR'
            }
            slideDescription={
              weddingObj.front_tittle
                ? weddingObj.front_tittle
                : 'Reserva la fecha'
            }
            slideDate={
              weddingObj.wedding_date
                ? weddingObj.wedding_date
                : '25 Diciembre 2025'
            }
            url={component.url}
          />
        ))
      }
    </Slider>
  );
}

export default SimpleSlider;