import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateCountdownImage, updateOldWedding, loadingComponent } from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api'
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';

const successAlert = () => {
  let mensaje = 'Imagen guardada correctamente.'
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}
const CountDownuploadSideBar = () => {

  const submitHandler = async () => {
    try {
      dispatch(loadingComponent(true));
      if (document.getElementById("fileupload").files.length === 1) {
        await uploadImageCountdown(
          fileCountdownInformation.countdown_file[0].filename,
          document.getElementById("fileupload").files[0]
        )
      }
      const updateObj = await docdbMethods.getDifferenceObj(fileCountdownInformation, fileCountdownInformation.previousdoc)
      docdbMethods.updateDocument(fileCountdownInformation.wedding, updateObj).then(res => {
        dispatch(updateOldWedding(updateObj))
        successAlert()
      })
    } catch (error) {
      alert("Error al salvar los cambios", error.message)
    }
    finally {
      dispatch(loadingComponent(false));
    }
  }

  const uploadImageCountdown = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const updateFileCountdown = async (e) => {
    var obj_file = await s3request.buildObjImage(e, fileCountdownInformation.wedding);
    var arr_countdown = [obj_file];
    dispatch(updateCountdownImage(arr_countdown));
  }

  const dispatch = useAppDispatch();
  const fileCountdownInformation = useAppSelector((state) => (state.wedding));

  return (
    <div>
      <form onSubmit={submitHandler}>
        <p className='couple-img-p'>Imagen del contador</p>
        <div style={{ display: 'flex' }}>
          <input
            style={{ width: '185px' }}
            type="text" disabled
            className="form-control" placeholder="Upload your image"
            value={(fileCountdownInformation.countdown_file)
              ? fileCountdownInformation.countdown_file[0].filename.split('\\').pop().split('/').pop()
              : "" || ''}
          />
          <label htmlFor="fileupload" className="custom-file-upload">
            <i className="fa fa-upload"></i>
          </label>
          <input
            type="file"
            accept="image/*"
            className="form-control"
            id="fileupload"
            onChange={(e) => updateFileCountdown(e)}
          />
        </div>
        <div>
          <div className="text-center">
            <Tooltip
              title="Guardar cambios"
            >
              <CloudUploadTwoToneIcon
                onClick={() => submitHandler()}
                style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
              />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CountDownuploadSideBar
