import React from 'react';
import '../rsvp/style.css'
import Checkout from '../CheckOut';
import docdbMethods from '../../features/apis/docdb';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setTransactionInfo, loadingComponent } from '../../features/wedding-state/wedding-state';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { setNewTransaction } from '../../features/user-state/user-state';
import paymentMethods from '../../features/apis/payment';
import { useNavigate } from 'react-router-dom';
import alertMethods from '../../features/alerts/alerts';
import s3request from '../../features/apis/s3api';
import { Link } from 'react-router-dom';

const ShoppingCart = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const successAlert = () => {
    let mensaje = 'Transacción realizada correctamente.';
    alertMethods.generalAlert('', 'success', mensaje, 3000, true)
  }
  const errorAlert = (mensaje) => {
    alertMethods.generalAlert('', 'error', mensaje, 3000, true)
  }

  const generateFinalFile = async () => {
    let filename = `wedding/${weddingInformation.wedding}/${weddingInformation.wedding}.json`;
    const copyWeddingInformation = { ...weddingInformation };
    delete copyWeddingInformation.guests;
    delete copyWeddingInformation.previousdoc;
    let content_type = "application/json";
    let confirmation = await s3request.uploadImage(
      filename,
      content_type,
      copyWeddingInformation
    )
    if (confirmation && confirmation.status === "error") {
      errorAlert(confirmation.message);
    }
    return filename;
  }

  const handleSubmit = async (setPaymentIssue, setBillingIssue) => {
    try {
      const urlBase = window.location.origin;
      dispatch(loadingComponent(true));
      if (
        userInformation.billingInformation.firstName !== ""
        && userInformation.billingInformation.lastName !== ""
        && userInformation.billingInformation.address1 !== ""
        && userInformation.billingInformation.city !== ""
        && userInformation.billingInformation.zip !== ""
        && userInformation.billingInformation.country !== ""
        && weddingInformation.price_amount !== ""
        && userInformation.billingInformation.email !== ""
        //&& parseInt(weddingInformation.price_amount) > 0
        && paymentInformation.ccNumber !== ""
        && paymentInformation.ccExpiration !== ""
        && paymentInformation.ccCVV !== ""
        && paymentInformation.nameOnCard !== ""
      ) {
        setPaymentIssue("");
        const paymentObject = await paymentMethods.getPaymentObject(
          userInformation,
          weddingInformation,
          paymentInformation,
          urlBase + '/complete',
        );
        console.log(paymentObject);
        const responsePayment =
          (parseInt(weddingInformation.price_amount) > 0)
            ? await paymentMethods.getPaymentPage(paymentObject)
            : { result: 1, idTransaction: 1, responseAuthorization: 1 };
        if (responsePayment && responsePayment.result === 1) {
          const finalFileName = await generateFinalFile();
          const now = new Date();
          const dataString = now.toISOString();
          const weddingTransactionInformation = {
            idTransaction: responsePayment.idTransaction,
            responseAuthorization: responsePayment.responseAuthorization,
            completeFileName: finalFileName,
          }
          const objUsrTransaction = {
            wedding: weddingInformation.wedding,
            amount: weddingInformation.price_amount,
            idTransaction: responsePayment.idTransaction,
            responseAuthorization: responsePayment.responseAuthorization,
            timestamp: dataString,
          }
          const copyUsrTransaction = [...userInformation.transactionInformation];
          copyUsrTransaction.push(objUsrTransaction);
          await docdbMethods.updateUserDocument(
            userInformation.userName,
            { transactionInformation: copyUsrTransaction }
          ).then(async (res) => {
            if (res.status !== "error") {
              dispatch(setNewTransaction(objUsrTransaction));
              await docdbMethods.updateDocument(weddingInformation.wedding, weddingTransactionInformation).then((res) => {
                dispatch(setTransactionInfo(weddingTransactionInformation));
                successAlert();
                setTimeout(() => {
                  navigate('/complete', { replace: true });
                }, 2000);
              }).catch((err) => errorAlert(err.message));
            } else errorAlert(res.message);
          });
        } else {
          setPaymentIssue(responsePayment.responseText);
        }
      }
      else {
        setBillingIssue(true);
      }
    } catch (e) {
      console.log("aqui", e);
      errorAlert(e.message);
    } finally {
      dispatch(loadingComponent(false));
    }
  }

  const handleClose = () => {
    dispatch(loadingComponent(false))
  }

  const paymentInformation = useAppSelector((state) => (state.pay));
  const userInformation = useAppSelector((state) => (state.user));
  const weddingInformation = useAppSelector((state) => (state.wedding));

  return (
    <div id="completeForm" className="rsvp-area go-rsvp-area section-padding">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={weddingInformation.loadingComponent}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-2 col-md-10 offset-md-1">
            <div className="rsvp-wrap">
              <ul className='shop-arrow-container'>
                <li><Link to="/Admin"><i className="fa fa-arrow-left" title='Regresar'></i></Link></li>
              </ul>
              <div className="col-12">
                <div className="section-title section-title4 text-center">
                  <h2>Ya casi terminamos </h2>
                  <p>Ingresa tu informacion para ejecutar el pago</p>
                </div>
              </div>
              <Checkout handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCart;
