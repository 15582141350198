import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PayState {
  ccNumber: string;
  ccExpiration: string;
  ccCVV: string;
  nameOnCard: string;
  cardType: string;
}

const initialState: PayState = {
  ccNumber: '',
  ccExpiration: '',
  ccCVV: '',
  nameOnCard: '',
  cardType: 'Visa',
}

const payStateSlice = createSlice({
  name: 'pay',
  initialState,
  reducers: {
    setPayCVV(state, action: PayloadAction<string>) {
      state.ccCVV = action.payload;
    },
    setPayCard(state, action: PayloadAction<string>) {
      state.ccNumber = action.payload;
    },
    setPayExpiration(state, action: PayloadAction<string>) {
      state.ccExpiration = action.payload;
    },
    setPayName(state, action: PayloadAction<string>) {
      state.nameOnCard = action.payload;
    },
    setPayCardType(state, action: PayloadAction<string>) {
      state.cardType = 'Visa';
    },
  },
});

export const {
  setPayCVV,
  setPayCard,
  setPayExpiration,
  setPayName,
  setPayCardType
} = payStateSlice.actions;
export default payStateSlice.reducer;
