import React, { useState, useEffect } from 'react';
import 'react-fancybox/lib/fancybox.css';
import axios from 'axios';
import ComponentModal from '../ComponentModal';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { upadteCoupleTemplate } from '../../features/wedding-state/wedding-state';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Particles from 'react-particles-js';
import stockShape2 from '../../images/hero/icon-new-color.png';
import classes from '../../css/BackgroundVideo.module.css';
import CountDown from 'react-countdown';

const StyleComponent = ({ TemplateComponent, ExampleComponent, index, name, description, methodAlert = null }) => {

  const dispatch = useAppDispatch();
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [isExamplePreviewVisible, setExamplePreviewVisible] = useState(false);

  const openPreview = () => {
    setPreviewVisible(true);
  };

  const closePreview = () => {
    setPreviewVisible(false);
    setExamplePreviewVisible(false);
  };

  const openExamplePreview = () => {
    setExamplePreviewVisible(true);
  }

  const handleStyleChange = (styleId) => {
    dispatch(upadteCoupleTemplate(styleId))
  };

  const styleTemplateId = useAppSelector((state) => (state.wedding.template));
  const formatNumber = (number) => String(number).padStart(2, '0');

  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('https://api.unsplash.com/photos/random', {
          params: {
            query: 'wallpapers',
            client_id: '0vDnKqPSVKmeTiQwQWdgh-zefgebtggUM1-HY-VgGoY',
          },
        });
        setImageUrl(response.data.urls.regular);
      } catch (error) {
        console.error('Error fetching image from Unsplash:', error);
      }
    };

    fetchImage();
  }, []);

  return (
    <div key={'style-img' + index} className="col-lg-6 col-md-6 col-sm-6 col-12">
      <Grid item key={'style-img' + index} xs={12} sm={6} md={4}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: (styleTemplateId === index.toString() || styleTemplateId === index) ? 'lightblue' : null
          }}
        >
          <div className="style-img styles-list">
            <div style={{ position: 'relative' }}>
              <CardMedia
                component="div"
                image={ imageUrl }
                sx={{
                  pt: '65%',
                }}
              />
              <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                {
                  index === 0 &&
                  <div className={classes.Content}>
                    <div className="container" style={{
                      width: 'auto',
                      height: '255px'
                    }}>
                      <div className="slide-content"
                        style={{
                          width: '300px',
                          height: '255px',
                          marginTop: '0px',
                        }}
                      >
                        <div className="slide-subtitle">
                          <h4>NOS VAMOS A CASAR</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  index === 3 &&
                  <Particles
                    className="particaleWrapper"
                    params={{
                      particles: {
                        number: {
                          value: 40,
                          density: {
                            enable: true,
                            value_area: 800
                          }
                        },
                        line_linked: {
                          enable: false
                        },
                        move: {
                          speed: 1.5,
                          out_mode: "in"
                        },
                        shape: {
                          type: [
                            "images",
                            "circle"
                          ],
                          images: [
                            {
                              src: `${stockShape2}`,
                              height: 13,
                              width: 15,
                            },
                            {
                              src: `${stockShape2}`,
                              height: 20,
                              width: 20
                            },
                            {
                              src: `${stockShape2}`,
                              height: 2,
                              width: 46
                            },
                            {
                              src: `${stockShape2}`,
                              height: 29,
                              width: 33,
                            },
                            {
                              src: `${stockShape2}`,
                              height: 10,
                              width: 12,
                            },
                            {
                              src: `${stockShape2}`,
                              height: 21,
                              width: 22
                            },
                            {
                              src: `${stockShape2}`,
                              height: 21,
                              width: 22
                            },
                            {
                              src: `${stockShape2}`,
                              height: 5,
                              width: 7
                            },
                            {
                              src: `${stockShape2}`,
                              height: 13,
                              width: 15,
                            },
                            {
                              src: `${stockShape2}`,
                              height: 20,
                              width: 20
                            },
                            {
                              src: `${stockShape2}`,
                              height: 2,
                              width: 46
                            },
                            {
                              src: `${stockShape2}`,
                              height: 29,
                              width: 33,
                            },
                            {
                              src: `${stockShape2}`,
                              height: 10,
                              width: 12,
                            },
                            {
                              src: `${stockShape2}`,
                              height: 21,
                              width: 22
                            },
                            {
                              src: `${stockShape2}`,
                              height: 21,
                              width: 22
                            },
                            {
                              src: `${stockShape2}`,
                              height: 5,
                              width: 7
                            },
                          ]
                        },
                        color: {
                          value: "#eae3d1"
                        },
                        size: {
                          value: 20,
                          random: true,
                          anim: {
                            enable: true,
                            speed: 2,
                            size_min: 10,
                            sync: true
                          }
                        }

                      },
                      opacity: {
                        value: 0.4008530152163807,
                        random: false,
                        anim: {
                          enable: false,
                          speed: 1,
                          opacity_min: 0.1,
                          sync: false
                        }
                      },
                      interactivity: {
                        detect_on: "window",
                        events: {
                          onhover: {
                            enable: true,
                            mode: "repulse"
                          },
                          onclick: {
                            enable: false,
                            mode: "bubble"
                          },
                          resize: true
                        },
                        modes: {
                          grab: {
                            distance: 400,
                            line_linked: {
                              opacity: 1
                            }
                          },
                          repulse: {
                            distance: 100,
                            duration: 0.4
                          },
                          push: {
                            particles_nb: 4
                          },
                          remove: {
                            particles_nb: 2
                          }
                        }
                      },
                      retina_detect: false
                    }}
                  />
                }
                {
                  index === 2 &&
                  <div className={classes.Content}>
                    <div className="container" style={{
                      width: 'auto',
                      height: '320px'
                    }}>
                    </div>
                  </div>
                }
                {
                  index === 1 &&
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <div className="display-t">
                        <div className="display-tc animate-box" data-animate-effect="fadeIn">
                          <CountDown
                            date={new Date('2026-12-31')}
                            renderer={({ days, hours, minutes, seconds }) =>
                              <div className="simply-countdown simply-countdown-one">
                                <div className="simply-section simply-days-section"><div><span className="simply-amount">{formatNumber(days)}</span><span className="simply-word">days</span></div></div>
                                <div className="simply-section simply-hours-section"><div><span className="simply-amount">{formatNumber(hours)}</span><span className="simply-word">hours</span></div></div>
                                <div className="simply-section simply-minutes-section"><div><span className="simply-amount">{formatNumber(minutes)}</span><span className="simply-word">minute</span></div></div>
                                <div className="simply-section simply-seconds-section"><div><span className="simply-amount">{formatNumber(seconds)}</span><span className="simply-word">seconds</span></div></div>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography>
              {description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={openExamplePreview}>Ejemplo</Button>
            <Button size="small" onClick={() => {
              handleStyleChange(index);
              if (methodAlert) methodAlert()
            }}>Elegir</Button>
            <Button size="small" onClick={openPreview}>Diseño</Button>
          </CardActions>
        </Card>
        <ComponentModal
          component={<TemplateComponent onPreview={true} />}
          defaultState={isPreviewVisible}
          handleFunction={closePreview}
        />
        {
          (ExampleComponent)
            ?
            <ComponentModal
              component={<ExampleComponent onPreview={true} />}
              defaultState={isExamplePreviewVisible}
              handleFunction={closePreview}
            />
            : null
        }
      </Grid>
    </div>
  );
}

export default StyleComponent;
