import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import alertMethods from '../../features/alerts/alerts';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { updateCelebration } from '../../features/guest_state/guest_state';
import docdbMethods from '../../features/apis/docdb';
import { setGuestInfo } from '../../features/guest_state/guest_state';
import InputModal from './confirmationModal';

const ConfirmationButton = () => {

  const saveGuestsConfirmation = async (guestObj) => {
    try {
      docdbMethods.confirmationGuest(welcomeObject.wedding, guestObj).then(res => {
        if (res.status && res.status === "error") {
          alertMethods.generalAlert('', 'error', 'Error al salvar los cambios.' + res.message, 3000, true)
          return;
        }
        var confirmationMessage = 'Hemos confirmado tu asistencia.';
        var positiveConfirmations = 1;
        if (guestObj.guestNames
          && guestObj.guestNames.length > 0
          && guestObj.guestNames[0].name !== ""
        ) {
          positiveConfirmations = (guestObj.guestNames.filter(guest => guest.confirmed === true)).length;
          confirmationMessage = `Hemos confirmado asistencia ${positiveConfirmations} de ${guestObj.guestNames.length} invitados.`
        }
        alertMethods.generalAlert('', 'success', confirmationMessage, 3000, true)
        if (positiveConfirmations >= 1) {
          dispatch(updateCelebration(true));
          setTimeout(() => {
            dispatch(updateCelebration(false));
          }, 5000);
        }
      })
    } catch (error) {
      alertMethods.generalAlert('', 'error', 'Error al salvar los cambios.', 3000, true)
    } finally { }
  }

  const [open, setOpen] = useState(false);
  const welcomeObject = useAppSelector((state) => state.wedding);

  const handleClick = () => {
    const guestInfo = { ...rsvpReduxState };
    delete guestInfo.error;
    guestInfo.confirmed = true;
    dispatch(setGuestInfo({ key: 'confirmed', value: true }));
    if (guestInfo.guestNames
      && guestInfo.guestNames.length > 0
      && guestInfo.guestNames[0].name !== ""
    )
      setOpen(true);
    else {
      dispatch(setGuestInfo({
        key: 'guestNames', value: [
          {
            name: guestInfo.name,
            confirmed: true
          }
        ]
      }));
      setOpen(true);
    }
    return;
  };

  const saveAfterModal = () => {
    const guestInfo = { ...rsvpReduxState };
    delete guestInfo.error;
    saveGuestsConfirmation(guestInfo);
    setOpen(false);
  }

  const updateGuestConfirmation = (index, confirmation) => {
    const guestNamesArr = [...rsvpReduxState.guestNames];
    const guestName = { ...guestNamesArr[index] };
    guestName.confirmed = confirmation;
    guestNamesArr[index] = guestName;
    dispatch(setGuestInfo({ key: 'guestNames', value: guestNamesArr }));
  }

  const AnimatedButton = styled(Button)(({ theme }) => ({
    transition: theme.transitions.create(['background-color', 'border-color', 'box-shadow'], {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {},
  }));

  const rsvpReduxState = useAppSelector((state) => state.guest);
  const dispatch = useAppDispatch();

  const setCloseModal = () => {
    setOpen(false);
  }

  return (
    <>
      <InputModal
        open={open}
        saveMethod={updateGuestConfirmation}
        arrMembers={rsvpReduxState.guestNames && rsvpReduxState.guestNames.length > 0 ? [...rsvpReduxState.guestNames] : []}
        setOpen={setCloseModal}
        completeConfirmation={saveAfterModal}
      />
      {
        rsvpReduxState.phone !== "" ?
          <AnimatedButton
            variant="contained"
            onClick={handleClick}
            sx={{
              color: rsvpReduxState.confirmed ? 'white' : 'grayblue',
              borderColor: rsvpReduxState.confirmed ? '' : 'grayblue',
              backgroundColor: rsvpReduxState.confirmed ? 'green' : 'grayblue',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '0.7rem',

              '&.MuiButton-contained': {
                color: 'white',
                backgroundColor: 'green',
                '&:hover': {
                  backgroundColor: 'darkgreen', // Color más oscuro al hacer hover
                },
              },
            }}
          >
            {(!rsvpReduxState.confirmed) ? "Asistencia" : "Asistencia"}
          </AnimatedButton>
          : null
      }
    </>
  )
}

export default ConfirmationButton;
