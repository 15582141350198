import React, { useEffect } from 'react';
import './style.css'
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Login = () => {

  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();
  let from = '/Admin';

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <div id="LoginForm" className="rsvp-area go-rsvp-area section-padding">
      <div id="Login" className="row">
        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <Authenticator
              loginMechanisms={['email']}
              socialProviders={['google']}
            >
            </Authenticator>
        </div>
      </div>
    </div>
  );
}

export default Login;
