import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateToggleDrawer } from '../../features/wedding-state/wedding-state';
import { styled, createTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 280;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

const DrawerFunctions = () => {

  const drawerSection = useAppSelector((state) => (state.wedding))
  const dispatch = useAppDispatch();
  const open = drawerSection.toggle_drawer;

  const toggleDrawer = () => {
    dispatch(updateToggleDrawer(!open))
  };

  const closeDrawer = () => {
    dispatch(updateToggleDrawer(open))
  }

  return { open, toggleDrawer, closeDrawer, Drawer, drawerWidth, defaultTheme };
};

export default DrawerFunctions;
