import React, {useEffect} from 'react';
import defaultFrontImage from '../../../images/slider/1.jpg';
import ConfirmationButton from '../../ConfirmationButton';

const HeroComponent = ({ id, subtitleDescription, slideDescription, slideDate, url }) => {

  useEffect(() => {
    let idObject = "home"
    if (url && url !== "") {
      if(id !== 0) idObject += id
      document.getElementById(idObject).style.background = `url(${url}) center center/cover`
    } else {
      document.getElementById(idObject).style.background = `url(${defaultFrontImage}) center center/cover`
    }
  },[url, id]);

  return (
    <div id={id !== 0 ? "home"+id: "home"} className="item1 h-front">
      <div className="container front1">
        <div className="slide-content">
          <div className="slide-subtitle">
            <h4>{subtitleDescription}</h4>
          </div>
          <div className="slide-title">
            <h2>{slideDescription}</h2>
          </div>
          <div className="slide-text">
            <p>{slideDate}</p>
          </div>          
        </div>
        <div className="slide-confirmation-button">
          <ConfirmationButton />
        </div>
      </div>      
    </div>
  )
}

export default HeroComponent;
