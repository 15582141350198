import { useEffect } from 'react';
import Couple from '../../components/couple';
import SimpleSlider from '../../components/hero';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import People from '../../components/people';
import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import { useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setShowHomeTour } from '../../features/user-state/user-state';
import useMediaQuery from '@mui/material/useMediaQuery';
import YTComponent from '../../components/ReactPlayer';
import CollaboratorsButton from '../../components/Collaborators';

const Homepage = ({ onPreview }) => {
  const { setIsOpen } = useTour();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { route } = useAuthenticator(context => [context.route]);
  const weddingInformation = useAppSelector((state) => (state.wedding));
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const userInformation = useAppSelector((state) => (state.user));
  const isMobiled = useMediaQuery('(max-width: 991px)')

  const loadingUserInformation = () => {
    window.location.reload();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (route === 'setup' && authStatus === 'authenticated') {
      return loadingUserInformation();
    }
    if (location.pathname === '/home'
      && route === 'setup'
      && weddingInformation.wedding === 0
    ) {
      if (userInformation.showHomeTour) {
        setIsOpen(true);
        dispatch(setShowHomeTour(false));
      }
    }
  }, [
    location.pathname,
    setIsOpen,
    route,
    weddingInformation.wedding,
    location,
    authStatus,
    dispatch,
    userInformation.showHomeTour,
    isMobiled
  ]);

  return (
    <div>
      <Navbar onPreview={onPreview} />
      <SimpleSlider />
      {
        (weddingInformation.music_yt_id && weddingInformation.music_yt_id !== "")
          ?
          <YTComponent url={weddingInformation.music_yt_id} autoplay={true} onPreview={onPreview}/>
          : ''
      }
      <Saveday />
      <Couple />
      <Welcome />
      <Story />
      {weddingInformation.people_visible
        ? <People />
        : ''}

      <Location />
      <Gallery />
      <Rsvp />
      {
        (weddingInformation.wedding_giftstore_visible) ?
          <Gift /> : ''
      }
      <CollaboratorsButton />
    </div>
  )
}

export default Homepage;
