import { useAppSelector } from '../../../../../app/hooks';
import GalleryComponent from '../../../Style2/components/GalleryComponent';
import imgSample from '../FamilyComponent/1.jpg';


const Gallery = () => {

  const weddingInformation = useAppSelector((state) => (state.wedding));

  return (
    <div id="gallery" className="gallery-box">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box">
              <h2>Galería</h2>
              <p>Galería de Recuerdos</p>
            </div>
          </div>
        </div>
        <div className="row row-bottom-padded-md">
          <div className="col-md-12">
            <ul id="fh5co-gallery-list">
              {
                weddingInformation.album_file_info.map((galleryObj, index) => (
                  <GalleryComponent
                    key={"GalleryObj" + index}
                    galleryImg={(galleryObj.url && galleryObj.url !== "") ? galleryObj.url : imgSample}
                    index={index}
                  />
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery;