import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import Pricing from '../Pricing';
import SummaryForm from '../SummaryForm';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://invitegt.com/">
        Invitegt.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Checkout = ({ handleSubmit }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [billingIssue, setBillingIssue] = React.useState(false);
  const [paymentIssue, setPaymentIssue] = React.useState("");

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setBillingIssue(false);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setBillingIssue(false);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Pricing />;
      case 1:
        return <AddressForm />;
      case 2:
        return <PaymentForm />;
      case 3:
        return <SummaryForm />;
      default:
        throw new Error('Unknown step');
    }
  }

  const steps = ['Plan', 'Address', 'Payment', 'Summary'];
  

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          {
            (paymentIssue !== "") ?
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {paymentIssue} — <strong>Valida tu información!</strong>
              </Alert>
              : ''
          }
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {getStepContent(activeStep)}
            {
              (billingIssue) ?
                <Alert severity="error">Hay algunos campos que falta llenar!</Alert>
                : ''
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button>
              )}
              {
                activeStep === steps.length - 1 ?
                  <Button
                    variant="contained"
                    onClick={()=> handleSubmit(setPaymentIssue,setBillingIssue)}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {'Place order'}
                  </Button>
                  :
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {'Next'}
                  </Button>
              }
            </Box>
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}

export default Checkout;
