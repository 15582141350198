import React, { useState } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import './style.css';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoginIcon from '@mui/icons-material/Login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import pink from '../../images/BEIGE.png';
import Button from '@mui/material/Button';
import sectionsImages from '../../features/loadobjects';
import { updateLoadPreview, loadingComponent } from '../../features/wedding-state/wedding-state';
import WeddingList from '../WeddingList';

const MobileMenu = ({ onPreview }) => {

  const [isMenuShow, setMenuShow] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isOpenProfile, setOpenProfile] = useState(null);

  const menuHandler = () => {
    setMenuShow(!isMenuShow);
  }

  const setIsOpen = () => {
    setOpen(!isOpen);
  }

  const loadAllSections = async () => {
    try {
      dispatch(loadingComponent(true));
      dispatch(updateLoadPreview(true));
      await sectionsImages.getAllSections(weddingObj, dispatch);
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(updateLoadPreview(false));
      dispatch(loadingComponent(false));
    }
  }

  const dispatch = useAppDispatch();

  const weddingObj = useAppSelector((state) => state.wedding);
  const userObj = useAppSelector((state) => state.user);
  const { route } = useAuthenticator(context => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const location = useLocation();
  const currentPath = location.pathname;

  const HandleProfileClick = () => {
    setOpenProfile(!isOpenProfile);
  }

  const closeMobileMenuHandler = () => {
    setMenuShow(!isMenuShow);
  }

  return (
    <div>
      <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
        <div className="logo2">
          <h2><Link to='/home'>
            {
              (weddingObj.boy_name) ? `${weddingObj.boy_name} & ${weddingObj.girl_name}` : 'Harry & Nancy'
            }
          </Link></h2>
        </div>
        {
          (route === 'authenticated' && (currentPath === '/Admin' || currentPath === '/admin'))
            ?
            <ul className="responsivemenu">
              {
                <div>
                  <li>
                    <Link onClick={HandleProfileClick}>
                      <AccountCircleIcon />
                      {
                        (user.attributes.email)
                          ? ` ${user.attributes.email.split("@")[0].toLowerCase()}`
                          : ` ${user.username.split("@")[0].toLowerCase()}`
                      }
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </li>
                  <Collapse isOpen={isOpenProfile}>
                    <Card>
                      <CardBody>
                        <li><Link to='/home' onClick={signOut}>signOut</Link></li>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              }
              {
                (weddingObj.wedding !== 0)
                  ?
                  <li>
                    <Link to='/complete'>
                      <Button title='Complete' color='success' onClick={async () => { await loadAllSections() }} variant='contained'>
                        <VisibilityIcon />
                        Visualizar
                      </Button>
                    </Link>
                  </li>
                  : ''
              }
              {
                (!onPreview || currentPath === '/Admin')
                  ?
                  <li>
                    <WeddingList closeMobileMethod={closeMobileMenuHandler}/>
                  </li>
                  : ''
              }
              {
                (weddingObj.wedding !== 0 
                  && weddingObj.idTransaction === ''
                  ) ?
                  <li>
                    <Link to='/shopping'>
                      <Button title='Complete' color='success' onClick={async () => { await loadAllSections() }} variant='contained'>
                        <AddShoppingCartIcon />
                        Comprar
                      </Button>
                    </Link>
                  </li>
                  : ''
              }
              <li><Link to='/home'>Home</Link></li>
            </ul>
            :
            <ul className="responsivemenu">
              {
                (route !== 'authenticated') ?
                  <div>
                    <li>
                      <Link className='mobile-login-account' to='/login'><LoginIcon/>{'Login'}</Link>
                    </li>
                  </div>
                  :
                  <div>
                    <li>
                      <Link onClick={HandleProfileClick}>
                        <AccountCircleIcon />
                        {
                          (user.attributes.email)
                            ? ` ${user.attributes.email.split("@")[0].toLowerCase()}`
                            : ` ${user.username.split("@")[0].toLowerCase()}`
                        }
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <Collapse isOpen={isOpenProfile}>
                      <Card>
                        <CardBody>
                          <li><Link onClick={signOut}>signOut</Link></li>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
              }
              {
                (!onPreview && !userObj.weddingError && currentPath!== '/Admin') ? (<li><Link className='create-wedding mobile-create' to='/Admin'><AddCircleIcon />{'Crear'}</Link></li>) : ('')
              }
              <li><Link onClick={setIsOpen}>Home<i className="fa fa-angle-right" aria-hidden="true"></i></Link></li>
              <Collapse isOpen={isOpen}>
                <Card>
                  <CardBody>
                    <li><Link to='/home'>Clasico</Link></li>
                    <li><Link to='/home6'>Fancy</Link></li>
                    <li><Link to='/home3'>Festivo</Link></li>
                    <li><Link to='/home5'>Minimalista</Link></li>                    
                    <li><Link to='/styles'>Ver Mas Estilos</Link></li>
                  </CardBody>
                </Card>
              </Collapse>
            </ul>
        }
      </div>

      {
        (!onPreview) 
          ?
          <div className="showmenu mobile-login-bars" onClick={menuHandler}>
            <i className="fa fa-bars" aria-hidden="true">
            </i>
          </div>
          : 
          <div className="showmenu mobile-login-bars">
            <img src={pink} alt='logo' style={{
              maxHeight: '90px',
              maxWidth: '90px',
              position: 'relative',
              left: '0px',
              top: '-26px',
              pointerEvents: 'none',
            }} />
          </div>
      }
    </div>
  )
}

export default MobileMenu;
