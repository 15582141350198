import CollaboratorsDialog from "./Dialog";
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useAppSelector } from "../../app/hooks";
import './index.css';
import { useLocation } from 'react-router-dom';
import CollaboratorsCarousel from "./Swipeable";

const CollaboratorsButton = () => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const weddingObj = useAppSelector((state) => state.wedding);
  const location = useLocation();
  const currentPath = location.pathname;
  const isAdminPath = currentPath.startsWith('/Admin');

  const collaboratorsButton = (
    <div className="collaborator">
      <Button variant="outlined" onClick={handleOpen}>
        Conoce a nuestros Colaboradores
      </Button>
      <CollaboratorsDialog
        open={open}
        onClose={handleClose}
        collaboratorArr={weddingObj.collaborators}
      />
    </div>
  )

  const collaboratorsCarousel = (
    <CollaboratorsCarousel collaboratorArr={weddingObj.collaborators} />
  )

  // Siempre muestra CollaboratorsCarousel en la ruta de Admin
  if (isAdminPath) {
    return collaboratorsCarousel;
  }

  // Si no está en Admin y hay colaboradores con el primer nombre no vacío
  if (weddingObj.collaborators.length > 0 && weddingObj.collaborators[0].name !== "") {
    return collaboratorsButton;
  }

  // Si no está en Admin y el array de colaboradores está vacío o el primer nombre es vacío
  return undefined;
}

export default CollaboratorsButton;
