import React from 'react';
import StyleList from '../StyleList';

const SidebarStyles = () => {
  return (
    <div>
      <div name="sideBarStyles" className="guestlist-form-area">
        <div className="container" >
          <div className="section-title section-title4 text-center personal-form-wrap no-padding-mob">
            <h2>Elige tu estilo:</h2>
            <StyleList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarStyles;
