import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  updateWelcomeImage,
  updateWelcomeTitle,
  updateWelcomeText,
  updateWelcomeLocation,
  updateOldWedding,
  loadingComponent
} from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api'
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';

const successAlert = () => {
  let mensaje = 'Cambios salvados correctamente.'
  alertMethods.generalAlert('', 'success', mensaje, 2000, true)
}

const AdminSidebarWelcome = () => {

  const submitHandler = async () => {
    try {
      dispatch(loadingComponent(true));
      if (document.getElementById("welcome-fileupload").files.length === 1) {
        await uploadImageWedding(
          welcomeSection.welcome_file[0].filename,
          document.getElementById("welcome-fileupload").files[0]
        )
      }
      const updateObj = await docdbMethods.getDifferenceObj(welcomeSection, welcomeSection.previousdoc)
      docdbMethods.updateDocument(welcomeSection.wedding, updateObj).then(res => {
        dispatch(updateOldWedding(updateObj))
        successAlert()
      })
    } catch (error) {
      alert("Error al salvar los cambios", error.message)
    }
    finally {
      dispatch(loadingComponent(false));
    }
  }

  const uploadImageWedding = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const welcomeSection = useAppSelector((state) => (state.wedding))
  const dispatch = useAppDispatch();

  const updateWelcomeSection = (e) => {
    if (e.id === "welcome_tittle") {
      dispatch(updateWelcomeTitle(e.value))
    }
    else if (e.id === "welcome_text") {
      dispatch(updateWelcomeText(e.value))
    }
    else if (e.id === "welcome_location") {
      dispatch(updateWelcomeLocation(e.value))
    }
  }

  const updateFile = async (e) => {
    var obj_file = await s3request.buildObjImage(e, welcomeSection.wedding);
    var arr_welcome = [obj_file];
    dispatch(updateWelcomeImage(arr_welcome));
  }

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div style={{ display: 'flex' }}>
          <input type="text" disabled
            style={{ width: '185px' }}
            className="form-control" placeholder="Selecciona la imagen de bienvenida"
            value={(welcomeSection.welcome_file[0].filename)
              ? welcomeSection.welcome_file[0].filename.split('\\').pop().split('/').pop()
              : "" || ''} />
          <label htmlFor="welcome-fileupload" className="custom-file-upload">
            <i className="fa fa-upload"></i>
          </label>
          <input id="welcome-fileupload"
            type="file"
            accept="image/*"
            className="form-control"
            onChange={(e) => updateFile(e)} />
        </div>
        <div>
          <div className="comment-area">
            <p>Titulo</p>
          </div>
          <input
            style={{ width: '185px' }}
            id="welcome_tittle" type="text" className="form-control" placeholder="Bienvenidos a nuestro gran día"
            value={(welcomeSection.welcome_tittle) ? welcomeSection.welcome_tittle : ''}
            onChange={(e) => updateWelcomeSection(e.target)} />
        </div>
        <div className="form-textarea">
          <div className="comment-area">
            <p>Descripción</p>
          </div>
          <textarea id="welcome_text" placeholder='Welcome to one of the most important days......'
            value={(welcomeSection.welcome_text) ? welcomeSection.welcome_text : ''}
            onChange={(e) => updateWelcomeSection(e.target)} />
        </div>
        <div>
          <div className="comment-area">
            <p>Locación del evento</p>
          </div>
          <input
            style={{ width: '185px' }}
            id="welcome_location" type="text" className="form-control" placeholder="Google Maps link"
            value={(welcomeSection.welcome_location) ? welcomeSection.welcome_location : ''}
            onChange={(e) => updateWelcomeSection(e.target)}
          />
        </div>
        <br></br>
        <div>
          <div className="text-center">
            <Tooltip
              title="Guardar cambios"
            >
              <CloudUploadTwoToneIcon
                onClick={() => submitHandler()}
                style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
              />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AdminSidebarWelcome
