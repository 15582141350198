import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateTimeline, updateOldWedding, loadingComponent } from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api'
import docdbMethods from '../../../features/apis/docdb';
import ComponentModal from '../../ComponentModal';
import ReactCropComponent from '../../ReactCropEditor';
import alertMethods from '../../../features/alerts/alerts';

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const TimeLineEvent = ({ index, component }) => {

  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [srcFile, setSrcFile] = useState(null);

  const uploadImageWedding = async (filename, file) => {
    await s3request.uploadImage(
      filename,
      file.type,
      file
    )
    const updateObject = await docdbMethods.getDifferenceObj(timelineSeccion, timelineSeccion.previousdoc)
    docdbMethods.updateDocument(timelineSeccion.wedding, updateObject).then(res => {
      dispatch(updateOldWedding(updateObject))
    })
  }

  const maxSizeInBytes = 10 * 1024 * 1024; // 15 MB

  const handleFileChange = async (e) => {
    var file = e.target.files[0]
    if (file && file.size > maxSizeInBytes) {
      errorAlert('El archivo es demasiado grande. El tamaño máximo permitido es 15 MB.');
      e.target.value = ''; // Limpia el input
      return;
    } else if (e.target.files && e.target.files.length > 0) {
      setSrcFile(e.target.files[0]);
      setPreviewVisible(true);
    }
    try {
      dispatch(loadingComponent(true));
      var obj_file = await s3request.buildObjImage(e, timelineSeccion.wedding);
      var inputId = String(e.target.id).replace("memory_fileupload", "");
      var copyArray = [...timelineSeccion.timeline_file_info]
      copyArray[inputId] = { ...copyArray[inputId], ...obj_file };
      dispatch(updateTimeline(copyArray));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(loadingComponent(false));
    }
  }

  const handleChangeText = (e) => {
    var arrTime = [...timelineSeccion.timeline_file_info];
    if (e.target.id.includes("memory_tittle")) {
      let index = String(e.target.id).replace("memory_tittle", '');
      arrTime[index] = { ...arrTime[index], tittle: e.target.value };
    }
    if (e.target.id.includes("memory_date")) {
      let index = String(e.target.id).replace("memory_date", '');
      arrTime[index] = { ...arrTime[index], date: e.target.value };
    }
    if (e.target.id.includes("memory_speech")) {
      let index = String(e.target.id).replace("memory_speech", '');
      arrTime[index] = { ...arrTime[index], speech: e.target.value };
    }
    dispatch(updateTimeline(arrTime));
  }

  const timelineSeccion = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (itemId) => {
    setActiveButton(itemId === activeButton ? null : itemId);
  };

  const onResponseCropFile = async (cropFile, index = -1) => {
    var obj_file = await s3request.buildCropObjImage(srcFile.name, cropFile, timelineSeccion.wedding);
    var time_arr_copy = [...timelineSeccion.timeline_file_info]
    var time_obj_copy = { ...timelineSeccion.timeline_file_info[index] }
    time_obj_copy["url"] = obj_file["url"]
    time_obj_copy["contenttype"] = obj_file["contenttype"]
    time_obj_copy["filename"] = obj_file["filename"]
    time_arr_copy[index] = time_obj_copy
    dispatch(updateTimeline(time_arr_copy));
    setPreviewVisible(false);
    uploadImageWedding(obj_file.filename, cropFile);
  };

  const closePreview = () => {
    setPreviewVisible(false);
  };

  return (
    <div key={'story_section' + index}>
      <div className="div-btn">
        <button onClick={() => handleButtonClick(index)} className="timeline-btns">
          {"evento " + (index + 1)} {index === activeButton ? '▲' : '▼'}
        </button>
      </div>
      {index === activeButton &&
        <div key={'form-div' + index}>
          {<form defaultValue="Valor predeterminado">
            <div style={{ display: 'flex' }}>
              <input
                style={{ width: '185px' }}
                type="text" disabled className="form-control" placeholder="Select image of memory"
                value={(component.filename)
                  ? component.filename.split('\\').pop().split('/').pop()
                  : ""}
              />
              <label htmlFor={'memory_fileupload' + index} className="custom-file-upload">
                <i className="fa fa-upload"></i>
              </label>
              <input id={'memory_fileupload' + index} type="file" accept="image/*" className="form-control"
                onChange={(e) => handleFileChange(e)}
              />
              <ComponentModal
                component={
                  <ReactCropComponent
                    file={srcFile}
                    resolveFunction={onResponseCropFile}
                    index={index}
                  />
                }
                defaultState={isPreviewVisible}
                handleFunction={closePreview}
              ></ComponentModal>
            </div>
            <div>
              <div className="comment-area">
                <p>Título </p>
              </div>
              <input
                style={{ width: '185px' }}
                id={'memory_tittle' + index} type="text" className="form-control" placeholder={component.description}
                value={(component.tittle) ? component.tittle : ""}
                onChange={(e) => handleChangeText(e)}
              />
            </div>
            <div>
              <div className="comment-area">
                <p>Fecha del recuerdo </p>
              </div>
              <input
                style={{ width: '185px' }}
                id={'memory_date' + index} type="date" className="form-control" placeholder='May 20th of 2025'
                value={(component.date) ? component.date : ""}
                onChange={(e) => handleChangeText(e)}
              />
            </div>
            <div className="form-textarea">
              <div className="comment-area">
                <p>Descripción del evento</p>
              </div>
              <textarea id={'memory_speech' + index} placeholder='Brief speech about memory ...'
                value={(component.speech) ? component.speech : ""}
                onChange={(e) => handleChangeText(e)}
              />
            </div>
          </form>}
        </div>}
    </div>

  )
}

export default TimeLineEvent
