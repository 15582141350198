import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const InputModal = ({ index, saveMethod, rsvpValue, confirmed, arrMembers }) => {

  const [open, setOpen] = useState(false);

  const handleSave = () => {
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleChange = (event) => {
    if (!confirmed) {      
      const newAarrayObj = adjustArraySize(arrMembers, event.target.value);
      saveMethod(index, event.target.value, newAarrayObj);
    }
  }

  const adjustArraySize = (arrMembers, size) => {
    var newArrMembers = [];
    if (!arrMembers || arrMembers.length === 0) {
      for (let i = 0; i < size; i++) {
        newArrMembers.push({ name: '', confirmed: false });
      }
    }
    else if (size > arrMembers.length) {
      newArrMembers = [...arrMembers];
      for (let i = newArrMembers.length; i < size; i++) {
        newArrMembers.push({ name: '', confirmed: false });
      }
    } else if (size < arrMembers.length) {
      newArrMembers = [...arrMembers];
      newArrMembers.length = size;
    }
    return newArrMembers;
  }

  const handleChangeTextField = (event, indexText, indexGuest) => {
    if (event.target.value) {
      const formattedArrMembers = (arrMembers) ? [...arrMembers] : [];
      const copyArrNewMembers = [...formattedArrMembers];
      if (copyArrNewMembers.length < rsvpValue) {
        for (let i = copyArrNewMembers.length; i < rsvpValue; i++) {
          copyArrNewMembers.push({ name: '', confirmed: false });
        }
      }
      copyArrNewMembers[indexText] = { name: event.target.value, confirmed: false };
      saveMethod(indexGuest, rsvpValue, copyArrNewMembers);
    }
  }

  return (
    <div>
      <Tooltip title="Numero de invitados + acompañantes">
        <Stack direction="row" spacing={1}>
          <IconButton
            color={(confirmed) ? 'success' : 'info'}
            size='small'
            aria-label="rsvp"
            onClick={() => setOpen(true)}
          >
            <EditIcon />
            {
              (rsvpValue) ? rsvpValue : 0
            }
          </IconButton>
        </Stack>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography component={'span'} variant={'body2'} id="modal-modal-title">
            Miembros por invitación
            <Tooltip
              title={
                `Si la invitación es para una familia, 
                pareja o grupo, puedes agregar el nombre de cada miembro, 
                o puedes no agregar nombres y la confirmación 
                sera tomada por todos los miembros`
              }
            >
              <InfoIcon />
            </Tooltip>
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="rsvp-select-label">Miembros</InputLabel>
            <Select
              labelId="rsvp-select-label"
              id="rsvp-select"
              value={(rsvpValue) ? rsvpValue : '0'}
              label="Invitados"
              onChange={handleChange}
            >
              <MenuItem disabled value={'0'}>0</MenuItem>
              <MenuItem value={'1'}>1</MenuItem>
              <MenuItem value={'2'}>2</MenuItem>
              <MenuItem value={'3'}>3</MenuItem>
              <MenuItem value={'4'}>4</MenuItem>
              <MenuItem value={'5'}>5</MenuItem>
            </Select>
          </FormControl>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography component={'span'} variant={'body2'}>Invitados</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component={'span'} variant={'body2'}>
                {
                  (arrMembers && arrMembers.length > 0) ?
                    arrMembers.map((member, indexText) => {
                      return (
                        <div key={indexText}>
                          <Typography component={'span'} variant={'body2'}>
                            <Box
                              component="form"
                              sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              {
                                (!confirmed) ?
                                  <TextField
                                    id="outlined-basic"
                                    label={`Miembro ${indexText + 1}`}
                                    variant="outlined"
                                    disabled={confirmed}
                                    value={member.name}
                                    onChange={(e) => handleChangeTextField(e, indexText, index)}
                                  />
                                  : <Chip
                                    label={member.name}
                                    color={(member.confirmed) ? 'success' : 'error'}
                                  />
                              }
                            </Box>
                          </Typography>
                        </div>
                      );
                    }) : ''
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Button variant="contained" onClick={handleSave}>Confirmar</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default InputModal;
