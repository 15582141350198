import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import Homepage4 from '../HomePage4'
import Homepage5 from '../HomePage5';
import Homepage6 from '../HomePage6';
import BlogPage from '../blogpage'
import BlogDetailsPage from '../BlogDetailsPage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import './App.css';
import { RequireAuth } from '../../components/RequiredAuth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import docdbMethods from '../../features/apis/docdb';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setStoreUserProfile, setErrorPage } from '../../features/user-state/user-state';
import Completepage from '../CompletePage';
import ShoppingCart from '../../components/ShoppingCart';
import Assistance from '../../components/Assistance';
import { TourProvider } from '@reactour/tour'
import complete_steps from '../../features/tour/completet';
import CompleteForm from '../../components/CompleteForm';
import Policies from '../../components/Policies';
import Login from '../../components/Login';
import Landingpage from '../LandingPage';
import StylesPage from '../StylesPage';
import HomeTour from './HomeTour';
import AdminTour from './AdminTour';
import ProfilePage from '../ProfilePage';
import ExampleComponentS5 from '../Examples/Style5';
import ExampleComponentS6 from '../Examples/Style6';
import ExampleComponentS1 from '../Examples/Style1';
import ExampleComponentS2 from '../Examples/Style2';
import MemoryPage from '../MemoryPage';
import Confetti from 'react-confetti';

const App = () => {
  const { route } = useAuthenticator(context => [context.route]);
  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (route === 'authenticated') {
      docdbMethods.getUserDocument(user.username).then(res => {
        if (res.status === "error") {
          dispatch(setErrorPage(true));
          return;
        }
        dispatch(setStoreUserProfile(res))
      }).catch(error => alert(error))
    }
  },
    [user, route, dispatch]);

  const radius = 10;

  const weddingObject = useAppSelector((state) => (state.wedding));
  const rsvpReduxState = useAppSelector((state) => state.guest);

  return (
    <div className="App">
      {rsvpReduxState.celebration && <Confetti />}
      <Router>
        <Routes>
          <Route exact path='/home' element={
            <HomeTour />
          } />
          <Route path='/home2' element={<Homepage2 onPreview={
            (weddingObject.show_final_assistance) ? true : false
          } />} />
          <Route path='/home3' element={<Homepage3 onPreview={
            (weddingObject.show_final_assistance) ? true : false
          } />} />
          <Route path='/home4' element={<Homepage4 onPreview={
            (weddingObject.show_final_assistance) ? true : false
          } />} />
          <Route path='/home5' element={<Homepage5 onPreview={
            (weddingObject.show_final_assistance) ? true : false
          } />} />
          <Route path='/home6' element={<Homepage6 onPreview={
            (weddingObject.show_final_assistance) ? true : false
          } />} />
          <Route path='/Blog' element={<BlogPage />} />
          <Route path='/Blog-details' element={<BlogDetailsPage />} />
          <Route path='/' element={<Landingpage />} />
          <Route path='/admin' element={
              <AdminTour />
          } />
          <Route path='/complete' element={
            <RequireAuth>
              <TourProvider steps={complete_steps}
                styles={{
                  popover: (base) => ({
                    ...base,
                    '--reactour-accent': '#3F7267',
                    borderRadius: radius,
                  }),
                  maskArea: (base) => ({ ...base, rx: radius, }),
                  maskWrapper: (base) => ({ ...base, }),
                  badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
                  controls: (base) => ({ ...base, marginTop: 100 }),
                  close: (base) => ({ ...base, right: 'auto', left: 8, top: 8 }),
                }}
                badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}>
                <Completepage ReactElement={CompleteForm} />
              </TourProvider>
            </RequireAuth>
          } />

          <Route path="/id/:id/:name" element={<Assistance />} />
          <Route path="/memory/:id" element={<MemoryPage />} />
          <Route path="/shopping" element=
            {
              <RequireAuth>
                <Completepage ReactElement={ShoppingCart} />
              </RequireAuth>
            }
          />
          <Route path='/privacy_policy' element={<Policies />} />

          <Route path="/login" element=
            {
              <Completepage ReactElement={Login} />
            }
          />
          <Route path='/styles' element={<StylesPage />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/ejemplo1' element={<ExampleComponentS1 />} />
          <Route path='/ejemplo2' element={<ExampleComponentS6 />} />
          <Route path='/ejemplo3' element={<ExampleComponentS5 />} />
          <Route path='/ejemplo4' element={<ExampleComponentS2 />} />
        </Routes>
        <Footer />
        <Scrollbar />
      </Router>
    </div>
  );
}

export default App;
