import Navbar from '../../components/Navbar';
import StyleList from '../../components/StyleList';
import StepperAdmin from '../../components/Stepper'

const StylesPage = ({ onPreview }) => {
  return (
    <div>
      <Navbar onPreview={onPreview} />
      <StepperAdmin />
      <StyleList />
    </div>
  )
}

export default StylesPage;
