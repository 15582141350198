import React from "react";
import { useAppSelector } from "../../app/hooks";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import Cemaco from '../../images/gift/Cemaco_carrusel.png';
import Siman from '../../images/gift/Siman-modified.png';

const Gift = () => {


  var settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const fileGiftInformation = useAppSelector((state) => state.wedding)

  const giftStorePhotos = {
    'Cemaco': Cemaco,
    'Siman': Siman
  } 

  return (
    <div className="gift-area">
      <div className="container">
        <div className="col-12">
          <div className="section-title text-center">
            <h2>Lista de Regalos</h2>
            <p>Aqui puedes ver las listas de regalos.</p>
          </div>
        </div>
        <div className="row gift-item">
          <div className="slider-container">
            <Slider {...settings}>
              {(() => {
                const items = [];
                for (let i = 0; i < 4; i++) {
                  fileGiftInformation.giftstores_file_info.forEach((objeto, index) => {
                    items.push(
                      <div className="slider" key={`store_links_${i}_${index}`}>
                        {objeto.linksurl ? (
                          <Link to={objeto.linksurl} target="_blank" rel="noopener noreferrer">
                            <img className="store-logo" src={giftStorePhotos[objeto.name]} alt={objeto.name} />
                          </Link>
                        ) : (
                          <div className="slider">
                            <img className="store-logo" src={giftStorePhotos[objeto.name]} alt={objeto.name} />
                          </div>
                        )}
                      </div>
                    );
                  });
                }
                return items;
              })()}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gift;
