const mobileh_steps = [
    {
        selector: '.h-front',
        content: 'Te mostraremos los diferentes componentes personalizables para crear tu hermosa boda.👫',
    },
    {
        selector: '.front1',
        content: 'El encabezado e introduccion de tu invitacion.'
    },
    {
        selector: '.h-countdown',
        content: 'Aqui podrás ver el tiempo restante hasta que llegue el gran día.⌚😊',
        position: 'bottom'
    },
    {
        selector: '.h-couple-mob',
        content: 'Aqui podras ver informacion de la feliz pareja.👫',
        position: 'bottom'
    },
    {
        selector: '.h-welcome',
        content: 'Título de bienvenida a tu boda, y también un link para el lugar de tu evento.'
    },
    {
        selector: '.h-story',
        content: 'La historia con tu pareja. Por ejemplo, puedes agregar como se conocieron, en donde fue su primera cita, etc.📓'
    },
    {
        selector: '.h-groomsmen-bridesmaid-mob',
        content: 'padrinos y las damas de honor de tu boda.'
    },
    {
        selector: '.h-location-mob',
        content: 'Lugares y dias en los que se realizaran los actos de tu boda. Puedes incluir información sobre la ceremonia, la recepción, etc.'
    },
    {
        selector: '.h-gallery-mob',
        content: 'El album como pareja.',
        position: 'bottom'
    },
    {
        selector: '.mobile-login-bars',
        content: 'Para poder iniciar con tu boda, debes ingresar a tu cuenta o crear una nueva. Por favor dar click aqui para desplegar el menu'
    },
    {
        selector: '.mobile-login-account',
        content: 'Por favor da click en Login y registra tu cuenta.👤'
    },
    {
        selector: '.mobile-create',
        content: 'Por último, después de ingresar a tu cuenta por favor da click en + Crear y así puedes iniciar con la elaboración de tu boda virtual.'
    }
];

export default mobileh_steps;
