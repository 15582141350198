import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updatePeopleVisibility, updateOldWedding } from '../../../features/wedding-state/wedding-state';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';

const successAlert = () => {
  let mensaje = 'Cambios guardados correctamente.'
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = () => {
  let mensaje = 'No se han podido guardar los cambios.'
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const GroomsmenBridesmaid = () => {
  const savePeople = async () => {
    const updateObject = await docdbMethods.getDifferenceObj(filePeopleInformation, filePeopleInformation.previousdoc);
    docdbMethods.updateDocument(filePeopleInformation.wedding, updateObject).then(res => {
      if (res && res.status === "error") errorAlert();
      dispatch(updateOldWedding(updateObject))
      successAlert()
    });
  }

  const dispatch = useAppDispatch();
  const filePeopleInformation = useAppSelector((state) => state.wedding)

  const [cloudVisible, setCloudVisible] = useState(true);

  const saveCheckBoxChange = async (updateObj) => {
    docdbMethods.updateDocument(filePeopleInformation.wedding, updateObj).then(res => {
      if (res && res.status === "error") errorAlert();
      dispatch(updateOldWedding(updateObj))
      successAlert()
    });
  }

  const handleCheckboxYesChange = (event) => {
    setCloudVisible(true);

    if (event.target.checked) {
      dispatch(updatePeopleVisibility(true));
    } else {
      dispatch(updatePeopleVisibility(false));
    }
    saveCheckBoxChange({ people_visible: event.target.checked })
  };

  const handleCheckboxNoChange = (event) => {
    setCloudVisible(false);

    if (event.target.checked) {
      dispatch(updatePeopleVisibility(false));
    } else {
      dispatch(updatePeopleVisibility(true));
    }
    saveCheckBoxChange({ people_visible: !event.target.checked })
  };

  return (
    <div>
      <form>
        <div>
          <div>
            <p className='text-center'>¿Deseas utilizar esta <br></br> sección en tu boda?</p>
            <div className='text-center'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filePeopleInformation.people_visible}
                    onChange={handleCheckboxYesChange}
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    inputProps={{ 'aria-label': 'Sí Checkbox' }}
                  />
                }
                label="Sí"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!filePeopleInformation.people_visible}
                    onChange={handleCheckboxNoChange}
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    inputProps={{ 'aria-label': 'No Checkbox' }}
                  />
                }
                label="No"
              />
            </div>
          </div>
          {cloudVisible &&
            <div className='text-center'>
              <Tooltip
                title="Guardar cambios"
              >
                <CloudUploadTwoToneIcon
                  onClick={() => savePeople()}
                  style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
                />
              </Tooltip>
            </div>}
        </div>
      </form>
    </div>
  )
}

export default GroomsmenBridesmaid
