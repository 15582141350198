import React from 'react';
import CountDown from 'react-countdown';
import cupsImg from '../../../images/invitegt/1.png';

const Saveday = () => {
  const formatNumber = (number) => String(number).padStart(2, '0');
  return (
    <div className="count-down-area h-countdown">
      <div className="count-down-sectionparallax">
        <div className="container">
          <div className="count-down-item count-down-item2">
            <div className="row">
              <div 
              className="col-12 col-md-4 section-area" 
              id="count-down-img"
              style={{ backgroundImage: `url(${cupsImg})` }}
              >
                <div className="section-sub">
                </div>
                <h2 className="big"><span>Estamos a la espera.....</span> El gran día</h2>
              </div>
              <div className="col-12 col-md-8 clock-area">
                <div className="count-down-clock">
                  <CountDown
                    date = {new Date('2024-12-31')}
                    renderer={
                      ({ days, hours, minutes, seconds }) => {
                        const totalHours = (days * 24) + hours;
                        return (
                          <div>
                            <ul className="react-countdown">
                              <li><p className="digit">{totalHours}</p><p className="text">hour</p></li>
                              <li><p className="digit">{formatNumber(minutes)}</p><p className="text">min</p></li>
                              <li><p className="digit">{formatNumber(seconds)}</p><p className="text">Sec</p></li>
                            </ul>
                          </div>
                        )
                      }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Saveday;