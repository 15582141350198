const EventSample = ({ url, name, date, place, address, link, index, hour }) => {
  return (
    <div
      key={`${name}_${index}`}
      id={`${name}_${index}`}
      className="col-lg-4 col-md-6 col-sm-12"
    >
      <div className="event-inner">
        <div className="event-img">
          <img className="img-fluid" src={
            url
          } alt="" />
        </div>
        <h2>{
          `${(name)}`
        }</h2>
        <p>{`${place} - ${date} - ${(hour)? hour+" horas": ''}`}</p>
        <p>{`${address}`}</p>
        <a 
        href={`${link}`} 
        rel="noreferrer" 
        target="_blank"
        style={{
          pointerEvents: link ? 'auto' : 'none',
          textDecoration: link ? 'underline' : 'none'
        }}
        >
          Ver Ubicación 
        </a>
      </div>
    </div>
  )
}

export default EventSample;