import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CounterStepState { value: number }

const initialState: CounterStepState = { value: 1 }

const counterStepSlice = createSlice({
  name: 'counterStep',
  initialState,
  reducers: {
    incremented(state){
      state.value++;
    },
    decremented(state){
      state.value--;
    },
    setAmount(state, action: PayloadAction<number>) {
      state.value = action.payload;
    }
  }
})

export const { incremented, decremented, setAmount } = counterStepSlice.actions
export default counterStepSlice.reducer;
