import React from 'react';
import Chip from '@mui/material/Chip';

function InvitationSummary({ guests }) {
  let totalConfirmed = 0;
  let totalNotConfirmed = 0;
  let totalRejected = 0;

  guests.forEach(guest => {
    guest.guestNames?.forEach(guestName => {
      if (guest.confirmed) {
        if (guestName.confirmed) {
          totalConfirmed += 1;
        } else {
          totalRejected += 1;
        }
      } else {
        if (guestName.confirmed) {
          totalConfirmed += 1;
        } else {
          totalNotConfirmed += 1;
        }
      }
    });
  });

  return (
    <div>
      <Chip label={`Confirmed: ${totalConfirmed}`}  />
      <Chip label={`Rejected: ${totalRejected}`} variant="outlined" />
      <Chip label={`Not Confirmed: ${totalNotConfirmed}`} variant="outlined" />
    </div>
  );
}

export default InvitationSummary;
