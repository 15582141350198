import React from 'react';
import Sectiontitle from '../../section-title';
import './style.css';
import ceremonyImg from '../../../images/invitegt/6.png';
import receptionImg from '../../../images/invitegt/7.png';
import giftsImg from '../../../images/invitegt/3.png';


const Location = () => {
  return (
    <div id="event" className="service-area section-padding h-location">
      <div className="container">
        <Sectiontitle section={'Eventos'} />

        <div className="service-area-menu h-location-mob">
          <div className="Ceremony-wrap">
            <div className="row">
              <div className="col-lg-5">
                <div>
                  <img
                    src={ceremonyImg}
                    className={'ceromony-img'}
                    alt="Ceremony img"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className={'ceromony-content'}>
                  <h3>Ceremonia de Bodas</h3>
                  <span>
                    {"Nombre del lugar de la ceremonia"}
                  </span>
                  <span>
                    {"Sunday, 25 July 2025"}
                    {", 9.00 AM"}
                  </span>
                  <span>
                    {"Antigua Guatemala, Guatemala"}
                  </span>
                  <a
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      pointerEvents: 'none',
                      textDecoration: 'none'
                    }}
                  >
                    Ver localidad
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Ceremony-wrap">
          <div className="row">
            <div className="col-lg-7">
              <div className={'ceromony-content ceromony-content2'}>
                <h3>Fiesta de Bodas</h3>
                <span>
                  {"Salon de la recepción"}
                </span>
                <span>
                  {"Sunday, 25 July"}
                  {", 12.00 AM"}
                </span>
                <span>
                  {"Salon de eventos, Antigua Guatemala, Guatemala"}
                </span>
                <a
                  href={"/"}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    pointerEvents: 'none',
                    textDecoration: 'none'
                  }}
                >
                  See Location
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div>
                <img
                  src={receptionImg}
                  className={'ceromony-img'}
                  alt="Reception img"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Ceremony-wrap">
          <div className="row">
            <div className="col-lg-5">
              <div>
                <img
                  src={giftsImg}
                  className={'ceromony-img'}
                  alt="Gifts img"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className={'ceromony-content'}>
                <h3>Regalos de Boda</h3>
                <span>
                  {"Salón de regalos!"}
                </span>
                <a
                  href={"/"}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    pointerEvents: 'none',
                    textDecoration: 'none'
                  }}
                >
                  Ver Localidad
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default Location;