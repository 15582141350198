import React, { useState } from 'react';
import '../rsvp/style.css'
import { Link } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton';
import Preview from '@mui/icons-material/Visibility';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import s3request from '../../features/apis/s3api';
import docdbMethods from '../../features/apis/docdb';
import alertMethods from '../../features/alerts/alerts';
import { updateCompleteFileName, updateLoadPreview } from '../../features/wedding-state/wedding-state';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PdfAlertLink from '../PdfAlertLink';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';


const CompleteForm = () => {
  const [openComplete, setOpenComplete] = useState(false);
  const weddingInformation = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();
  const url = new URL(window.location.href);
  const baseUrl = `${url.protocol}//${url.host}`;
  const boyName = weddingInformation.boy_name.replace(/\s+/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const girlName = weddingInformation.girl_name.replace(/\s+/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const link = boyName + "_" + girlName;
  const finalLink = baseUrl +"/id/"
    + weddingInformation.wedding + "/"
    + (link).toLowerCase();
  const galleryLink = baseUrl + "/memory/" + weddingInformation.wedding
  const navigate = useNavigate();

  const successAlert = () => {
    let mensaje = 'Objeto generado correctamente.'
    alertMethods.generalAlert('', 'success', mensaje, 3000, true)
  }

  const errorAlert = () => {
    let mensaje = 'Fallo al generar archivo prueba nuevamente.'
    alertMethods.generalAlert('', 'Failure', mensaje, 3000, true)
  }

  // Función para abrir la vista previa
  const openPreview = () => {
    if (weddingInformation.template === 0 || weddingInformation.template === "0") {
      navigate("/home");
    }
    else if (weddingInformation.template === 1 || weddingInformation.template === "1") {
      navigate("/home6");
    }
    else if (weddingInformation.template === 2 || weddingInformation.template === "2") {
      navigate("/home5");
    }
    else if (weddingInformation.template === 3 || weddingInformation.template === "3") {
      navigate("/home3");
    }
  };

  const handleCloseComplete = () => {
    setOpenComplete(false);
  };

  const handleOpenComplete = () => {
    setOpenComplete(true);
  };

  const generateFinalFile = async () => {
    try {
      dispatch(updateLoadPreview(true))
      let filename = `wedding/${weddingInformation.wedding}/${weddingInformation.wedding}.json`;
      const copyWeddingInformation = { ...weddingInformation };
      delete copyWeddingInformation.guests;
      delete copyWeddingInformation.previousdoc;
      let content_type = "application/json";
      let confirmation = await s3request.uploadImage(
        filename,
        content_type,
        copyWeddingInformation
      )
      if (weddingInformation.completeFileName !== filename) {
        await docdbMethods.updateDocument(weddingInformation.wedding, {
          "completeFileName": filename
        })
        dispatch(updateCompleteFileName(filename))
      }
      if (confirmation === filename) {
        successAlert();
      }
    } catch (e) {
      errorAlert();
    } finally {
      dispatch(updateLoadPreview(false))
    }
  }
  const qrValue = finalLink;

  const copyToClipboard = (id) => {
    const qrElement = document.getElementById(id);

    qrElement.toBlob((blob) => {
      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard.write([item]);
    });
  }

  return (
    <div id="completeForm" className="rsvp-area go-rsvp-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-2 col-md-10 offset-md-1">
            <div className="rsvp-wrap">
              <ul className='shop-arrow-container'>
                <li><Link to="/Admin"><i className="fa fa-arrow-left" title='Regresar'></i></Link></li>
              </ul>
              <div className="col-12">
                <div className="section-title section-title4 text-center">
                  <h2>Ultimos pasos</h2>
                  <p>Valida tu información</p>
                </div>
              </div>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {
                    (weddingInformation.completeFileName !== "")
                      ?
                      <Grid item xs={12}>
                        <div className='codigo-qr'>
                          <p>QR de tu boda:</p>
                          <QRCode
                            value={qrValue}
                            id={'qr-full-link'}
                            size={128}
                            level="H"
                            width={128}
                            height={128}
                          />
                          <Button
                            onClick={() => copyToClipboard('qr-full-link')}
                            variant="contained"
                          >
                            Copiar QR de boda
                          </Button>
                        </div>
                        <div className='codigo-qr'>
                          <p>QR de tu galeria:</p>
                          <QRCode
                            value={galleryLink}
                            id={'qr-memory-link'}
                            size={128}
                            level="H"
                            width={128}
                            height={128}
                          />
                          <Button
                            onClick={() => copyToClipboard('qr-memory-link')}
                            variant="contained"
                          >
                            Copiar QR de boda
                          </Button>
                        </div>
                        <Alert severity="success" className='complete-link-boda'>Link de boda!:
                          <Link to={finalLink} target="_blank">{finalLink}</Link>
                          <IconButton
                            aria-label="copy"
                            onClick={() => navigator.clipboard.writeText(finalLink)}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Alert>
                        <Alert severity="success" className='complete-link-boda'>Link de galería!:
                          <Link to={galleryLink} target="_blank">{galleryLink}</Link>
                          <IconButton
                            aria-label="copy"
                            onClick={() => navigator.clipboard.writeText(galleryLink)}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Alert>
                        <PdfAlertLink
                          finalLink={finalLink}
                          weddingInformation={weddingInformation}
                          styleNumber={1}
                        />
                        <PdfAlertLink
                          finalLink={finalLink}
                          weddingInformation={weddingInformation}
                          styleNumber={2}
                        />
                      </Grid>
                      : ""
                  }
                  <Grid item xs={6}>
                    <LoadingButton
                      className='complete-preview'
                      onClick={openPreview}
                      variant='contained'
                      loading={(weddingInformation) ? weddingInformation.loadingPreview : false}
                      endIcon={<Preview />}
                      loadingPosition="end">
                      Preview
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6}>
                    {
                      (weddingInformation.idTransaction && weddingInformation.idTransaction !== "")
                        ?
                        <LoadingButton
                          onClick=
                          {
                            (weddingInformation.completeFileName.includes(weddingInformation.wedding))
                              ? handleOpenComplete : generateFinalFile
                          }
                          variant='contained'
                          loading={(weddingInformation) ? weddingInformation.loadingPreview : false}
                          endIcon={<ArrowForwardIcon />}
                          loadingPosition="end">
                          Generate wedding
                        </LoadingButton>
                        : ""
                    }
                  </Grid>
                </Grid>
              </Box>
              <Dialog
                open={openComplete}
                onClose={handleCloseComplete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Advertencia de reemplazo"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Ya existe cargada una boda con este nombre deseas sobreescribirla?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseComplete}>Ignorar</Button>
                  <Button onClick={generateFinalFile} autoFocus>
                    Confirmar
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompleteForm;
