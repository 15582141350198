import * as React from 'react';
import { useState, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import DoorbellTwoToneIcon from '@mui/icons-material/DoorbellTwoTone';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import CardGiftcardTwoToneIcon from '@mui/icons-material/CardGiftcardTwoTone';
import PhotoAlbumTwoToneIcon from '@mui/icons-material/PhotoAlbumTwoTone';
import PortraitTwoToneIcon from '@mui/icons-material/PortraitTwoTone';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import LibraryMusicTwoToneIcon from '@mui/icons-material/LibraryMusicTwoTone';
import Collapse from '@mui/material/Collapse';
import StyleIcon from '@mui/icons-material/Style';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { useAppSelector } from '../../app/hooks';
import Groups2Icon from '@mui/icons-material/Groups2';

const Item = ({ description, index, method, section }) => {
  const [expanded, setExpanded] = useState(false);
  const counterValue = useAppSelector((state) => (state.counterStep.value));

  const handleToggleExpand = () => {
    setExpanded(!expanded);
    method(index);
  };

  useEffect(() => {
    if (index !== counterValue && expanded === true) {
      setExpanded(false);
    }
  }, [index, counterValue, expanded]);

  function getIcon(index) {
    if (index === 1) {
      return <PermContactCalendarIcon />;
    } else if (index === 2) {
      return <DashboardIcon />;
    } else if (index === 14) {
      return <HourglassFullTwoToneIcon />;
    } else if (index === 4) {
      return <PeopleOutlineIcon />
    } else if (index === 5) {
      return <CardMembershipIcon />
    } else if (index === 6) {
      return <ManageHistoryOutlinedIcon />
    } else if (index === 15) {
      return <DoorbellTwoToneIcon />
    } else if (index === 16) {
      return <CelebrationTwoToneIcon />
    } else if (index === 17) {
      return <CardGiftcardTwoToneIcon />
    } else if (index === 9) {
      return <PhotoAlbumTwoToneIcon />
    } else if (index === 7) {
      return <PortraitTwoToneIcon />
    } else if (index === 13) {
      return <ListAltTwoToneIcon />
    } else if (index === 18) {
      return <LibraryMusicTwoToneIcon />;
    } else if (index === 11) {
      return <AddBusinessIcon />
    } else if (index === 19) {
      return <StyleIcon />
    } else if (index === 20) {
      return <Groups2Icon />
    }
  }

  const reduxInformation = useAppSelector((state) => (state.wedding));

  return (
    <div>
      <ListItemButton key={'item_' + index} onClick={handleToggleExpand}>
        <ListItemIcon id='index'>
          {getIcon(index)}
        </ListItemIcon>
        <ListItemText primary={description} />
        {
          (index === 13) ?
            <ListItemIcon id='index'>
              <Badge badgeContent={reduxInformation.guests.filter(elemento => elemento["confirmed"] === true).length} color="primary">
                <MailIcon color="action" />
              </Badge>
            </ListItemIcon> : ''
        }
      </ListItemButton>
      <Collapse in={expanded}>
        <div style={{ marginLeft: '16px' }}>
          {expanded && section}
        </div>
      </Collapse>
    </div>
  );
}

export default Item;