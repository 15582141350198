import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { useAppSelector } from '../../app/hooks';
import { useLocation } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Button from '@mui/material/Button';
import './index.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const YTComponent = ({ url, autoplay, onPreview }) => {
  const location = useLocation();
  const songInformation = useAppSelector((state) => state.wedding.music_yt_id);
  const currentPath = location.pathname;

  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
    if (playerRef.current) {
      playing ? playerRef.current.pause() : playerRef.current.play();
    }
  }

  return (
    <div>
      {
        ((songInformation && songInformation !== ""
          &&
          (currentPath.startsWith("/home") || onPreview)
        ) || currentPath.startsWith("/Admin")
        || currentPath.startsWith("/ejemplo")
        )
          ?
          <ReactPlayer
            style={{ display: 'none' }}
            url={url}
            width="100%"
            height="50px"
            controls={true}
            light={!autoplay}
            playing={playing}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 1,
                  fs: 0,
                  iv_load_policy: 3,
                  cc_load_policy: 0,
                  color: 'white',
                  modestbranding: 1,
                },
              },
            }}
          /> : ''
      }
      <div className="col-l2">
        <div className={`
        ${(currentPath.startsWith("/home")||currentPath.startsWith("/ejemplo"))
            ? 'section-title'
            : ''
          } text-center`}>          
          <h2>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Stack
                style={{
                  width: '300px',
                  height: '23px',
                }}>
                <Chip label="Escuchemos la canción de la pareja" variant="outlined" />
              </Stack>
            </div>
            <Button
              className="pulseButton"
              onClick={handlePlayPause}
              variant='contained'
              size='large'
              style={{
                borderRadius: '50%',
                width: '45px',
                height: '45px',
                padding: '10px',
                minWidth: '0',
              }}
            >
              {
                (playing) ?
                  <PauseIcon /> :
                  <PlayArrowIcon />
              }
            </Button>
          </h2>
        </div>
      </div>
    </div>
  )
}

export default YTComponent;
