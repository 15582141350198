import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  userName: string;
  userProfileImage?: string;
  userBlogName?: string;
  userBlogDescription?: string;
  userBlogFacebook?: string;
  userBlogInstagram?: string;
  weddingArray: number[];
  weddingError: boolean;
  billingInformation: {};
  transactionInformation: object[];
  showLogin: boolean;
  showHomeTour: boolean;
}

const initialState: UserState = {
  userName: '',
  weddingArray: [],
  weddingError: false,
  billingInformation: {},
  transactionInformation: [],
  showLogin: false,
  showHomeTour: true,
}

const userStateSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStoreUserProfile(state, action: PayloadAction<UserState>) {
      state.userName = action.payload.userName;
      state.weddingArray = action.payload.weddingArray;
      state.billingInformation = action.payload.billingInformation 
      ? action.payload.billingInformation : {};
      state.transactionInformation = action.payload.transactionInformation
      ? action.payload.transactionInformation : [];
    },
    setPushWedding(state, action: PayloadAction<number>) {
      state.weddingArray.push(action.payload);
    },
    setErrorPage(state, action: PayloadAction<boolean>){
      state.weddingError = action.payload
    },
    setProfileBillingInformation(state, action: PayloadAction<{}>){
      state.billingInformation = action.payload
    },
    setNewTransaction(state, action: PayloadAction<{}>){
      state.transactionInformation.push(action.payload);
    },
    setIsLoginActive(state, action: PayloadAction<boolean>){
      state.showLogin = action.payload;
    },
    setShowHomeTour(state, action: PayloadAction<boolean>){
      state.showHomeTour = action.payload;
    },
    setProfilePicture(state, action: PayloadAction<string>){
      state.userProfileImage = action.payload;
    },
    setProfileBlogName(state, action: PayloadAction<string>){
      state.userBlogName = action.payload;
    },
    setProfileBlogDescription(state, action: PayloadAction<string>){
      state.userBlogDescription = action.payload;
    },
    setProfileBlogFacebook(state, action: PayloadAction<string>){
      state.userBlogFacebook = action.payload;
    },
    setProfileBlogInstagram(state, action: PayloadAction<string>){
      state.userBlogInstagram = action.payload;
    },
  },
});

export const {
  setStoreUserProfile,
  setPushWedding, 
  setErrorPage,
  setProfileBillingInformation,
  setNewTransaction,
  setIsLoginActive,
  setShowHomeTour,
  setProfilePicture,
  setProfileBlogName,
  setProfileBlogDescription,
  setProfileBlogFacebook,
  setProfileBlogInstagram,
} = userStateSlice.actions;
export default userStateSlice.reducer;
