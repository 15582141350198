import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import sectionsImages from '../../features/loadobjects';
import { updateLoadPreview, loadingComponent } from '../../features/wedding-state/wedding-state';
import Alert from '@mui/material/Alert';
import './style.css'
import beige_short from '../../images/BEIGE.png';
import WeddingList from '../WeddingList';

const Header = ({ onPreview }) => {
  const weddingObj = useAppSelector((state) => state.wedding)
  const userObj = useAppSelector((state) => state.user);
  const { route } = useAuthenticator(context => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useAppDispatch();

  const HandleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    signOut();
    handleClose();
  }

  const loadAllSections = async () => {
    try {
      dispatch(loadingComponent(true));
      dispatch(updateLoadPreview(true));
      await sectionsImages.getAllSections(weddingObj, dispatch);
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(updateLoadPreview(false));
      dispatch(loadingComponent(false));
    }
  }

  const getReturnPath = () => {
    if (weddingObj.template === 1 || weddingObj.template === "1") {
      return '/home6';
    } else if (weddingObj.template === 2 || weddingObj.template === "2") {
      return '/home5';
    } else if (weddingObj.template === 3 || weddingObj.template === "3") {
      return '/home3';
    } else{
      return '/home';
    }
  }

  return (
    <div className="Header_root">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-10">
              <div className="logo">
                <h2>
                  <Link to={getReturnPath()}>
                    {(weddingObj.boy_name) ? weddingObj.boy_name : 'Harry'}
                    <span><i className="fa fa-heart" aria-hidden="true"></i></span>
                    {(weddingObj.girl_name) ? weddingObj.girl_name : 'Nancy'}
                  </Link>
                </h2>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="header-menu d-lg-block d-none">
                <ul className="mobail-menu d-flex">
                <img src={beige_short} alt='logo' style={{ 
                  maxHeight: '90px', 
                  maxWidth: '90px', 
                  position: 'absolute',
                  left: '200px',
                  }}/>
                  {
                    (onPreview || currentPath === '/Admin') ? ('') :
                      <li><Link to='#'>Estilos</Link>
                        <ul className="submenu">
                          <li><Link to='/home'>Clasico</Link></li>
                          <li><Link to='/home6'>Fancy</Link></li>
                          <li><Link to='/home3'>Festivo</Link></li>
                          <li><Link to='/home5'>Minimalista</Link></li>                          
                          <li><Link to='/styles'>Ver Mas Estilos</Link></li>
                        </ul>
                      </li>
                  }
                  {
                    (
                      !onPreview
                      && !userObj.weddingError
                      && currentPath !== '/Admin'
                    )
                      ? (<li><Link className='create-wedding' to='/Admin'>Crear</Link></li>)
                      : ('')
                  }
                  {
                    (
                      !onPreview
                      && !userObj.weddingError
                      && currentPath === '/Admin'
                    )
                      ? (
                        <li>
                          <WeddingList/>
                        </li>
                      )
                      : ('')
                  }
                  {
                    (onPreview) ? ('') :
                      (route !== 'authenticated') ?
                        <li>
                          <Link to='/login'>
                            <Button variant="contained" className="login-create">
                              Login
                            </Button>
                          </Link>
                        </li>
                        :
                        <li>
                          <Button
                            onClick={HandleProfileClick}
                            variant="contained"
                          >
                            <AccountCircleIcon />
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={handleClose}>{(user?.username) ? user.attributes.email : 'Profile'}</MenuItem>
                            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                          </Menu>
                        </li>
                  }
                  {
                    (
                      route === 'authenticated'
                      && currentPath === '/Admin'
                      && weddingObj.wedding !== 0
                      && weddingObj.idTransaction === ''
                      && !onPreview
                    )
                      ? (
                        <li>
                          <Link to={"/shopping"}>
                            <Button title='Shopping' color='success' variant='contained' onClick={async () => { await loadAllSections() }}>
                              <ShoppingCartRoundedIcon />
                            </Button>
                          </Link>
                        </li>
                      ) : ('')
                  }
                  {
                    (
                      route === 'authenticated' 
                      && currentPath === '/Admin' 
                      && weddingObj.wedding !== 0
                      && !onPreview
                      )
                      ? (
                        <li>
                          <Link to='/complete'>
                            <Button title='Complete' color='success' onClick={async () => { await loadAllSections() }} variant='contained'>
                              <VisibilityIcon />
                            </Button>
                          </Link>
                        </li>)
                      : ('')
                  }
                </ul>
              </div>
            </div>
            <div className="col-2">
              <MobileMenu
                onPreview={onPreview} />
            </div>
          </div>
          {
            (userObj.weddingError) ? <Alert severity="error">Ha ocurrido un error porfavor intenta mas tarde estamos trabajando en ello</Alert> : ('')
          }
        </div>
      </div>
    </div>
  )
}

export default Header;
