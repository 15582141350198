import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link,
} from '@react-pdf/renderer';
import srcGreatVibesFont from '../../../fonts/GreatVibes-Regular.ttf';
import srcImagePng from '../../../images/invitation-plain7.png';

// Register font
Font.register(
  { family: 'GreatVibes', src: srcGreatVibesFont }
);

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 0,
    padding: 0,
    flexGrow: 1,
    textAlign: 'center',
    fontFamily: 'GreatVibes',
  },
  image: {
    position: 'absolute', // Posición absoluta para la imagen de fondo
    top: 0,
    left: 0,
    width: '100%', // Ajusta el tamaño al 100% del contenedor
    height: '100%',
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
    fontFamily: 'GreatVibes',
    paddingTop: 250,
    color: '#000000',
  },
  honor_text: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    color: '#A56D51',
  },
  names_text: {
    fontSize: 50,
    textAlign: 'center',
    fontFamily: 'GreatVibes',
    color: '#000000',
  },
  container: {
    border: 1,
    borderColor: '#FFFFFF',
    paddingTop: 40,
    paddingBottom: 40,
  },
  container_text: {
    color: '#000000',
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },
});

// Create Document Component
const MyPdfStyle2 = ({ finalLink, weddingInformation, weddingDateFormated }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image
          style={styles.image}
          src={srcImagePng}
        />
        <Link src={finalLink}>
          <Text style={styles.title}>...Nuestro Si para siempre...</Text>
          <Text style={styles.honor_text}>Tenemos el honor de compartir con ustedes</Text>
          <Text style={styles.honor_text}>Nuestra Boda</Text>
          <Text style={styles.names_text}>{weddingInformation.boy_name} & {weddingInformation.girl_name}</Text>
          <Text style={styles.honor_text}>{weddingDateFormated}</Text>
          <Text style={styles.honor_text}>{weddingInformation.ceremony_adress}</Text>
          <View style={styles.container}>
            <Text style={styles.container_text}>
              Haz click aquí
            </Text>
            <Text style={styles.container_text}>
              Para abrir la invitación
            </Text>
            <Text style={styles.container_text}>
              InviteGt
            </Text>
          </View>
        </Link>
      </View>
    </Page>
  </Document>
)

export default MyPdfStyle2;
