import React from 'react'
import strory2 from '../../images/events/2.jpg'
import '../location/style.css'
import { useAppSelector } from '../../app/hooks'


const WeddingParty = () => {

  const weddingObj = useAppSelector((state) => state.wedding);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1])-1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  function formatearHora(hora) {
    const partes = hora.split(":");
    const horas = partes[0];
    const minutos = partes[1].length === 1 ? "0" + partes[1] : partes[1];
    return horas + ":" + minutos;
  }

  return (
    <div className="Ceremony-wrap">
      <div className="row">
        <div className="col-lg-7">
          <div className={'ceromony-content ceromony-content2'}>
            <h3>Fiesta de Bodas</h3>
            <span>
              {(weddingObj.reception_name) ? weddingObj.reception_name : "Nombre del lugar de la recepción"}
            </span>
            <span>
              {(weddingObj.wedding_date) ? formatDate(weddingObj.wedding_date) : "Sunday, 25 July"}
              {(weddingObj.reception_hour) ? `, ${formatearHora(weddingObj.reception_hour)}` : ", 9.00 AM"}
            </span>
            <span>
              {(weddingObj.reception_adress) ? weddingObj.reception_adress : "256 Apay Road,Califonia Bong, London"}
            </span>
            {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p>
                        <Link to="/">See Location</Link> */}
            <a 
            href={weddingObj.reception_link} 
            target="_blank" 
            rel="noreferrer"
            style={{
              pointerEvents: weddingObj.reception_link ? 'auto' : 'none',
              textDecoration: weddingObj.reception_link ? 'underline' : 'none'
            }}
            >
              See Location
            </a>
          </div>
        </div>
        <div className="col-lg-5">
          <div>
            <img
              src={(weddingObj.reception_file[0].url)
                ? weddingObj.reception_file[0].url
                : strory2}
              className={'ceromony-img'}
              alt="Reception img"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeddingParty
