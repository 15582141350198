import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  setPayCVV,
  setPayName,
  setPayCard,
  setPayExpiration,
} from '../../features/pay-state/pay-state';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

export default function PaymentForm() {

  const onChange = (e) => {
    switch (e.target.id) {
      case 'cardName':
        dispatch(setPayName(e.target.value));
        break;
      case 'cardNumber':
        dispatch(setPayCard(e.target.value));
        break;
      case 'expDate':
        let valor = e.target.value.replace(/\D/g, '');
        if (valor.length > 2 && valor.charAt(2) !== '/')
          e.target.value = valor.substring(0, 2) + '/' + valor.substring(2);
        dispatch(setPayExpiration(e.target.value));
        break;
      case 'cvv':
        dispatch(setPayCVV(e.target.value));
        break;
      default:
        throw new Error('Unknown step');
    }
  }

  const dispatch = useAppDispatch();
  const payInformation = useAppSelector((state) => (state.pay));

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="Name on card"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            onChange={onChange}
            value={payInformation.nameOnCard}
            inputProps={{
              maxLength: 50,
              pattern: "^[A-Za-z\\s]+$",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Card number"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
            onChange={onChange}
            value={payInformation.ccNumber}
            inputProps={{
              maxLength: 16
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
            onChange={onChange}
            value={payInformation.ccExpiration}
            inputProps={{
              maxLength: 5,
              pattern: "^(0[1-9]|1[0-2])/\\d{2}$",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
            onChange={onChange}
            value={payInformation.ccCVV}
            inputProps={{
              maxLength: 3,
              pattern: "[0-9]*",
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}