import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setStoreWedding,
  updateToggleDrawer,
  restartReduxStateToDefault
} from '../../features/wedding-state/wedding-state';
import docdbrequest from '../../features/apis/docdb'
import alertMethods from '../../features/alerts/alerts.js'
import { setAmount } from '../../features/step_counter/step-slice';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom'
import { Collapse, CardBody, Card } from 'reactstrap';
import EventIcon from '@mui/icons-material/Event';

const successAlertsearch = () => {
  alertMethods.generalAlert('', 'success', 'Bienvenido a tu evento.', 3000, false)
}

const errorAlert = () => {
  let mensaje = 'Lo sentimos, su evento no esta registrado. Intenta de nuevo por favor.'
  alertMethods.generalAlert('Ooops', 'error', mensaje, 4000, false)
}


const WeddingList = ({ closeMobileMethod }) => {
  const isMobiled = useMediaQuery('(max-width: 991px)')
  const dispatch = useAppDispatch();

  const searchWeddingById = (weddingNumber) => {
    dispatch(restartReduxStateToDefault()) // Limpiar state si creo nuevo evento
    docdbrequest.searchDocument(weddingNumber)
      .then(async res => {
        if (res === null || res === '') {
          errorAlert()
        } else {
          res.wedding = weddingNumber
          dispatch(setStoreWedding(res));
          successAlertsearch();
          dispatch(setAmount(19));
          setTimeout(() => {
            dispatch(updateToggleDrawer(true));
          }, 2000);
        }
      });
  }

  const weddingObjectList = useAppSelector((state) => (state.user.weddingArray));
  const weddingObject = useAppSelector((state) => (state.wedding));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const HandleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isOpen, setOpen] = useState(false);

  const setIsOpen = () => {
    setOpen(!isOpen);
  }

  return (
    <div id="EventSelect">
      {
        (isMobiled)
          ?
          <div>
            <li><Link onClick={setIsOpen}>
              <EventIcon /> Tus Eventos<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
            </li>
            <Collapse isOpen={isOpen}>
              <Card>
                <CardBody>
                  {
                    (weddingObjectList) ?
                      weddingObjectList.map((wedding) => {
                        return (
                          <li key={wedding.weddingId}><Link onClick={
                            () => {
                              searchWeddingById(wedding.weddingId);
                              handleClose();
                              if (closeMobileMethod) closeMobileMethod();
                            }
                          }>{wedding.name}</Link></li>
                        )
                      }) : ""
                  }
                </CardBody>
              </Card>
            </Collapse>
          </div>
          :
          <div>
            <Button
              onClick={HandleProfileClick}
              variant="contained"
            >
              {
                weddingObject.wedding !== 0
                  ? (weddingObject.boy_name.split(' ')[0]) + '&' + (weddingObject.girl_name.split(' ')[0])
                  : 'Evento'
              }
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {
                (weddingObjectList) ?
                  weddingObjectList.map((wedding) => {
                    return (
                      <MenuItem
                        key={wedding.weddingId}
                        value={wedding.weddingId}
                        name={wedding.name}
                        onClick={() => { searchWeddingById(wedding.weddingId); handleClose() }}
                      >
                        {wedding.name}
                      </MenuItem>
                    )
                  }) : ""
              }
            </Menu>
          </div>
      }
    </div>
  );
}

export default WeddingList;
