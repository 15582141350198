import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../story/style.css'
import story1 from '../../images/story/1.jpg';

const StoryComponent = ({ storyObject, index }) => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(false);
  const [expandedp, setExpandedp] = useState(false);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  const toggleExpansion = () => {
    setExpandedp(!expandedp);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="item h-story">
      <div className="story-icon">
        <span className="flaticon-birds-in-love"></span>
      </div>
      <div className="story-content">
        <div className="image-wrap">
          <div className={`${location.pathname === '/complete' ? 'complete-image-wrap' : 'single-image-wrap'}`}>
            <img src={(storyObject.url)
              ? storyObject.url
              : story1
            } alt="" className="single-image" />
          </div>
        </div>
        <h2>{(storyObject.tittle)
          ? storyObject.tittle
          : "Nuestra Primera Cita"}</h2>
        <span className="date">{(storyObject.date)
          ? formatDate(storyObject.date)
          : "Diciembre 25, 2017"}</span>

        {
          (location.pathname === '/' || location.pathname.startsWith('/home'))
            ? <p className={`paragraph ${expandedp ? "expandedp" : ""}`}>
              <span className='pspan'>{
                (storyObject.speech)
                  ? storyObject.speech
                  :
                  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some,"
              }</span></p>
            : <textarea
              name='story1'
              className={`textarea ${expanded ? "expanded" : ""}`}
              rows={expanded ? "8" : "2"}
              readOnly
              value={(storyObject.speech)
                ? storyObject.speech
                : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some,"} />
        }
        <div className="story-button">
          {
            (location.pathname === '/' || location.pathname.startsWith('/home'))
              ? <button onClick={toggleExpansion} className="readmore-btn">{expandedp ? "Leer menos" : "Leer más"}</button>
              : <button onClick={toggleExpand} className="readmore-btn">{expanded ? "Leer menos" : "Leer más"}</button>
          }
        </div>
      </div>
    </div>
  )
}

export default StoryComponent;