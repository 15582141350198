import React, { useEffect, useState } from 'react';
import './style.css'
import { useParams } from 'react-router-dom';
import s3request from '../../features/apis/s3api'
import { useLocation, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setStoreWedding,
  updateLoadPreview,
  updateShowFinalAssistance,
} from '../../features/wedding-state/wedding-state';
import { setGuestInfo } from '../../features/guest_state/guest_state';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import docdbMethods from '../../features/apis/docdb';

const Assistance = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorClosedInvitation, setIsErrorClosedInvitation] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const { id } = useParams();
  const location = useLocation();
  const vertical = 'top';
  const horizontal = 'center';
  const dispatch = useAppDispatch();
  const weddingObject = useAppSelector((state) => (state.wedding));
  const navigate = useNavigate();

  useEffect(() => {
    if (id && id !== '' && parseInt(id) > 0 && weddingObject.wedding !== id) {
      let filename = `wedding/${id}/${id}.json`
      setIsLoading(true);
      s3request.getImage(filename, "application/json").then(res => {
        if (res && res.status === "error") {
          setIsOpen(true)
          setTimeout(() =>
            window.location.href = window.location.origin, 4000);
          return;
        }
        let data = JSON.parse(res);
        if (data && data.wedding === id) {
          dispatch(setStoreWedding(data));
          dispatch(updateLoadPreview(true));
        }
      }).catch(e => console.log(e)
      ).finally(() => setIsLoading(false));
    }
  }, [id, location, dispatch, weddingObject]);


  const changeHandler = (e) => {
    const phoneNumber = e.target.value.replace(/\D/g, '');
    const limitedPhoneNumber = phoneNumber.slice(0, 10);
    e.target.value = limitedPhoneNumber
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    let contentPhone = document.getElementById("phone").value;
    if (contentPhone === '') {
      setErrorForm(true)
      return
    }

    var current_guest = await docdbMethods.getGuests(weddingObject.wedding, contentPhone);
    console.log("current_guest", current_guest)
    current_guest = (current_guest && current_guest.phone) ? current_guest : undefined;
    if (current_guest) {
      dispatch(setGuestInfo({ key: 'name', value: current_guest.name }));
      setIsErrorClosedInvitation(false);
    }
    if (!weddingObject.open_wedding && !current_guest) {
      setIsErrorClosedInvitation(true);
      return
    }
    dispatch(updateShowFinalAssistance(true));
    dispatch(setGuestInfo({ key: 'phone', value: contentPhone }));
    if (
      current_guest
      && current_guest.notes
      && current_guest.notes !== ''
    ) {
      dispatch(setGuestInfo({ key: 'notes', value: current_guest.notes }));
    }
    if(current_guest && current_guest.confirmed) {
      dispatch(setGuestInfo({ key: 'confirmed', value: current_guest.confirmed }));
    } else dispatch(setGuestInfo({ key: 'confirmed', value: false }));
    if(current_guest && current_guest.rsvp) {
      dispatch(setGuestInfo({ key: 'rsvp', value: current_guest.rsvp }));
    }
    if (current_guest
      && current_guest.guestNames
      && current_guest.guestNames.length > 0
      && current_guest.guestNames[0].name
      && current_guest.guestNames[0].name !== '') {
      dispatch(setGuestInfo(
        { key: 'guestNames', value: current_guest.guestNames }
      ));
    }
    if (weddingObject.template === 0 || weddingObject.template === "0") {
      navigate("/home")
      window.scrollTo(0, 0);
    }
    else if (weddingObject.template === 1 || weddingObject.template === "1") {
      navigate("/home6")
      window.scrollTo(0, 0);
    }
    else if (weddingObject.template === 2 || weddingObject.template === "2") {
      navigate("/home5")
      window.scrollTo(0, 0);
    }
    else if (weddingObject.template === 3 || weddingObject.template === "3") {
      navigate("/home3")
      window.scrollTo(0, 0);
    }
  }

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  return (
    <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div className="rsvp-wrap">
              <div className="col-12">
                <div className="section-title section-title4 text-center">
                  <h2>Se nuestro invitado</h2>
                  <p>Por favor reserva antes de {' '}
                    {
                      (weddingObject.wedding_confirmation_date !== '') ?
                        formatDate(weddingObject.wedding_confirmation_date) :
                        (weddingObject.wedding_date) ?
                          formatDate(weddingObject.wedding_date) :
                          formatDate('2026-10-10')
                    } .
                  </p>
                  <p>
                    {
                      (!weddingObject.kids) ?
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="info">Respetuosamente no niños</Alert>
                        </Stack>
                        : ''
                    }
                  </p>
                  <p>
                    {
                      (isErrorClosedInvitation) ?
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="error">Numero de telefono no valido</Alert>
                        </Stack>
                        : ''
                    }
                  </p>
                </div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="contact-form form-style">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        placeholder="Tu numero de telefono*"
                        onChange={changeHandler}
                        id="phone"
                        name="phone"
                      />
                      <p>{errorForm ? 'Ingresa un numero de telefono' : ''}</p>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <LoadingButton
                      type="submit"
                      variant='contained'
                      loading={isLoading}>
                      Mostrar Invitacion
                    </LoadingButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isOpen}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert severity="error">No se ha encontrado el evento!</Alert>
      </Snackbar>
    </div>
  )

}

export default Assistance;
