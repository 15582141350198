import { EventCeremony, EventParty, EventGift } from './EventSections';
import { useAppSelector } from '../../../../../app/hooks';

const Events = () => {
  let locationInformation = useAppSelector((state) => state.wedding);
  return (
    <div id="events" className="events-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box">
              <h2>Eventos</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <EventCeremony />
          {
            (locationInformation.wedding_party_visible)
              ? <EventParty /> : null
          }
          {
            (locationInformation.wedding_gift_visible)
              ? <EventGift /> : null
          }
        </div>
      </div>
    </div>
  )
}

export default Events;