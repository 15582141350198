import React from 'react';

const SampleTemplateStyle5 = ({ component, message }) => {
  return (
    <div id="events" className="events-box">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-box">
              <h2>{message}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {component}
        </div>
      </div>
    </div>
  )
}

export default SampleTemplateStyle5
