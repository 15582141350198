import React from 'react'
import strory3 from '../../images/events/3.jpg'
import '../location/style.css'
import { useAppSelector } from '../../app/hooks'

const WeddingGifts = () => {

  const weddingObj = useAppSelector((state) => state.wedding);  

  return (
    <div className="Ceremony-wrap">
      <div className="row">
        <div className="col-lg-5">
          <div>
            <img
              src={(weddingObj.gift_file[0].url)
                ? weddingObj.gift_file[0].url
                : strory3}
              className={'ceromony-img'}
              alt="Gifts img"
            />
          </div>
        </div>
        <div className="col-lg-7">
          <div className={'ceromony-content'}>
            <h3>Regalos de Boda</h3>
            <span>
              {(weddingObj.gift_name) ? weddingObj.gift_name : "Nombre del lugar de regalos!"}
            </span>
            {/* <span>256 Apay Road,Califonia Bong, London</span> */}
            {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p>
                    <Link to="/">See Location</Link> */}
            <a 
            href={weddingObj.gift_link} 
            target="_blank" 
            rel="noreferrer"
            style={{
              pointerEvents: weddingObj.gift_link ? 'auto' : 'none',
              textDecoration: weddingObj.gift_link ? 'underline' : 'none'
            }}
            >
              See Location
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeddingGifts
