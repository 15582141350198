import Navbar from '../../components/Navbar';
import GalleryPage from '../../components/GalleryPage';

const MemoryPage = () => {
  return (
    <div>
      <Navbar onPreview={true} />
      <GalleryPage />
    </div>
  )
}

export default MemoryPage;
