import React from 'react'
import { useAppDispatch } from '../../../app/hooks';
import alertMethods from '../../../features/alerts/alerts';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import docdbMethods from '../../../features/apis/docdb';
import {updateOldWedding} from '../../../features/wedding-state/wedding-state';

const successAlert = (mensaje) => {
    alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
    alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const OptionalSection = ({ visibleVariable, reduxMethod, reduxVariableName, weddingId }) => {
    const dispatch = useAppDispatch();

    const handleCheckboxYesChange = (event) => {
        if (event.target.checked) {
            dispatch(reduxMethod(true));
        } else {
            dispatch(reduxMethod(false));
        }
        saveCheckBoxChange({ [reduxVariableName]: event.target.checked })
    };

    const handleCheckboxNoChange = (event) => {
        if (event.target.checked) {
            dispatch(reduxMethod(false));
        } else {
            dispatch(reduxMethod(true));
        }
        saveCheckBoxChange({ [reduxVariableName]: !event.target.checked })
    };

    const saveCheckBoxChange = async (updateObj) => {
        docdbMethods.updateDocument(weddingId, updateObj).then(res => {
            if (res && res.status === "error") errorAlert();
            dispatch(updateOldWedding(updateObj))
            successAlert("Cambios salvados correctamente.")
        });
    }

    return (
        <div>
            <p className='text-center'>¿Deseas utilizar esta <br></br> sección en tu boda?</p>
            <div className='text-center'>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={visibleVariable}
                            onChange={handleCheckboxYesChange}
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite />}
                            inputProps={{ 'aria-label': 'Sí Checkbox' }}
                        />
                    }
                    label="Sí"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!visibleVariable}
                            onChange={handleCheckboxNoChange}
                            icon={<FavoriteBorder />}
                            checkedIcon={<Favorite />}
                            inputProps={{ 'aria-label': 'No Checkbox' }}
                        />
                    }
                    label="No"
                />
            </div>
        </div>
    )
}

export default OptionalSection
