import React, { useEffect, useState } from 'react';
import './style.css'
import { useParams } from 'react-router-dom';
import s3request from '../../features/apis/s3api'
import docdbMethods from '../../features/apis/docdb';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setStoreWedding
  , updateAlbumImage
  , loadingComponent
} from '../../features/wedding-state/wedding-state';
import Gallery from '../gallery';
import ImageCaptureButton from '../ImageCaptureButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const GalleryPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const weddingObject = useAppSelector((state) => (state.wedding));
  const [isActiveCameraButton, setIsActiveCameraButton] = useState(false);

  useEffect(() => {
    if (id && id !== '' && parseInt(id) > 0 && weddingObject.wedding !== id) {
      let filename = `wedding/${id}/${id}.json`;
      s3request.getImage(filename, "application/json").then(res => {
        if (res && res.status === "error") {
          console.log("gallery30", res);
          setTimeout(() =>
            window.location.href = window.location.origin, 4000);
          return;
        }
        let data = JSON.parse(res);
        if (data && data.wedding === id) {
          dispatch(setStoreWedding(data));
          docdbMethods.getGallery(id).then(res => {
            if (res && res.status === "error") {
              setTimeout(() =>
                window.location.href = window.location.origin, 4000);
              return;
            }
            if (res && res.length > 0) {
              dispatch(updateAlbumImage(res));
              (res.length > 90)
                ? setIsActiveCameraButton(false)
                : setIsActiveCameraButton(true);
            }
          }).catch(e => console.log(e));
        }
      }).catch(e => console.log(e));
    }
  }, [id, location, dispatch, weddingObject]);

  const handleClose = () => {
    dispatch(loadingComponent(false))
  }

  const setLoadingStatus = (status) => {
    dispatch(loadingComponent(status))
  }

  return (
    <div id="gallery-page" className="rsvp-area go-rsvp-area section-padding">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={weddingObject.loadingComponent}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container">
        <Gallery />
      </div>
      <ImageCaptureButton
        weddingId={id}
        enabled={isActiveCameraButton}
        loadingStatusMethod={setLoadingStatus}
      />
    </div>
  )
}

export default GalleryPage;
