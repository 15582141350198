import EventSample from '../EventSample';
import { useAppSelector } from '../../../../../../app/hooks';
import eventImageSample from '../../FamilyComponent/1.jpg';

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const formatDate = (dateString) => {
  const arrDates = dateString.split('-');
  return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
}

const EventCeremony = () => {
  const weddingInformation = useAppSelector((state) => (state.wedding));
  return (
    <EventSample
      url={
        (weddingInformation.ceremony_file[0].url && weddingInformation.ceremony_file[0].url !== "")
          ? weddingInformation.ceremony_file[0].url
          : eventImageSample
      }
      name={"Ceremonia de Boda"}
      place={weddingInformation.ceremony_name}
      date={(weddingInformation.wedding_date) ? formatDate(weddingInformation.wedding_date) : "25 Julio 2025"}
      address={weddingInformation.ceremony_adress}
      link={weddingInformation.ceremony_link}
      index={0}
      hour={weddingInformation.ceremony_hour}
    />
  );
};

const EventParty = () => {
  const weddingInformation = useAppSelector((state) => (state.wedding));
  return (
    <EventSample
      url={
        (weddingInformation.reception_file[0].url && weddingInformation.reception_file[0].url !== "")
          ? weddingInformation.reception_file[0].url
          : eventImageSample
      }
      name={"Fiesta de Boda"}
      place={weddingInformation.reception_name}
      date={(weddingInformation.wedding_date) ? formatDate(weddingInformation.wedding_date) : "25 Julio 2025"}
      address={weddingInformation.reception_adress}
      link={weddingInformation.reception_link}
      index={0}
      hour={weddingInformation.reception_hour}
    />
  );
};

const EventGift = () => {
  const weddingInformation = useAppSelector((state) => (state.wedding));
  return (
    <EventSample
      url={
        (weddingInformation.gift_file[0].url && weddingInformation.gift_file[0].url !== "")
          ? weddingInformation.gift_file[0].url
          : eventImageSample
      }
      name={"Regalos de Boda"}
      place={weddingInformation.gift_name}
      date={(weddingInformation.wedding_date) ? formatDate(weddingInformation.wedding_date) : "25 Julio 2025"}
      address={weddingInformation.reception_adress}
      link={weddingInformation.gift_link}
      index={0}
    />
  );
}

export { EventCeremony, EventParty, EventGift };
