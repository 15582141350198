import * as React from 'react';
import AdminSidebarBasicInformation from '../AdminSidebarComponents/BasicInformation';
import AdminSidebarBasicFront from '../AdminSidebarComponents/Front';
import CountDownuploadSideBar from '../AdminSidebarComponents/CountDownUpload';
import AdminSidebarCouple from '../AdminSidebarComponents/Couple';
import AdminSidebarWelcome from '../AdminSidebarComponents/Welcome';
import AdminSidebarTimeline from '../AdminSidebarComponents/Timeline';
import AdminSidebarCeremony from '../AdminSidebarComponents/Ceremony';
import AdminSidebarReception from '../AdminSidebarComponents/Reception';
import AdminSidebarGifts from '../AdminSidebarComponents/Gift';
import AdminSidebarAlbum from '../AdminSidebarComponents/Album';
import GroomsmenBridesmaid from '../AdminSidebarComponents/Groomsmen_Bridesmaid';
import GiftStores from '../AdminSidebarComponents/GiftStores';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAmount } from '../../features/step_counter/step-slice';
import sectionsImages from '../../features/loadobjects';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { loadingComponent } from '../../features/wedding-state/wedding-state';
import Item from './item';
import Collaborator from '../AdminSidebarComponents/Collaborator';

const MainListItems = () => {

  const arrElementBar = [
    {
      description: "Información Básica",
      id: 1,
      section: <AdminSidebarBasicInformation />
    },
    {
      description: "Estilos",
      id: 19,
      section: null
    },    
    {
      description: "Portada",
      id: 2,
      section: <AdminSidebarBasicFront />
    },
    {
      description: "Contador",
      id: 14,
      section: <CountDownuploadSideBar />
    },
    {
      description: "Pareja",
      id: 4,
      section: <AdminSidebarCouple />
    },
    {
      description: "Invitación",
      id: 5,
      section: <AdminSidebarWelcome />
    },
    {
      description: "Linea de Tiempo",
      id: 6,
      section: <AdminSidebarTimeline />
    },
    {
      description: "Ceremonia",
      id: 15,
      section: <AdminSidebarCeremony />
    },
    {
      description: "Recepción",
      id: 16,
      section: <AdminSidebarReception />
    },
    {
      description: "Regalos",
      id: 17,
      section: <AdminSidebarGifts />
    },
    {
      description: "Album",
      id: 9,
      section: <AdminSidebarAlbum />
    },
    {
      description: "Personas Especiales",
      id: 7,
      section: <GroomsmenBridesmaid />
    },
    {
      description: "Tiendas de Regalos",
      id: 11,
      section: <GiftStores />
    },
    {
      description: "Invitados",
      id: 13,
      section: null
    },
    {
      description: "Musica",
      id: 18,
      section: null,
    },
    {
      description: "Colaboradores",
      id: 20,
      section: <Collaborator />
    }
  ]
  const dispatch = useAppDispatch();
  const fileInformation = useAppSelector((state) => (state.wedding));

  const GetSectionURL = async (amount) => {
    dispatch(loadingComponent(true))
    dispatch(setAmount(amount));
    sectionsImages.getSectionByURL(fileInformation, amount, dispatch)
  }

  const handleClose = () => {
    dispatch(loadingComponent(false))
  }

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fileInformation.loadingComponent}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        arrElementBar.map((element, index) => (
          <Item
            key={'item_' + element.id}
            description={element.description}
            index={element.id}
            method={() => GetSectionURL(element.id)}
            section={element.section}
          />
        ))
      }
    </React.Fragment>
  )
}

export default MainListItems;
