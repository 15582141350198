import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateOldWedding, updateCollaborators } from '../../../features/wedding-state/wedding-state';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';
import CollaboratorItem from './CollaboratorItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const successAlert = () => {
  let mensaje = 'Cambios guardados correctamente.'
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = () => {
  let mensaje = 'Todos los campos son obligatorios.'
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const Collaborator = () => {

  const saveCollaborators = async () => {
    for (let i = 0; i < collaboratorSection.collaborators.length; i++) {
      if (
        collaboratorSection.collaborators[i].name === "" ||
        collaboratorSection.collaborators[i].description === "") {
        errorAlert()
        return
      }
    }
    const updateObj = await docdbMethods.getDifferenceObj(collaboratorSection, collaboratorSection.previousdoc)
    docdbMethods.updateDocument(collaboratorSection.wedding, updateObj).then(res => {
      dispatch(updateOldWedding(updateObj))
      successAlert()
    })
  }

  const addNewEventToStory = () => {
    var copyArray = [...collaboratorSection.collaborators];
    copyArray.push(
      {
        filename: "",
        url: "",
        name: "",
        description: "",
      }
    )
    dispatch(updateCollaborators(copyArray));
  }

  const removeNewEventToStory = () => {
    var copyArray = [...collaboratorSection.collaborators];
    copyArray.pop()
    dispatch(updateCollaborators(copyArray));
  }

  const collaboratorSection = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  return (
    <div>
      {collaboratorSection.collaborators.map((component, index) => (
        <CollaboratorItem
          key={index}
          index={index}
          component={component}
        />
      ))
      }
      <div className="text-center">
        <Tooltip
          title="Agregar evento"
        >
          <AddCircleOutlineIcon
            onClick={() => addNewEventToStory()}
            style={{
              cursor: 'pointer',
              color: '#649E93',
              fontSize: '45px',
              pointerEvents: collaboratorSection.collaborators.length >= 4 ? 'none' : 'auto'
            }}
          />
        </Tooltip>
        <Tooltip
          title="Remover evento"
        >
          <RemoveCircleOutlineIcon
            onClick={() => removeNewEventToStory()}
            style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
          />
        </Tooltip>
      </div>

      <div className="text-center">
        <Tooltip
          title="Guardar cambios"
        >
          <CloudUploadTwoToneIcon
            onClick={() => saveCollaborators()}
            style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
          />
        </Tooltip>
      </div>
    </div >
  )
}

export default Collaborator
