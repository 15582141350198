import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  updateCeremonyAdress,
  updateCeremonyHour,
  updateCeremonyLink,
  updateCeremonyName,
  updateCeremonyImage,
  updateOldWedding,
  loadingComponent
} from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api'
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';
import ComponentModal from '../../ComponentModal';
import ReactCropComponent from '../../ReactCropEditor';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const successAlert = (mensaje) => {
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const AdminSidebarCeremony = () => {

  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [srcFile, setSrcFile] = useState(null);
  const [cropFile, setCropFile] = useState(null);

  const submitHandler = async () => {
    try {
      dispatch(loadingComponent(true));
      if (document.getElementById("ceremony-fileupload").files.length === 1) {
        await uploadImageWedding(
          ceremonySection.ceremony_file[0].filename,
          cropFile
        )
      }
      const updateObj = await docdbMethods.getDifferenceObj(ceremonySection, ceremonySection.previousdoc)
      docdbMethods.updateDocument(ceremonySection.wedding, updateObj).then(res => {
        dispatch(updateOldWedding(updateObj))
        successAlert("Cambios salvados correctamente.")
      })
    } catch (error) {
      errorAlert('Error al salvar los cambios.')
    }
    finally {
      dispatch(loadingComponent(false));
    }
  }

  const uploadImageWedding = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const ceremonySection = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  const updateCeremony = (e) => {
    if (e.id === "ceremony_name") {
      dispatch(updateCeremonyName(e.value))
    } else if (e.id === "ceremony_adress") {
      dispatch(updateCeremonyAdress(e.value))
    } else if (e.id === "ceremony_link") {
      dispatch(updateCeremonyLink(e.value))
    }
  }

  const maxSizeInBytes = 10 * 1024 * 1024; // 15 MB

  const updateFile = async (e) => {
    var file = e.target.files[0]
    if (file && file.size > maxSizeInBytes) {
      errorAlert('El archivo es demasiado grande. El tamaño máximo permitido es 15 MB.');
      e.target.value = ''; // Limpia el input
      return;
    } else if (e.target.files && e.target.files.length > 0) {
      setSrcFile(e.target.files[0]);
      setPreviewVisible(true);
    }
  }

  const onResponseCropFile = async (cropFile, index = -1) => {
    var obj_file = await s3request.buildCropObjImage(srcFile.name, cropFile, ceremonySection.wedding);
    var arr_ceremony = [obj_file];
    dispatch(updateCeremonyImage(arr_ceremony));
    setPreviewVisible(false);
    setCropFile(cropFile);
  };

  const closePreview = () => {
    setPreviewVisible(false);
  };

  const [selectedTime, setSelectedTime] = useState(
    dayjs(ceremonySection.ceremony_hour 
      ? ceremonySection.ceremony_hour: '10:00', 'HH:mm'));

  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
    dispatch(updateCeremonyHour(newValue.$H + ":" + newValue.$m))
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div style={{ display: 'flex' }}>
          <input
            style={{ width: '185px' }}
            type="text" disabled
            className="form-control" placeholder="Select image of place"
            value={(ceremonySection.ceremony_file[0].filename)
              ? ceremonySection.ceremony_file[0].filename.split('\\').pop().split('/').pop()
              : "" || ''}
          />
          <label htmlFor="ceremony-fileupload" className="custom-file-upload">
            <i className="fa fa-upload"></i>
          </label>
          <input id="ceremony-fileupload" type="file" accept="image/*" className="form-control"
            onChange={(e) => updateFile(e)}
          />
          <ComponentModal
            component={
              <ReactCropComponent
                file={srcFile}
                resolveFunction={onResponseCropFile}
                index={0}
              />
            }
            defaultState={isPreviewVisible}
            handleFunction={closePreview}
          ></ComponentModal>
        </div>
        <div>
          <input
            style={{ width: '185px' }}
            id="ceremony_name" type="text" className="form-control" placeholder="Name of place"
            value={(ceremonySection.ceremony_name) ? ceremonySection.ceremony_name : ""}
            onChange={(e) => updateCeremony(e.target)}
          />
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
              <DemoItem label="Hora del evento">
                <TimePicker id="ceremony_hour" style={{ width: '185px' }}
                  value={selectedTime}
                  onAccept={handleTimeChange}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div>
          <input
            style={{ width: '185px' }}
            id="ceremony_adress" type="text" className="form-control" placeholder="Adress of place"
            value={(ceremonySection.ceremony_adress) ? ceremonySection.ceremony_adress : ""}
            onChange={(e) => updateCeremony(e.target)}
          />
        </div>
        <div>
          <input
            style={{ width: '185px' }}
            id="ceremony_link" type="text" className="form-control" placeholder="Link of ubication"
            value={(ceremonySection.ceremony_link) ? ceremonySection.ceremony_link : ""}
            onChange={(e) => updateCeremony(e.target)}
          />
        </div>
        <br></br>
        <div>
          <div className="text-center">
            <Tooltip
              title="Guardar cambios"
            >
              <CloudUploadTwoToneIcon
                onClick={() => submitHandler()}
                style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
              />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AdminSidebarCeremony
