import React from 'react';
import SampleTemplate from '../../components/AdminSidebarComponents/SampleTemplate';
import ProfileSample from '../../components/ProfileSample';
import Navbar from '../../components/Navbar';
import StepperAdmin from '../../components/Stepper';

const ProfilePage = () => {
  return (
    <div>
      <Navbar />
      <StepperAdmin />
      <div className="blog-pg-section">
        <div>
          <div className="row">
            <div className="col col-12">
              <SampleTemplate component={<ProfileSample />} message="Perfil" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
