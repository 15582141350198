import React from 'react';
import './style.css'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  minWidth: '40%',
  backgroundColor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: '60%',
  padding: '0px',
};

const ComponentModal = ({ component, defaultState, handleFunction }) => {
  return (
    <div 
    id="Component-modal"
    style={{ backgroundColor: 'white' }}
    >
      <Modal
        open={defaultState}
        onClose={handleFunction}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="PersonalModal" className="personal-form-area go-personal-form-area section-padding">
            <div className="container" style={{ backgroundColor: 'white' }}>
              {component}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ComponentModal;
