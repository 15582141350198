import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom'
import Sectiontitle from '../../section-title';
import './style.css';
import caballero1 from '../../../images/invitegt/ia/1.png';
import caballero2 from '../../../images/invitegt/ia/2.png';
import caballero3 from '../../../images/invitegt/ia/3.png';
import caballero4 from '../../../images/invitegt/ia/4.png';
import caballero5 from '../../../images/invitegt/ia/5.png';
import caballero6 from '../../../images/invitegt/ia/6.png';
import dama1 from '../../../images/invitegt/ia/7.png';
import dama2 from '../../../images/invitegt/ia/8.png';
import dama3 from '../../../images/invitegt/ia/9.png';
import dama4 from '../../../images/invitegt/ia/10.png';
import dama5 from '../../../images/invitegt/ia/11.png';
import dama6 from '../../../images/invitegt/ia/12.png';


const People = () => {

  const arrPeopleGroomsman = [
    {
      imageSource: caballero1,
      name: "Mr Abraham",
      relationship: "Padre"
    },
    {
      imageSource: caballero2,
      name: "Rohan Mithy",
      relationship: "Hermano"
    },
    {
      imageSource: caballero3,
      name: "Matias Brown",
      relationship: "Amigo"
    },
    {
      imageSource: caballero4,
      name: "Mr Brown",
      relationship: "Tio"
    },
    {
      imageSource: caballero5,
      name: "Rohan Mark",
      relationship: "Primo"
    },
    {
      imageSource: caballero6,
      name: "Lux Mithy",
      relationship: "Hermano"
    },
  ]

  const arrPeopleBridesmaids = [
    {
      imageSource: dama1,
      name: "Lydia",
      relationship: "Mejor Amiga"
    },
    {
      imageSource: dama2,
      name: "Mary",
      relationship: "Prima"
    },
    {
      imageSource: dama3,
      name: "Lily",
      relationship: "Hermana"
    },
    {
      imageSource: dama4,
      name: "Ana",
      relationship: "Amiga"
    },
    {
      imageSource: dama5,
      name: "July",
      relationship: "Hermana"
    },
    {
      imageSource: dama6,
      name: "Lily",
      relationship: "Hermana"
    },
  ]

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div id="people" className="person-area section-padding pb-70">
      <div className="container">
        <Sectiontitle section={"Caballeros y Damas de Honor"} />
        <div className="person-area-menu">
          <div className="person-btn h-groomsmen-bridesmaid-mob">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  Caballeros
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  Damas
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="Groomsman-wrap h-groomsmen-bridesmaid">
                <div className="row">
                  {arrPeopleGroomsman.map((objeto, index) => (
                    <div className="col-lg-4 col-md-6" key={`peopleboys-${index}`}>
                      <div className="person-wrap">
                        <div className="person-img">
                          <img src={objeto.imageSource}
                            alt="arraygrooms" />
                          <div className="social-list">
                            <ul>
                              <li><Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                              <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
                            </ul>
                          </div>
                        </div>
                        <div className="person-content">
                          <h3>{objeto.name}</h3>
                          <span>{objeto.relationship}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="Ceremony-wrap">
                <div className="row">
                  {arrPeopleBridesmaids.map((objeto, index) => (
                    <div className="col-lg-4 col-md-6" key={`peoplegirls-${index}`}>
                      <div className="person-wrap">
                        <div className="person-img">
                          <img src={objeto.imageSource}
                            alt="arraybrides" />
                          <div className="social-list">
                            <ul>
                              <li><Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                              <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                            </ul>
                          </div>
                        </div>
                        <div className="person-content">
                          <h3>{objeto.name}</h3>
                          <span>{objeto.relationship}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>)
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>

  )
}

export default People;
