import React from 'react';

const SampleTemplate = ({component, message}) => {
  return (
    <div>
      <div name="SideBarCountDown"  className="guestlist-form-area">
        <div className="container">
          <div 
          className="section-title section-title4 text-center personal-form-wrap"
          style={{
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
          >
            <h2>{message}</h2>
            {component}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SampleTemplate
