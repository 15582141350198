import '../../css/style.css';
import { useAppSelector } from '../../../../../app/hooks';
import SampleImage from '../FamilyComponent/1.jpg';

const Couple = () => {
  let weddingObj = useAppSelector((state) => state.wedding);
  return (
    <div id="about" className="about-box">
      <div className="about-a1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-box">
                <h2>
                  {
                    (weddingObj.boy_name)
                      ? weddingObj.boy_name + " "
                      : 'Harry '
                  }
                  <span>&</span>
                  {
                    (weddingObj.girl_name)
                      ? " " + weddingObj.girl_name
                      : ' Nancy'
                  }
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row align-items-center about-main-info">
                <div className="col-lg-8 col-md-6 col-sm-12">
                  <h2> Acerca de
                    <span>
                      {
                        (weddingObj.girl_name)
                          ? " " + weddingObj.girl_name +" "+ weddingObj.girl_last
                          : ' Nancy'
                      }
                    </span>
                  </h2>
                  <p>
                    {
                      (weddingObj.couple_girl_phrase)
                        ? weddingObj.couple_girl_phrase
                        : 'Hi I am Nancy Elizabeth. I am going to introduce myself.I am a professional graphic designer professional graphic designer going to introduce myself.I am going to introduce myself.'
                    }
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="about-img">
                    <img
                      className="img-fluid rounded"
                      src={
                        (weddingObj.couple_boy_file[0].url && weddingObj.couple_boy_file[0].url !== "")
                          ? weddingObj.couple_boy_file[0].url
                          : SampleImage
                      }
                      alt="" />
                  </div>
                </div>
              </div>
              <div className="row align-items-center about-main-info">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="about-img">
                    <img
                      className="img-fluid rounded"
                      src={
                        (weddingObj.couple_girl_file[0].url && weddingObj.couple_girl_file[0].url !== "")
                          ? weddingObj.couple_girl_file[0].url
                          : SampleImage
                      } alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-12">
                  <h2> Acerca de
                    <span>
                      {
                        (weddingObj.boy_name)
                          ? " "+ weddingObj.boy_name +" "+ weddingObj.boy_last
                          : ' Harry'
                      }
                    </span>
                  </h2>
                  <p>
                    {
                      (weddingObj.couple_boy_phrase)
                        ? weddingObj.couple_boy_phrase
                        : 'Hi I am Harry William. I am going to introduce myself.I am a professional graphic designer professional graphic designer going to introduce myself.I am going to introduce myself.'
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Couple;