import Swal from 'sweetalert2';

const generalAlert = (title, icon, message, timer,showConfirmButton) => {
  Swal.fire({
    title: title,
    position: 'center',
    text: message,
    icon: icon,
    showConfirmButton: showConfirmButton,
    timer: timer
  });
}

const alertMethods = {generalAlert}

export default alertMethods
