import React from 'react'
import AdminPage from '../AdminPage'
import { TourProvider } from '@reactour/tour'
import DrawerFunctions from '../../components/Dashboard/DrawerFunctions'

const AdminTour = () => {

  const { toggleDrawer } = DrawerFunctions();
  const radius = 10;

  const admin_steps = [
    {
      selector: '.create-new-event',
      content: 'Primero vamos a crear tu evento, y así puedes empezar a llenar toda la información en la barra de secciones.',
    },
    {
      selector: '.tour-sidebar',
      content: 'Esta es la barra de secciones, en ella podrás personalizar cada parte de tu invitación de boda. ',
      action: toggleDrawer
    },
    {
      selector: '.fa-heart',
      content: 'Cuando lo desees puedes revisar tus cambios (solo debes hacer clic en el corazón ❤️).',
    },
    {
      selector: '.create-search',
      content: 'Para enviar las invitaciones, puedes hacer clic en el botón COMPLETE 👁️ (visible en el menú en cuanto se crea o busca una boda en tu cuenta).',
    },
  ];

  return (
    <TourProvider steps={admin_steps}
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#3F7267',
          borderRadius: radius,
        }),
        maskArea: (base) => ({ ...base, rx: radius, }),
        maskWrapper: (base) => ({ ...base, }),
        badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
        controls: (base) => ({ ...base, marginTop: 100 }),
        close: (base) => ({ ...base, right: 'auto', left: 8, top: 8 }),
      }}
      badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}
    >
      <AdminPage />
    </TourProvider>
  )
}

export default AdminTour
