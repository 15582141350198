import React from 'react';
import './style.css';

const Welcome = () => {
  return (
    <div id="welcomeArea" className="welcome-area h-welcome">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="welcome-content">
              <h2>
                {"Bienvenidos a nuestro gran día"}
              </h2>
              <p>
                {"Celebremos juntos el día más importante de nuestras vidas"}
              </p>
              <div className="btn-location">
                {
                  <a
                    target="_blank"
                    href='/'
                    title='Add Event location on the left'
                    style={{
                      pointerEvents: 'none',
                      textDecoration: 'none'
                    }}
                  >
                    Locación del Evento
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Welcome;
