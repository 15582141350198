import React, { useState } from 'react';
import Modal from '@mui/joy/Modal';
import Input from '@mui/joy/Input';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import { useAppDispatch } from '../../../app/hooks';

const ProfileInputModal = ({ openModal, handleClose, dispatchMethod, index=-1, arrObject=[], attrName="", attrValue="" }) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState(attrValue);
  
  const handleSubmit = () => {
    if (index === -1) {
      dispatch(dispatchMethod(inputValue));
    } else {
      const copyArr = [...arrObject];
      const copyObject = {...arrObject[index]};      
      copyObject[attrName] = inputValue;
      copyArr[index] = copyObject;
      dispatch(dispatchMethod(copyArr));
    }
    handleClose();
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
    >
      <Box p={2} style={{ 
        backgroundColor: 'white', 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Input value={inputValue} onChange={handleChange} />
        <Button onClick={handleSubmit}>Guardar</Button>
      </Box>
    </Modal>
  )
};

export default ProfileInputModal;
