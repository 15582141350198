import React, { useState } from 'react'

const CollaboratorItem = ({ index, component }) => {

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (itemId) => {
    setActiveButton(itemId === activeButton ? null : itemId);
  };


  return (
    <div key={'collaborator_section' + index}>
      <div className="div-btn">
        <button onClick={() => handleButtonClick(index)} className="timeline-btns">
          {"colaborador " + (index + 1)} {index === activeButton ? '▲' : '▼'}
        </button>
      </div>
    </div>

  )
}

export default CollaboratorItem;
