import React from 'react'
import { Nav, NavItem, NavLink, } from 'reactstrap';
import './style.css'
import Saveday from '../countdown';

const SidebarCountdown = () => {
  return (
    <div>
      <div name="SideBarCountDown" className="guestlist-form-area">
        <div className="container">
          <div className="person-btn">
            <Nav tabs>
              <NavItem>
                <NavLink>
                  El gran dia esta cerca!
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="section-title section-title4 text-center personal-form-wrap">
            <h2>Tiempo restante hasta el dia de tu evento:</h2>
            <Saveday />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarCountdown
