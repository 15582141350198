import GalleryComponent from '../GalleryComponent';
import imgSample from '../../images/1.jpg';

const GalleryList = ({ weddingInformation }) => {

  return (
    <div className="row row-bottom-padded-md">
      <div className="col-md-12">
        <ul id="fh5co-gallery-list">
          {
            weddingInformation.album_file_info.map((galleryObj, index) => (
              <GalleryComponent
                key={"GalleryObj" + index}
                galleryImg={(galleryObj.url && galleryObj.url !== "") ? galleryObj.url : imgSample}
                index={index}
              />
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default GalleryList;
