import React, { useState } from 'react';
import 'react-fancybox/lib/fancybox.css';
import './style.css';
import SimpleSlider from '../hero';
import PreviewHero from '../hero3';
import StyleComponent from '../StyleComponent';
import Homepage from '../../main-component/HomePage';
import Homepage3 from '../../main-component/HomePage3';
import Homepage5 from '../../main-component/HomePage5';
import Homepage6 from '../../main-component/HomePage6';
import ExampleComponentS5 from '../../main-component/Examples/Style5';
import ExampleComponentS6 from '../../main-component/Examples/Style6';
import ExampleComponentS1 from '../../main-component/Examples/Style1';
import ExampleComponentS2 from '../../main-component/Examples/Style2';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const StyleList = () => {

  const vertical = 'top';
  const horizontal = 'center';

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const galleryList = [
    {
      hcomponent: <SimpleSlider />,
      TemplateComponent: Homepage,
      id: 1,
      name: 'Clasico',
      description: ' Elegantes y atemporales. Están diseñadas con un estilo tradicional, colores neutros y elementos de diseño refinados.',
      ExampleComponent: ExampleComponentS1
    },
    {
      hcomponent: <SimpleSlider />,
      TemplateComponent: Homepage6,
      id: 6,
      name: 'Elegante',
      description: 'Contemporáneo con su distintiva tipografía y suave paleta de colores. Presenta una mezcla armoniosa de fuentes;',
      ExampleComponent: ExampleComponentS6
    },
    {
      hcomponent: <SimpleSlider />,
      TemplateComponent: Homepage5,
      id: 5,
      name: 'Minimalista',
      description: 'Diseñada para aquellos que aprecian la belleza en la simplicidad, esta invitación captura la esencia de lo moderno y lo atemporal.',
      ExampleComponent: ExampleComponentS5
    },
    {
      hcomponent: <PreviewHero />,
      TemplateComponent: Homepage3,
      id: 3,
      name: 'Festivo',
      description: 'Diseñadas para reflejar la alegría y la celebración del gran día, colores vibrantes, ilustraciones alegres y elementos temáticos de festividades.',
      ExampleComponent: ExampleComponentS2
    }
  ]
  return (
    <div id="style" className="Style-section section-padding pb-70 h-style" >
      <div className="container no-padding-mob">
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isOpen}
          onClose={handleClose}
          key={vertical + horizontal}
          autoHideDuration={2000}
          style={{ top: '10%', zIndex: 1500 }}
        >
          <Alert severity="success">Estilo asignado!</Alert>
        </Snackbar>
        <div className="row column-mob">
          {galleryList.map((component, index) => (
            <StyleComponent
              key={'style-list-img' + index}
              hcomponent={component.hcomponent}
              index={index}
              TemplateComponent={component.TemplateComponent}
              name={component.name}
              description={component.description}
              ExampleComponent={component.ExampleComponent}
              methodAlert={() => setIsOpen(true)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default StyleList;
