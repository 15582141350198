import CollaboratorsDialog from "../../Collaborators/Dialog";
import React, { useState } from 'react';
import { Button } from '@mui/material';
import '../../Collaborators/index.css';
import family03 from '../../NewTemplateStyles/Style1/images/family-03.jpg';
import family04 from '../../NewTemplateStyles/Style1/images/family-04.jpg';
import family05 from '../../NewTemplateStyles/Style1/images/family-05.jpg';

const CollaboratorsButton = () => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const collaboratorArr = [
    {
      name: "Pedro Pérez",
      description: "Fotografo profesional con más de 10 años de experiencia en bodas",
      url: family03,
      email: "invitegt.bodas@gmail.com",
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com"
    },
    {
      name: "Mandy Mendoza",
      description: "Wedding planner con más de 5 años de experiencia en bodas",
      url: family04,
      email: "invitegt.bodas@gmail.com",
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com"
    },
    {
      name: "María Mendoza",
      description: "Repostera con más de 10 años de experiencia en bodas",
      url: family05,
      email: "invitegt.bodas@gmail.com",
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com"
    }
  ];

  return (
    <div className="collaborator">
      <Button variant="outlined" onClick={handleOpen}>
        Conoce a nuestros Colaboradores
      </Button>
      <CollaboratorsDialog
        open={open}
        onClose={handleClose}
        collaboratorArr={collaboratorArr}
      />
    </div>
  );
}

export default CollaboratorsButton;
