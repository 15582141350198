import React from 'react'
import './style.css';

const StepperAdmin = () => {
  return (
    <div id="stepperAdmin" className="stepper-area">
      <div className="container">
        <div className="row">
        </div>
      </div>
    </div>
  )
}

export default StepperAdmin;