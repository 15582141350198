import { useAppSelector } from '../../../../../app/hooks';
import coupleImg from '../../images/1.jpg';

const Couple = () => {
  let weddingObj = useAppSelector((state) => state.wedding);
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  return (
    <div id="fh5co-couple">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <h2>
              {
                (weddingObj.boy_name)
                  ? weddingObj.boy_name+' '
                  : 'Harry '
              }
              &
              {
                (weddingObj.girl_name)
                  ? ' '+weddingObj.girl_name
                  : ' Nancy'
              }
            </h2>
            <h3>
              {
                (weddingObj.wedding_date)
                  ? formatDate(weddingObj.wedding_date)
                  : "25 Julio 2025"
              }
            </h3>
            <p>Te invitamos a celebrar con nosotros</p>
          </div>
        </div>
        <div className="couple-wrap animate-box">
          <div className="couple-half">
            <div className="groom">
              <img 
              src={
                (weddingObj.couple_boy_file[0].url && weddingObj.couple_boy_file[0].url!=="")
                ? weddingObj.couple_boy_file[0].url
                : coupleImg
              } 
              alt="groom" 
              className="img-responsive" 
              />
            </div>
            <div className="desc-groom">
              <h3>
                {
                  (weddingObj.boy_name)
                    ? weddingObj.boy_name +' '+ weddingObj.boy_last
                    : 'Harry William'
                }
              </h3>
              <p>
                {
                  (weddingObj.couple_boy_phrase)
                    ? weddingObj.couple_boy_phrase
                    : 'Hola yo soy Harry William. Me voy a presentar. Soy un diseñador gráfico profesional.'
                }
              </p>
            </div>
          </div>
          <p className="heart text-center"><i className="icon-heart2"></i></p>
          <div className="couple-half">
            <div className="bride">
              <img 
              src={
                (weddingObj.couple_girl_file[0].url && weddingObj.couple_girl_file[0].url!=="")
                ? weddingObj.couple_girl_file[0].url
                : coupleImg
              } 
                alt="groom" 
                className="img-responsive" 
                />
            </div>
            <div className="desc-bride">
              <h3>
                {
                  (weddingObj.girl_name)
                    ? weddingObj.girl_name +' '+ weddingObj.girl_last
                    : 'Nancy Elizabeth'
                }
              </h3>
              <p>
                {
                  (weddingObj.couple_girl_phrase)
                    ? weddingObj.couple_girl_phrase
                    : 'Hola yo soy Nancy Elizabeth. Me voy a presentar. Soy un diseñador gráfico profesional.'
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Couple;
