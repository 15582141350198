import React, { useEffect } from 'react';
import Couple from '../../components/couple';
import BackgroundVideo from '../../components/hero4';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import People from '../../components/people';
import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import { useAppSelector } from '../../app/hooks';
import YTComponent from '../../components/ReactPlayer';
import CollaboratorsButton from '../../components/Collaborators';


const Homepage4 = ({ onPreview }) => {
  const homeInfo4 = useAppSelector((state) => (state.wedding));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar onPreview={onPreview} />
      <BackgroundVideo />
      {
        (homeInfo4.music_yt_id && homeInfo4.music_yt_id !== "")
          ?
          <YTComponent
            url={homeInfo4.music_yt_id}
            autoplay={true} onPreview={onPreview}
          />
          : ''
      }
      <Saveday />
      <Couple />
      <Welcome />
      <Story />
      {homeInfo4.people_visible
        ? <People />
        : ''}
      <Location />
      <Gallery />
      <Rsvp />
      {
        (homeInfo4.wedding_giftstore_visible) ?
          <Gift /> : ''
      }
      <CollaboratorsButton />
    </div>
  )
}

export default Homepage4;