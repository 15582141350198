import React from 'react'
import Sectiontitle from '../section-title'
import WeddingCeremony from '../locationCeremony/weddingCeremony'
import WeddingParty from '../locationReception/WeddingParty'
import WeddingGifts from '../locationGifts/WeddingGifts'
import './style.css'
import { useAppSelector } from '../../app/hooks';

const Location = () => {
  let locationInformation = useAppSelector((state) => state.wedding);
  return (
    <div id="event" className="service-area section-padding h-location">
      <div className="container">
        <Sectiontitle section={'Eventos'} />

        <WeddingCeremony />
        {
          (locationInformation.wedding_party_visible)
            ? <WeddingParty /> : null
        }

        {
          (locationInformation.wedding_gift_visible)
            ? <WeddingGifts /> : null
        }
      </div>
    </div>
  )
}
export default Location;