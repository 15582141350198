import Navbar from '../../../components/Navbar';
import SimpleSlider from '../../../components/Examples/hero';
import Footer from '../../../components/footer';
import CountDown from '../../../components/Examples/countdown';
import Couple from '../../../components/Examples/Couple';
import Welcome from '../../../components/Examples/welcome-area';
import Story from '../../../components/Examples/story';
import People from '../../../components/Examples/people';
import Location from '../../../components/Examples/location';
import Gallery from '../../../components/Examples/gallery';
import Rsvp from '../../../components/rsvp';
import Gift from '../../../components/gift';
import { useLocation } from 'react-router-dom';
import YTComponent from '../../../components/ReactPlayer';
import CollaboratorsButton from '../../../components/Examples/Collaborators';

const ExampleStyle1 = () => {
  const location = useLocation();
  const ytid = "https://www.youtube.com/watch?v=kPhpHvnnn0Q"

  return (
    <div>
      <Navbar onPreview={true} />
      <SimpleSlider />
      <YTComponent
        url={ytid}
        autoplay={true} onPreview={true}
      />
      <CountDown />
      <Couple />
      <Welcome />
      <Story />
      <People />
      <Location />
      <Gallery />
      <Rsvp />
      <Gift />
      <CollaboratorsButton />
      {
        (location.pathname.startsWith('/ejemplo')) ? "" : <Footer />
      }
    </div>
  )
}

export default ExampleStyle1;
