import React from 'react'
import { Link } from 'react-router-dom'
import Sectiontitle from '../section-title'
import couple1 from '../../images/couple/img-1.jpg'
import couple2 from '../../images/couple/img-2.jpg'
import couple3 from '../../images/couple/new-image-new-color.png'
import { useAppSelector } from '../../app/hooks';
import './style.css'

const Couple = () => {
  let weddingObj = useAppSelector((state) => state.wedding)
  return (
    <div id="couple" className="about-wrap">
      <div className="couple-area section-padding pb-70">
        <Sectiontitle section={'Feliz Pareja'} />
        <div className="container">
          <div className="couple-wrap h-couple">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12 couple-single">
                <div className="couple-wrap couple-wrap-2">
                  <div className="couple-img h-couple-mob" >
                    <img
                      src={(weddingObj.couple_girl_file[0].url)
                        ? weddingObj.couple_girl_file[0].url
                        : couple1}
                      alt="thumb"
                    />

                  </div>
                  <div className="couple-text">
                    <div className="couple-content">
                      <h3>
                        {
                          (weddingObj.girl_name)
                            ? weddingObj.girl_name + " " + weddingObj.girl_last
                            : 'NANCY ELIZABETH'
                        }
                      </h3>
                      <p>
                        {(weddingObj.couple_girl_phrase)
                          ? weddingObj.couple_girl_phrase
                          : 'Hi I am Nancy Elizabeth. I am going to introduce myself.I am a professional graphic designer professional graphic designer going to introduce myself.I am going to introduce myself.'
                        }
                      </p>
                    </div>
                    <div className="social-icon">
                      <ul className="d-flex">
                        <li><Link to='/'><span className="fa fa-facebook"></span></Link></li>
                        <li><Link to='/'><span className="fa fa-twitter"></span></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 couple-single">
                <div className="couple-shape">
                  <img src={couple3} alt="shape" />
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 couple-single">
                <div className="couple-wrap couple-wrap-3">
                  <div className="couple-img couple-img-2">
                    <img
                      src={(weddingObj.couple_boy_file[0].url)
                        ? weddingObj.couple_boy_file[0].url
                        : couple2}
                      alt="thumb"
                    />
                  </div>
                  <div className="couple-text">
                    <div className="couple-content">
                      <h3>
                        {
                          (weddingObj.boy_name)
                            ? weddingObj.boy_name + " " + weddingObj.boy_last
                            : 'HARRY WILLIAM'
                        }
                      </h3>
                      <p>
                        {(weddingObj.couple_boy_phrase)
                          ? weddingObj.couple_boy_phrase
                          : 'Hi I am Harry William. I am going to introduce myself.I am a professional graphic designer professional graphic designer going to introduce myself.I am going to introduce myself.'
                        }
                      </p>
                    </div>
                    <div className="social-icon">
                      <ul className="d-flex">
                        <li><Link to='/'><span className="fa fa-facebook"></span></Link></li>
                        <li><Link to='/'><span className="fa fa-twitter"></span></Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Couple;
