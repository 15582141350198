import React, { useState } from 'react';
import { Button } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CollectionsIcon from '@mui/icons-material/Collections';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { styled } from '@mui/material/styles';
import s3request from '../../features/apis/s3api';
import docdbMethods from '../../features/apis/docdb';
import alertMethods from '../../features/alerts/alerts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateAlbumImage } from '../../features/wedding-state/wedding-state';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import JSZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';
const originalDomain = 'img.invitegt.com';
const newDomain = 'invitegt.imgix.net';
const additionalParams = '?auto=compress&fm=jpg,compress&q=75';

const Input = styled('input')({
  display: 'none',
});

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center', // Centra horizontalmente
  alignItems: 'center', // Centra verticalmente
});

const successAlert = (mensaje) => {
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const ImageCaptureButton = ({ weddingId, enabled, loadingStatusMethod }) => {

  const handleLabelClick = (e) => {
    if (!enabled) {
      e.preventDefault(); // Previene la acción por defecto si el botón está deshabilitado
    }
  };

  const updateFile = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    if ((e.target.files) && (e.target.files.length > 0)) {
      loadingStatusMethod(true);
      var file = e.target.files[0]
      var filename = String(e.target.files[0].name).replace(/[^A-Z0-9.]/ig, "_");
      var finalname = "wedding/" + weddingId + "/" + filename
      var url = `https://invitegt.imgix.net/${finalname}?auto=compress&fm=jpg,compress&q=75`;
      await uploadImageWedding(finalname, file);
      await docdbMethods.pushToGallery(
        weddingId,
        { "url": url, "filename": finalname }
      ).then(res => {
        if (res.status === "error" || res.error) {
          setErrorMessage(res.error);
          errorAlert('Error al salvar los cambios.')
          return;
        }
        successAlert("Imagen cargada correctamente.");
        const copyArray = [...weddingObject.album_file_info];
        copyArray.push({ "url": url, "filename": finalname });
        dispatch(updateAlbumImage(copyArray));
      }).catch(e => {
        setErrorMessage(e.message);
        errorAlert('Error al salvar los cambios.')
      })
        .finally(() => loadingStatusMethod(false));
    }
  }

  const uploadImageWedding = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const downloadImagesAsZip = async (e) => {
    e.preventDefault();
    const zip = new JSZip();
    const imagePromises = weddingObject.album_file_info.map(async (photo, index) => {
      var newUrl = "";
      if (photo.url.includes(originalDomain)) {
        newUrl = photo.url.replace(originalDomain, newDomain);
        newUrl = newUrl + additionalParams;
      } else {
        newUrl = photo.url;
      }
      const response = await axios.get(newUrl, { responseType: 'blob' });
      const fileName = `image${index + 1}.jpg`;
      zip.file(fileName, response.data);
    });
    await Promise.all(imagePromises);
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'galeria.zip');
  };

  const weddingObject = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      {
        errorMessage &&
        <Stack sx={{ width: 'fit-content', margin: 'auto' }} spacing={2}>
          <Alert severity="warning">{errorMessage}</Alert>
        </Stack>
      }
      <Container>
        <label htmlFor="icon-button-file" onClick={handleLabelClick}>
          <Input
            accept="image/*"
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={(e) => updateFile(e)}
          />
          <Button variant="contained" disabled={!enabled} component="span" startIcon={<PhotoCamera />}>
            Capturar Imagen
          </Button>
        </label>
      </Container>
      <Container>
        <label htmlFor="icon-button-file-from-folder" onClick={handleLabelClick}>
          <Input
            accept="image/*"
            id="icon-button-file-from-folder"
            type="file"
            onChange={(e) => updateFile(e)}
          />
          <Button variant="contained" disabled={!enabled} component="span" startIcon={<CollectionsIcon />}>
            Cargar De Galería
          </Button>
        </label>
      </Container>
      <Container>
        <label htmlFor="icon-button-download-zip-folder">
          <Button variant="contained"
            component="span"
            startIcon={<FolderZipIcon />}
            onClick={downloadImagesAsZip}>
            Descarga la galeria
          </Button>
        </label>
      </Container>
    </>
  );
}

export default ImageCaptureButton;
