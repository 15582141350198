import React, { useEffect } from 'react'
import './style.css'
import { useAppSelector } from '../../app/hooks';
import beige_short from '../../images/BEIGE_SHORT.png';

const Footer = () => {
  const weddingObj = useAppSelector((state) => state.wedding)
  useEffect(() => {
    if (weddingObj.foot_file_url && weddingObj.foot_file_url !== "") {
      let idObject = "footArea"
      document.getElementById(idObject).style.backgroundImage = `url(${weddingObj.foot_file_url})`
    }
  })
  return (
    <div id="footArea" className="footer-area">
      <div className="container">
        <div className="footer-content">
          <div className="content-sub">
            <h2>
              {/* {
              (weddingObj.foot_tittle) ? weddingObj.foot_tittle : "Invitegt"
              } */}
              <img src={beige_short} alt='logo' style={{
                maxHeight: '300px',
                maxWidth: '300px',
                position: 'relative',
                left: '0px',
                top: '-26px'
              }} />
            </h2>
            <span>
              {(weddingObj.foot_text) ? weddingObj.foot_text : "Te desea lo mejor en este día tan especial"}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
