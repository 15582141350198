import React, { useState } from 'react';
import imgBg from '../../images/img_bg_4.jpg';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import {
  setGuestInfo
} from '../../../../../features/guest_state/guest_state';
import docdbMethods from '../../../../../features/apis/docdb';
import alertMethods from '../../../../../features/alerts/alerts';
import BestWishes from '../../../../BestWishes';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ConfirmationButton from '../../../../ConfirmationButton';


const Rsvp = () => {

  const successAlert = (mensaje) => {
    alertMethods.generalAlert('', 'success', mensaje, 3000, true)
  }

  const errorAlert = (mensaje) => {
    alertMethods.generalAlert('', 'error', mensaje, 3000, true)
  }

  const dispatch = useAppDispatch();

  const welcomeObject = useAppSelector((state) => state.wedding);
  const rsvpReduxState = useAppSelector((state) => state.guest);
  const [confirmedGuest, setConfirmedGuest] = useState(false);
  const [stateForm, setStateForm] = useState({
    error: {}
  });

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  const changeHandler = (e) => {
    const error = stateForm.error;
    error[e.target.name] = '';
    dispatch(setGuestInfo({ key: e.target.name, value: e.target.value }));
  }

  const saveGuestsConfirmation = async (guestObj) => {
    try {
      docdbMethods.confirmationGuest(welcomeObject.wedding, guestObj).then(res => {
        successAlert('Tu confirmacion fue agregada correctamente.');
        setConfirmedGuest(true);
      })
    } catch (error) {
      errorAlert('Error al salvar los cambios' + error.message);
    } finally { }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!welcomeObject.show_final_assistance) return;
    const { error } = stateForm;

    if (rsvpReduxState.name === '') {
      error.name = "Ingresa tu nombre";
    }
    if (rsvpReduxState.rsvp === '') {
      error.rsvp = "Selecciona el numero de invitados";
    }

    if (error) {
      setStateForm({
        error
      })
    }
    if (rsvpReduxState.name !== '' && rsvpReduxState.rsvp !== '') {
      const guestInfo = { ...rsvpReduxState };
      delete guestInfo.celebration;
      delete guestInfo.error;
      saveGuestsConfirmation(guestInfo);
    }
    return
  }

  return (
    <div id="fh5co-started" className="fh5co-bg" style={{
      backgroundImage: `url(${imgBg})`
    }}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row animate-box">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2>Se Nuestro Invitado</h2>
            <ConfirmationButton />
            <p>Por favor reserva antes de {' '}
              {
                  (welcomeObject.wedding_confirmation_date !== '') ?
                  formatDate(welcomeObject.wedding_confirmation_date) :
                  (welcomeObject.wedding_date) ?
                    formatDate(welcomeObject.wedding_date) :
                    formatDate('2026-10-10')
              }.
            </p>
            {
              (!welcomeObject.kids) ?
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity="info">Respetuosamente no niños</Alert>
                </Stack>
                : ''
            }
            {
              (confirmedGuest) ?
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity="success">Tu mensaje ha sido enviado</Alert>
                </Stack>
                : ''
            }
          </div>
        </div>
        <div className="row animate-box">
          <div className="col-md-10 col-md-offset-1">
            <form className="form-inline" onSubmit={submitHandler}>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label htmlFor="name" className="sr-only">Nombre</label>
                  <input
                    type="name"
                    value={rsvpReduxState.name}
                    onChange={changeHandler}
                    className="form-control"
                    id="fname"
                    name="name"
                    placeholder="Tu nombre"
                  />
                  <p>{stateForm.error.name ? stateForm.error.name : ''}</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label htmlFor="rsvp" className="sr-only">Numero de Invitados</label>
                  <Box sx={{ minWidth: 180 }}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="rsvp-label"
                        sx={{
                          color: 'white',
                          height: 'auto', // Ajusta esto según sea necesario
                          alignItems: 'center', // Centra el contenido verticalmente
                          paddingTop: '0px', // Ajusta el padding superior
                          paddingBottom: '0px', // Ajusta el padding inferior
                          verticalAlign: 'middle', // Ajusta la alineación vertical
                        }}
                      >Numero de Invitados*</InputLabel>
                      <Select
                        sx={{
                          '.MuiSelect-select': {
                            height: 'auto', // Ajusta esto según sea necesario
                            alignItems: 'center', // Centra el contenido verticalmente
                            paddingTop: '0px', // Ajusta el padding superior
                            paddingBottom: '0px', // Ajusta el padding inferior
                            verticalAlign: 'middle', // Ajusta la alineación vertical
                          },
                        }}
                        labelId="rsvp-label"
                        value={rsvpReduxState.rsvp}
                        label="Numero de Invitados*"
                        onChange={changeHandler}
                        name='rsvp'
                        id='rsvp'
                        className="form-control"
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <p>{stateForm.error.rsvp ? stateForm.error.rsvp : ''}</p>
                </div>
              </div>
              <div className="col-12 col-sm-12">
                <textarea
                  className="contact-textarea"
                  value={rsvpReduxState.notes}
                  onChange={changeHandler}
                  placeholder="Envia tus mejores deseos"
                  name="notes"
                >
                </textarea>
              </div>
              <div className="col-md-4 col-sm-4">
                <button
                  type="submit"
                  className="btn btn-default btn-block"
                  disabled={welcomeObject.show_final_assistance && rsvpReduxState.phone === ''}
                >Asistiré
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        (rsvpReduxState.confirmed)
          ? <BestWishes /> : ''
      }
    </div>
  )
}

export default Rsvp;
