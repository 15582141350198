import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import Navbar from '../../components/Navbar'
import StepperAdmin from '../../components/Stepper'
import { useTour } from '@reactour/tour';
import { useLocation } from 'react-router-dom';
import { updateShowCompletePage } from '../../features/wedding-state/wedding-state';

const Completepage = ({ ReactElement }) => {

  const completeInformation = useAppSelector((state) => state.wedding);

  const { setIsOpen } = useTour();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/complete' && completeInformation.idTransaction !== "" && completeInformation.show_complete_page) {
      setIsOpen(true);
      dispatch(updateShowCompletePage(false));
    }
  }, [location, dispatch, completeInformation.idTransaction, setIsOpen, completeInformation.show_complete_page]);


  return (
    <div>
      <Navbar />
      <StepperAdmin />
      <div className="blog-pg-section">
        <div>
          <div className="row">
            <div className="col col-12">
              <ReactElement />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Completepage;
