import React from 'react';
import {
  Modal, Box, Typography, Button, List,
  ListItem, ListItemText, Divider
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const InputModal = ({ open, saveMethod, arrMembers, setOpen, completeConfirmation }) => {

  return (
    <div>
      <Modal
        open={open}
        onClose={setOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography component={'span'} variant={'body2'} id="modal-modal-title">
            Miembros
          </Typography>
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="info">Presiona el boton confirmar por cada miembro que asistirá, si no confirmas ninguno se tomará como que todos declinaron.</Alert>
          </Stack>
          <Typography component={'span'} variant={'body2'}>
            <List dense>
              {
                arrMembers.map((member, index) => {
                  return (
                    < React.Fragment key={index}>
                      <ListItem
                        secondaryAction={
                          <>
                            <Button
                              onClick={() => saveMethod(index, true)}
                              color="success"
                              variant={(member.confirmed) ? 'contained' : ''}
                            >
                              Confirmar
                            </Button>
                            <Button
                              onClick={() => saveMethod(index, false)}
                              color="error"
                              variant={(!member.confirmed) ? 'contained' : ''}
                            >
                              Declinar
                            </Button>
                          </>
                        }
                      >
                        <ListItemText primary={member.name} />
                      </ListItem>
                      {index < member.length - 1 && <Divider />}
                    </React.Fragment>
                  )
                })
              }
            </List>
          </Typography>
          <Button
            variant="contained"
            onClick={() => completeConfirmation()}>
            Confirmar
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default InputModal;
