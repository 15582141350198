import React, { useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Alert from '@mui/material/Alert';
import Preview from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import ComponentModal from '../ComponentModal';
import MyPdfDocument from '../../components/MyPdfDocument';
import MyPdfStyle2 from '../../components/MyPdfDocument/MyPdfStyle2';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const PdfAlertLink = ({finalLink, weddingInformation, styleNumber}) => {

  const [isPdfStyle, setPdfStyle] = useState(false);
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  const closePdfStyle = () => {
    setPdfStyle(false);
  };

  return (
    <Alert severity="success" className='complete-link-boda'>Link de Sobre pdf estilo {styleNumber}:
      <PDFDownloadLink
        document={
          (styleNumber === 1) ?
          MyPdfDocument({
            finalLink,
            weddingInformation,
            weddingDateFormated: formatDate(weddingInformation.wedding_date),
          }):
          MyPdfStyle2({
            finalLink,
            weddingInformation,
            weddingDateFormated: formatDate(weddingInformation.wedding_date),
          })
        }
        fileName={`invitation${weddingInformation.boy_name}_${weddingInformation.girl_name}.pdf`}
      >
        {({ loading }) =>
          loading ? 'Cargando documento...' : <FileDownloadIcon />
        }
      </PDFDownloadLink>
      <IconButton
        color="primary"
        aria-label={`see style ${styleNumber}`}
        onClick={() => setPdfStyle(!isPdfStyle)}
      >
        <Preview />
      </IconButton>
      <ComponentModal
        component={
          <PDFViewer style={{ minHeight: '500px', maxHeight: '800px' }} 
          width="100%">
            {
              (styleNumber === 1) ?
                <MyPdfDocument
                  finalLink={finalLink}
                  weddingInformation={weddingInformation}
                  weddingDateFormated={formatDate(weddingInformation.wedding_date)}
                /> :
                <MyPdfStyle2
                  finalLink={finalLink}
                  weddingInformation={weddingInformation}
                  weddingDateFormated={formatDate(weddingInformation.wedding_date)}
                />
            }
          </PDFViewer>
        }
        defaultState={isPdfStyle}
        handleFunction={closePdfStyle}
      />
    </Alert>
  );
};

export default PdfAlertLink;
