import React, { useRef, useState } from 'react';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy/styles';
import s3request from '../../../features/apis/s3api';
import { useAppDispatch } from '../../../app/hooks';
import ProfileInputModal from './../../ProfileSample/ProfileInputModal';
import {
  loadingComponent,
  updateCollaborators,
  updateOldWedding
} from '../../../features/wedding-state/wedding-state';
import { useLocation } from 'react-router-dom';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { useAppSelector } from '../../../app/hooks';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';

const CollaboratorSample = (
  { name, url, description, email, index, facebook, instagram }
) => {

  const fileInputRef = useRef(null);
  const dispatch = useAppDispatch();
  const [openName, setOpenName] = useState(false);
  const [openDescription, setOpenDescription] = useState(false);
  const [openFacebook, setOpenFacebook] = useState(false);
  const [openInstagram, setOpenInstagram] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const weddingObj = useAppSelector((state) => state.wedding);
  const admin_or_profile = currentPath.startsWith("/Admin") || currentPath.startsWith("/profile");

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const successAlert = () => {
    let mensaje = 'Cambios guardados correctamente.'
    alertMethods.generalAlert('', 'success', mensaje, 3000, true)
  }

  const handleFileChange = async (event) => {
    if (event.target.files.length === 0) return;
    var obj_file = await s3request.buildObjImage(event, weddingObj.wedding);
    const copyArr = [...weddingObj.collaborators];
    const copyObject = { ...weddingObj.collaborators[index] };
    copyObject["url"] = obj_file.url;
    copyObject["filename"] = obj_file.filename;
    copyArr[index] = copyObject;
    uploadImageCollaborator(obj_file.filename, event.target.files[0]);
    dispatch(updateCollaborators(copyArr));
  };

  const CustomAvatar = styled(Avatar)({
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' // Añade un sombreado
    }
  });

  const handleSaveInformation = async (e) => {
    e.preventDefault();
    dispatch(loadingComponent(true));
    const updateObj = await docdbMethods.getDifferenceObj(weddingObj, weddingObj.previousdoc)
    docdbMethods.updateDocument(weddingObj.wedding, updateObj).then(res => {
      dispatch(updateOldWedding(updateObj))
      successAlert()
    })
    dispatch(loadingComponent(false));
  };

  const uploadImageCollaborator = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const theme = extendTheme({});

  return (
    <div
      id="PersonalForm"
      className="personal-form-area go-personal-form-area section-padding"
    >
      <div className="container" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>

        <CssVarsProvider theme={theme}>
          <Card
            sx={{
              width: 400,
              maxWidth: '100%',
              boxShadow: 'lg',
            }}
          >
            <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
              <CustomAvatar
                src={(url ? url : '')}
                onClick={(admin_or_profile) ? handleAvatarClick : undefined}
                sx={{ '--Avatar-size': '4rem' }}
              />
              <input
                type="file"
                id="profile-image"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <Chip
                size="sm"
                variant="soft"
                color="primary"
                onClick={(admin_or_profile) ? () => setOpenMail(!openMail) : undefined}
                sx={{
                  mt: -1,
                  mb: 1,
                  border: '3px solid',
                  borderColor: 'background.surface',
                }}
              >
                {(email) ? email : 'ejemplo@mail.com'}
              </Chip>
              <Typography
                onClick={(admin_or_profile) ? () => setOpenName(!openName) : undefined}
                level="title-lg">{
                  (name ? name : 'Nombre de Usuario')
                }</Typography>

              <Typography
                onClick={(admin_or_profile) ? () => setOpenDescription(!openDescription) : undefined}
                level="body-sm" sx={{ maxWidth: '24ch' }}>
                {
                  (description) ? description : 'Agrega tu Bio aquí'
                }
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mt: 2,
                  '& > button': { borderRadius: '2rem' },
                }}
              >
                <IconButton
                  onClick={(admin_or_profile) ? () => setOpenFacebook(!openFacebook) : () => window.open(facebook, '_blank')}
                  size="sm"
                  variant="plain"
                  color="neutral">
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5Z"
                      />
                    </svg>
                  </SvgIcon>
                </IconButton>
                <IconButton
                  onClick={(admin_or_profile) ? () => setOpenInstagram(!openInstagram) : () => window.open(instagram, '_blank')}
                  size="sm" variant="plain" color="neutral">
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 6.865A5.135 5.135 0 1 0 17.135 12A5.135 5.135 0 0 0 12 6.865Zm0 8.469A3.334 3.334 0 1 1 15.334 12A3.333 3.333 0 0 1 12 15.334Z"
                      />
                      <path
                        fill="currentColor"
                        d="M21.94 7.877a7.333 7.333 0 0 0-.465-2.427a4.918 4.918 0 0 0-1.153-1.772a4.894 4.894 0 0 0-1.77-1.153a7.323 7.323 0 0 0-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.057.011-4.123.06a7.333 7.333 0 0 0-2.428.465a4.905 4.905 0 0 0-1.77 1.153A4.886 4.886 0 0 0 2.525 5.45a7.333 7.333 0 0 0-.464 2.427c-.05 1.066-.06 1.407-.06 4.123s.01 3.057.06 4.123a7.334 7.334 0 0 0 .464 2.427a4.888 4.888 0 0 0 1.154 1.772a4.917 4.917 0 0 0 1.771 1.153a7.338 7.338 0 0 0 2.428.464C8.944 21.988 9.285 22 12 22s3.057-.011 4.123-.06a7.333 7.333 0 0 0 2.427-.465a5.113 5.113 0 0 0 2.925-2.925a7.316 7.316 0 0 0 .465-2.427c.048-1.067.06-1.407.06-4.123s-.012-3.057-.06-4.123Zm-1.8 8.164a5.549 5.549 0 0 1-.343 1.857a3.311 3.311 0 0 1-1.898 1.898a5.522 5.522 0 0 1-1.857.344c-1.055.048-1.371.058-4.042.058s-2.986-.01-4.04-.058a5.526 5.526 0 0 1-1.857-.344a3.108 3.108 0 0 1-1.15-.748a3.085 3.085 0 0 1-.748-1.15a5.521 5.521 0 0 1-.344-1.857c-.048-1.054-.058-1.37-.058-4.04s.01-2.987.058-4.042a5.563 5.563 0 0 1 .344-1.857a3.107 3.107 0 0 1 .748-1.15a3.082 3.082 0 0 1 1.15-.748A5.523 5.523 0 0 1 7.96 3.86C9.014 3.81 9.331 3.8 12 3.8s2.987.011 4.042.059a5.564 5.564 0 0 1 1.857.344a3.31 3.31 0 0 1 1.898 1.898a5.523 5.523 0 0 1 .344 1.857c.048 1.055.058 1.37.058 4.041s-.01 2.986-.058 4.041ZM17.339 5.462Z"
                      />
                    </svg>
                  </SvgIcon>
                </IconButton>
              </Box>
            </CardContent>
            <CardOverflow sx={{ bgcolor: 'background.level1' }}>
              <CardActions buttonFlex="1">
                <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
                  {
                    (admin_or_profile) ?
                      <Button onClick={(e) => handleSaveInformation(e)}>Guardar</Button>
                      : ""
                  }
                </ButtonGroup>
              </CardActions>
            </CardOverflow>
          </Card>
          <ProfileInputModal
            openModal={openMail}
            handleClose={() => setOpenMail(false)}
            dispatchMethod={updateCollaborators}
            index={index}
            arrObject={weddingObj.collaborators}
            attrName="email"
            attrValue={email}
          />
          <ProfileInputModal
            openModal={openName}
            handleClose={() => setOpenName(false)}
            dispatchMethod={updateCollaborators}
            index={index}
            arrObject={weddingObj.collaborators}
            attrName="name"
            attrValue={name}
          />
          <ProfileInputModal
            openModal={openDescription}
            handleClose={() => setOpenDescription(false)}
            dispatchMethod={updateCollaborators}
            index={index}
            arrObject={weddingObj.collaborators}
            attrName="description"
            attrValue={description}
          />
          <ProfileInputModal
            openModal={openFacebook}
            handleClose={() => setOpenFacebook(false)}
            dispatchMethod={updateCollaborators}
            index={index}
            arrObject={weddingObj.collaborators}
            attrName="facebook"
            attrValue={facebook}
          />
          <ProfileInputModal
            openModal={openInstagram}
            handleClose={() => setOpenInstagram(false)}
            dispatchMethod={updateCollaborators}
            index={index}
            arrObject={weddingObj.collaborators}
            attrName="instagram"
            attrValue={instagram}
          />
        </CssVarsProvider>
      </div>
    </div>
  )
}

export default CollaboratorSample
