import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  updateGiftLink,
  updateGiftName,
  updateGiftImage,
  updateOldWedding,
  loadingComponent,
  updateShowWeddingGift,
} from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api'
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';
import ComponentModal from '../../ComponentModal';
import ReactCropComponent from '../../ReactCropEditor';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';

const successAlert = (mensaje) => {
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const AdminSidebarGifts = () => {

  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [srcFile, setSrcFile] = useState(null);
  const [cropFile, setCropFile] = useState(null);

  const submitHandler = async () => {
    try {
      dispatch(loadingComponent(true))
      if (document.getElementById("gift-fileupload").files.length === 1) {
        await uploadImageWedding(
          giftSection.gift_file[0].filename,
          cropFile
        )
      }
      const updateObj = await docdbMethods.getDifferenceObj(giftSection, giftSection.previousdoc)
      docdbMethods.updateDocument(giftSection.wedding, updateObj).then(res => {
        dispatch(updateOldWedding(updateObj))
        successAlert("Cambios salvados correctamente.")
      })
    } catch (error) {
      errorAlert('Error al salvar los cambios.')
    }
    finally {
      dispatch(loadingComponent(false))
    }
  }

  const uploadImageWedding = async (filename, file) => {
    return await s3request.uploadImage(
      filename,
      file.type,
      file
    )
  }

  const giftSection = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  const updateGiftSection = (e) => {
    if (e.id === "gift_name") {
      dispatch(updateGiftName(e.value))
    } else if (e.id === "gift_link") {
      dispatch(updateGiftLink(e.value))
    }
  }

  const maxSizeInBytes = 10 * 1024 * 1024; // 15 MB

  const updateFile = async (e) => {
    var file = e.target.files[0]
    if (file && file.size > maxSizeInBytes) {
      errorAlert('El archivo es demasiado grande. El tamaño máximo permitido es 15 MB.');
      e.target.value = ''; // Limpia el input
      return;
    } else if (e.target.files && e.target.files.length > 0) {
      setSrcFile(e.target.files[0]);
      setPreviewVisible(true);
    }
  }

  const onResponseCropFile = async (cropFile, index = -1) => {
    var obj_file = await s3request.buildCropObjImage(srcFile.name, cropFile, giftSection.wedding);
    var arr_ceremony = [obj_file];
    dispatch(updateGiftImage(arr_ceremony));
    setPreviewVisible(false);
    setCropFile(cropFile);
  };

  const closePreview = () => {
    setPreviewVisible(false);
  };

  const saveCheckBoxChange = async (updateObj) => {
    docdbMethods.updateDocument(giftSection.wedding, updateObj).then(res => {
      if (res && res.status === "error") errorAlert();
      dispatch(updateOldWedding(updateObj))
      successAlert("Cambios salvados correctamente.")
    });
  }

  const handleCheckboxYesChange = (event) => {
    if (event.target.checked) {
      dispatch(updateShowWeddingGift(true));
    } else {
      dispatch(updateShowWeddingGift(false));
    }
    saveCheckBoxChange({ wedding_gift_visible: event.target.checked })
  };

  const handleCheckboxNoChange = (event) => {
    if (event.target.checked) {
      dispatch(updateShowWeddingGift(false));
    } else {
      dispatch(updateShowWeddingGift(true));
    }
    saveCheckBoxChange({ wedding_gift_visible: !event.target.checked })
  };

  return (
    <div>

      <div>
        <p className='text-center'>¿Deseas utilizar esta <br></br> sección en tu boda?</p>
        <div className='text-center'>
          <FormControlLabel
            control={
              <Checkbox
                checked={giftSection.wedding_gift_visible}
                onChange={handleCheckboxYesChange}
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
                inputProps={{ 'aria-label': 'Sí Checkbox' }}
              />
            }
            label="Sí"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!giftSection.wedding_gift_visible}
                onChange={handleCheckboxNoChange}
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
                inputProps={{ 'aria-label': 'No Checkbox' }}
              />
            }
            label="No"
          />
        </div>
      </div>

      <form onSubmit={submitHandler}>
        <div style={{ display: 'flex' }}>
          <input
            style={{ width: '185px' }}
            type="text" disabled
            className="form-control" placeholder="Select image of shop"
            value={(giftSection.gift_file[0].filename)
              ? giftSection.gift_file[0].filename.split('\\').pop().split('/').pop()
              : "" || ''}
          />
          <label htmlFor="gift-fileupload" className="custom-file-upload">
            <i className="fa fa-upload"></i>
          </label>
          <input id="gift-fileupload" type="file" accept="image/*" className="form-control"
            onChange={(e) => updateFile(e)} />
          <ComponentModal
            component={
              <ReactCropComponent
                file={srcFile}
                resolveFunction={onResponseCropFile}
                index={0}
              />
            }
            defaultState={isPreviewVisible}
            handleFunction={closePreview}
          ></ComponentModal>
        </div>
        <div>
          <input
            style={{ width: '185px' }}
            id="gift_name" type="text" className="form-control" placeholder="Name of shop"
            value={(giftSection.gift_name) ? giftSection.gift_name : ""}
            onChange={(e) => updateGiftSection(e.target)}
          />
        </div>
        <div>
          <input
            style={{ width: '185px' }}
            id="gift_link" type="text" className="form-control" placeholder="Link of shop"
            value={(giftSection.gift_link) ? giftSection.gift_link : ""}
            onChange={(e) => updateGiftSection(e.target)}
          />
        </div>
        <br></br>
        <div>
          <div className="text-center">
            <Tooltip
              title="Guardar cambios"
            >
              <CloudUploadTwoToneIcon
                onClick={() => submitHandler()}
                style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
              />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AdminSidebarGifts
