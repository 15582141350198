import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateGiftStoreLink, updateOldWedding, updateShowWeddingGiftStore } from '../../../features/wedding-state/wedding-state';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import OptionalSection from '../OptionalSection';

const successAlert = () => {
  let mensaje = 'Cambios guardados correctamente.'
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const GiftStores = () => {

  const saveStoreLink = async () => {
    const updateObj = await docdbMethods.getDifferenceObj(fileStoresInformation, fileStoresInformation.previousdoc)
    docdbMethods.updateDocument(fileStoresInformation.wedding, updateObj).then(res => {
      dispatch(updateOldWedding(updateObj))
      successAlert()
    })
  }

  const dispatch = useAppDispatch();
  const fileStoresInformation = useAppSelector((state) => state.wedding)

  const [selectedStoreCemaco, setSelectedStoreCemaco] = React.useState(false);
  const [selectedStoreSiman, setSelectedStoreSiman] = React.useState(false);

  const handleButtonClick = (index) => {
    setSelectedStoreCemaco(index === 0);
    setSelectedStoreSiman(index === 1);
  };

  const handleChangeLink = (e) => {
    var arrLink = [...fileStoresInformation.giftstores_file_info];
    if (e.target.id.includes("link_")) {
      let index = String(e.target.id).replace("link_", '');
      arrLink[index] = { ...arrLink[index], linksurl: e.target.value };
      dispatch(updateGiftStoreLink(arrLink));
    }
  }

  return (
    <div>
      <div>
        <OptionalSection
          visibleVariable={fileStoresInformation.wedding_giftstore_visible}
          reduxMethod={updateShowWeddingGiftStore}
          reduxVariableName={"wedding_giftstore_visible"}
          weddingId={fileStoresInformation.wedding}
        />
        <p className='text-center'>Elige tu tienda de obsequios</p>
        {fileStoresInformation.giftstores_file_info.map((component, index) => (
          <div key={'store_gifts' + index} >
            <FormControlLabel
              control={
                <Checkbox
                  checked={index === 0 ? selectedStoreCemaco : index === 1 ? selectedStoreSiman : false}
                  onChange={() => handleButtonClick(index)}
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                />
              }
              label={component.name}
            />
            {((index === 0 && selectedStoreCemaco) || (index === 1 && selectedStoreSiman)) && (
              <div>
                <div className="comment-area">
                  <p>Ingresa el link de {component.name} <br></br> para tus obsequios</p>
                </div>
                <input
                  style={{ width: '185px' }}
                  id={"link_" + index}
                  type="text"
                  className="form-control"
                  placeholder={"Link boda " + component.name}
                  value={fileStoresInformation.giftstores_file_info[index].linksurl || ''}
                  onChange={(e) => handleChangeLink(e)}
                />
              </div>
            )}
          </div>))}
        <div className='text-center'>
          <Tooltip
            title="Guardar cambios"
          >
            <CloudUploadTwoToneIcon
              onClick={() => saveStoreLink()}
              style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default GiftStores
