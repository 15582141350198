import React from 'react';
import { Button, MobileStepper, Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import CollaboratorSample from '../Sample';

const CollaboratorsCarousel = ({collaboratorArr}) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {collaboratorArr.map((collaborator, index) => (
          <div name={"collaborator_"+index} key={collaborator.name + "_" + index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box>
                <CollaboratorSample
                  url={collaborator.url}
                  name={collaborator.name}
                  description={collaborator.description}
                  index={index}
                  email={collaborator.email}
                  facebook={collaborator.facebook}
                  instagram={collaborator.instagram}
                />
              </Box>
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={collaboratorArr.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small"
            onClick={handleNext}
            disabled={
              (activeStep === collaboratorArr.length - 1)
              || (collaboratorArr.length === 0)
            }>
            Siguiente
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            Atrás
          </Button>
        }
      />
    </>
  );
};

export default CollaboratorsCarousel;
