import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  setProfileBillingInformation,
} from '../../features/user-state/user-state';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Alert from '@mui/material/Alert';
import docdbMethods from '../../features/apis/docdb';

const AddressForm = () => {

  const billingInformationRedux = useAppSelector((state) => state.user.billingInformation);
  const billingUserNameRedux = useAppSelector((state) => state.user.userName);

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  const [billingInformation, setBillingInformation] = React.useState({
    firstName: (billingInformationRedux?.firstName) ? billingInformationRedux?.firstName : '',
    lastName: (billingInformationRedux?.lastName) ? billingInformationRedux?.lastName : '',
    address1: (billingInformationRedux?.address1) ? billingInformationRedux?.address1 : '',
    address2: (billingInformationRedux?.address2) ? billingInformationRedux?.address2 : '',
    city: (billingInformationRedux?.city) ? billingInformationRedux?.city : '',
    state: (billingInformationRedux?.state) ? billingInformationRedux?.state : '',
    zip: (billingInformationRedux?.zip) ? billingInformationRedux?.zip : '',
    country: (billingInformationRedux?.country) ? billingInformationRedux?.country : '',
    phone: (billingInformationRedux?.phone) ? billingInformationRedux?.phone : '',
    email: (billingInformationRedux?.email) ? billingInformationRedux?.email : '',
    error: false,
  });

  const [saveInformation, setSaveInformation] = React.useState(false);

  const handleSaveInformation = (e) => {
    if (e.target.checked) {
      setSaveInformation(true);
      if (
        billingInformation.firstName !== ''
        && billingInformation.lastName !== ''
        && billingInformation.address1 !== ''
        && billingInformation.city !== ''
        && billingInformation.zip !== ''
        && billingInformation.country !== ''
        && billingInformation.email !== ''
) {
        setBillingInformation({ ...billingInformation, error: false });
        const copiedObj = { ...billingInformation };
        const copiedReduxObj = { ...billingInformationRedux };
        delete copiedObj.error;
        delete copiedReduxObj.error;
        let compareAddress = areObjectsEqual(copiedObj, copiedReduxObj);
        if (!compareAddress) {
          docdbMethods.updateUserDocument(billingUserNameRedux, { billingInformation })
            .then(res => {
              if (res.status !== "error") {
                dispatch(setProfileBillingInformation(billingInformation));
              }
            });
        }
      } else {
        setBillingInformation({ ...billingInformation, error: true });
      }
    } else {
      setSaveInformation(false);
    }
  }

  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      {
        (billingInformation.error) ?
          <Alert severity="error">Hay algunos campos que falta llenar!</Alert>
          : ''
      }
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={billingInformation.firstName}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, firstName: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={billingInformation.lastName}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, lastName: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            fullWidth
            autoComplete="tel-local"
            variant="standard"
            value={billingInformation.phone}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, phone: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            value={billingInformation.address1}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, address1: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
            value={billingInformation.address2}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, address2: e.target.value })
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            value={billingInformation.city}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, city: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
            value={billingInformation.state}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, state: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            value={billingInformation.zip}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, zip: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            variant="standard"
            value={billingInformation.country}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, country: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="email para factura"
            fullWidth
            autoComplete="email"
            variant="standard"
            helperText="Por favor, introduce una dirección de correo válida."
            value={billingInformation.email}
            onChange={(e) => {
              setBillingInformation({ ...billingInformation, email: e.target.value });
              setSaveInformation(false);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox
              color="secondary"
              name="saveAddress"
              value="yes"
              onChange={(e) => handleSaveInformation(e)}
              checked={saveInformation}
            />}
            label="Guardar esta información"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddressForm;
