const complete_steps = [
    {
        selector: '.complete-preview',
        content: 'Si presionas este botón, podrás observar cómo se verán las invitaciones cuando sean enviadas a tus contactos.',
    },
    {
        selector: '.complete-link-boda',
        content: 'Puedes copiar el link de tu boda desde aquí. O enviar sobres pdf.',
    },
];

export default complete_steps;