import sampleImage from './1.jpg';

const FamilyComponent = ({ family, index }) => {
  return (
    <div 
    key={`family_member_${family.name}_${index}`} 
    id={`family_member_${family.name}_${index}`} 
    className="col-lg-4 col-md-6 col-sm-12"
    >
      <div className="single-team-member">
        <div className="family-img">
          <img className="img-fluid" src={
            (family.url && family.url !== "") 
            ? family.url : sampleImage
          } alt="" />
        </div>
        <div className="family-info">
          <h4> {family.name}</h4>
          <p>{family.relationship}</p>
        </div>
      </div>
    </div>
  )
}

export default FamilyComponent;