import React from 'react';
import { useAppSelector } from '../../app/hooks';
import './style.css'
import StoryComponent from '../storyComponents';

const Story = () => {

  let storyObject = useAppSelector((state) => state.wedding);
  return (
    <div id="story" className="story-area section-padding">
      <div className="container">
        <div className="col-l2">
          <div className="section-title text-center">
            <h2>Nuestra Historia</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="story clearfix">
              <div className="story-top"></div>
              <div className="content-wrapper">
                {
                  storyObject.timeline_file_info.map((component, index) => (
                    <StoryComponent key={index} storyObject={component} index />)
                  )
                }
              </div>
              <div className="story-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Story;

