const home_steps = [
  {
    selector: '.h-front',
    content: 'Te mostraremos los diferentes componentes personalizables para crear tu hermosa boda.👫',
  },
  {
    selector: '.front1',
    content: 'El encabezado e introduccion de tu invitacion.'
  },
  {
    selector: '.h-countdown',
    content: 'Aqui podrás ver el tiempo restante hasta que llegue el gran día.⌚😊'
  },
  {
    selector: '.h-couple',
    content: 'Aqui podras ver informacion de la feliz pareja.👫'
  },
  {
    selector: '.h-welcome',
    content: 'Título de bienvenida a tu boda, y también un link para el lugar de tu evento.'
  },
  {
    selector: '.h-story',
    content: 'La historia con tu pareja. Por ejemplo, puedes agregar como se conocieron, en donde fue su primera cita, etc.📓'
  },
  {
    selector: '.h-groomsmen-bridesmaid',
    content: 'padrinos y las damas de honor de tu boda.'
  },
  {
    selector: '.h-location',
    content: 'Lugares y dias en los que se realizaran los actos de tu boda. Puedes incluir información sobre la ceremonia, la recepción, etc.'
  },
  {
    selector: '.h-gallery',
    content: 'El album como pareja.'
  },
  {
    selector: '.login-create',
    content: 'Para poder iniciar con tu boda, debes ingresar a tu cuenta o crear una nueva.👤'
  },
  // {
  //   selector: '.create-wedding',
  //   content: 'Por último, debes presionar este botón para poder crear tu boda y poder agregar todos los elementos que vimos en el recorrido.'
  // }
];

export default home_steps;
