import { useAppSelector } from '../../../../../app/hooks';
import StoryComponent from '../StoryComponent';
import imgSample from '../../images/1.jpg'

const Story = () => {
  let weddingObj = useAppSelector((state) => state.wedding);
  return (
    <div id="fh5co-couple-story">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <span>Con Amor</span>
            <h2>Nuestra Historia</h2>
            {/* <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
              blind texts.</p> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-md-offset-0">
            <ul className="timeline animate-box">
              {
                weddingObj.timeline_file_info.map((storyObject, index) => (
                  <StoryComponent 
                  key={"storeComponente" + index} 
                  storyObject={storyObject} index={index} 
                  imgSample={(storyObject.url)? storyObject.url: imgSample}
                  />
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Story;
