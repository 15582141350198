import Navbar from '../../../components/Navbar';
import ExampleComponent from '../../../components/NewTemplateStyles/Style2/components/Example';
import Footer from '../../../components/footer';
import { useLocation } from 'react-router-dom';

const ExampleStyle6 = () => {
  const location = useLocation();
  return (
    <div className='style6_fancy'>
      <Navbar onPreview={true} />
      <ExampleComponent />
      {
        (location.pathname.startsWith('/ejemplo'))? "" : <Footer />
      }
    </div>
  )
}

export default ExampleStyle6;
