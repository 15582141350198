import axios from 'axios';

const urlendpoint = (process.env.REACT_APP_API_DOCDB_ENDPOINT || window.REACT_APP_API_DOCDB_ENDPOINT)

const createDocument = async (username, bodyRequest) => {
  return await axios.post(
    urlendpoint + "/docdb/create_wedding/" + username, bodyRequest)
    .then(res => res.data).catch((error) => error)
}

const searchDocument = async (wedding) => {
  return await axios.get(
    urlendpoint + "/docdb/wedding/" + wedding)
    .then(res => res.data).catch(error => error)
}

const updateDocument = async (weddingId, bodyRequest) => {
  if (Object.keys(bodyRequest).length !== 0) {
    return await axios.post(
      urlendpoint +
      "/docdb/update_wedding/" + weddingId, bodyRequest
    ).then(res => res.data).catch((error) => (
      { status: "error", message: error.message }
    ));
  }
}

const getUserDocument = async (username) => {
  return await axios.get(
    urlendpoint + "/docdb/user/" + username)
    .then(res => res.data)
    .catch((error) => ({ "message": error.message, "status": "error" }));
}

const updateUserDocument = async (username, bodyRequest) => {
  return await axios.post(
    urlendpoint +
    "/docdb/update_user/" + username, bodyRequest
  ).then(res => res.data)
    .catch((error) => ({ "message": error.message, "status": "error" }));
}

const getDifferenceObj = async (newObj, oldObj) => {
  let keyFound = {};
  Object.keys(newObj).forEach(key => {
    if (key !== "previousdoc" && newObj[key] !== "" && newObj[key] !== oldObj[key]) {
      if (Array.isArray(newObj[key])) {
        var parseNewObject = newObj[key].map(({ index, contenttype, ...items }) => {
          if (items.url && !items.url.includes("img.invitegt.com")) {
            items.url = "https://img.invitegt.com/" + items.filename;
          }
          return items;
        });
        keyFound[key] = parseNewObject;
      } else keyFound[key] = newObj[key];
    }
  });
  return keyFound
}

const confirmationGuest = async (weddingId, bodyRequest) => {
  if (Object.keys(bodyRequest).length !== 0) {
    return await axios.post(
      urlendpoint +
      "/docdb/confirmation_guest/" + weddingId, bodyRequest
    ).then(res => res.data).catch((error) => (
      { status: "error", message: error.message }
    ));
  }
}

const getGuests = async (weddingId, phoneNumber) => {
  return await axios.get(
    urlendpoint + "/docdb/get_guest/" + weddingId + "/" + phoneNumber)
    .then(res => res.data)
    .catch((error) => ({ "message": error.message, "status": "error" }));
}

const getGallery = async (weddingId) => {
  return await axios.get(
    urlendpoint + "/docdb/get_gallery/" + weddingId)
    .then(res => res.data)
    .catch((error) => ({ "message": error.message, "status": "error" }));
}

const pushToGallery = async (weddingId, bodyRequest) => {
  if (Object.keys(bodyRequest).length !== 0) {
    var basePath = "docdb";
    if (urlendpoint.includes("invitegt")) {
      const data = JSON.stringify({
        "MessageBody": {
          "wedding": weddingId,
          "method": "push_to_gallery",
          "item": bodyRequest
        }
      });
      basePath = "docdb_queue";
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlendpoint +`/${basePath}/push_to_gallery/` + weddingId,
        data: data
      };
      return axios.request(config)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.log(error);
          return { status: "error", message: error.message }
        });
    } else {
      return await axios.post(
        urlendpoint +
        `/${basePath}/push_to_gallery/` + weddingId, bodyRequest
      ).then(res => res.data).catch((error) => {
        return { status: "error", message: error.message }
      });
    }

  }
}

const docdbMethods = {
  createDocument,
  searchDocument,
  updateDocument,
  getUserDocument,
  updateUserDocument,
  getDifferenceObj,
  confirmationGuest,
  getGuests,
  getGallery,
  pushToGallery,
}

export default docdbMethods
