import React from 'react'
import Homepage from '../HomePage'
import { TourProvider } from '@reactour/tour'
import home_steps from '../../features/tour/homet'
import mobileh_steps from '../../features/tour/mobileh'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../app/hooks'

const HomeTour = () => {

  const checkifmobilemediaquery = useMediaQuery('(max-width: 991px)');
  const checkifdesktopmediaquery = useMediaQuery('(min-width: 991px)');

  const radius = 10;
  const weddingObject = useAppSelector((state) => (state.wedding));

  return (
    checkifmobilemediaquery ?
      (<TourProvider steps={mobileh_steps}
        styles={{
          popover: (base) => ({
            ...base,
            '--reactour-accent': '#3F7267',
            borderRadius: radius,
          }),
          maskArea: (base) => ({ ...base, rx: radius, }),
          maskWrapper: (base) => ({ ...base, }),
          badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
          close: (base) => ({ ...base, right: 'auto', left: 18, top: 8 }),
        }}
        badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}>
        <Homepage onPreview=
          {
            (weddingObject.show_final_assistance)
              ? true : false
          } />
      </TourProvider>)
      : checkifdesktopmediaquery ?
        (<TourProvider steps={home_steps}
          styles={{
            popover: (base) => ({
              ...base,
              '--reactour-accent': '#3F7267',
              borderRadius: radius,
            }),
            maskArea: (base) => ({ ...base, rx: radius, }),
            maskWrapper: (base) => ({ ...base, }),
            badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
            controls: (base) => ({ ...base, marginTop: 100 }),
            close: (base) => ({ ...base, right: 'auto', left: 8, top: 8 }),
          }}
          badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps}>
          <Homepage onPreview=
            {
              (weddingObject.show_final_assistance)
                ? true : false
            } />
        </TourProvider>) : ""
  )
}

export default HomeTour
