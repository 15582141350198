import React from 'react'
import strory1 from '../../images/events/1.jpg'
import '../location/style.css'
import { useAppSelector } from '../../app/hooks'

const WeddingCeremony = () => {

  const weddingObj = useAppSelector((state) => state.wedding)

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1])-1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  return (
    <div className="service-area-menu h-location-mob">
      <div className="Ceremony-wrap">
        <div className="row">
          <div className="col-lg-5">
            <div>
              <img
                src={(weddingObj.ceremony_file[0].url)
                  ? weddingObj.ceremony_file[0].url
                  : strory1}
                className={'ceromony-img'}
                alt="Ceremony img"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className={'ceromony-content'}>
              <h3>Ceremonia de Bodas</h3>
              <span>
                {(weddingObj.ceremony_name) ? weddingObj.ceremony_name : "Nombre del lugar de la ceremonia"}
              </span>
              <span>
                {(weddingObj.wedding_date) ? formatDate(weddingObj.wedding_date) : "Sunday, 25 July"}
                {(weddingObj.ceremony_hour) ? `, ${weddingObj.ceremony_hour}` : ", 9.00 AM"}
              </span>
              <span>
                {(weddingObj.ceremony_adress) ? weddingObj.ceremony_adress : "256 Apay Road,Califonia Bong, London"}
              </span>
              <a 
              href={weddingObj.ceremony_link} 
              target="_blank" 
              rel="noreferrer"
              style={{
                pointerEvents: weddingObj.ceremony_link ? 'auto' : 'none',
                textDecoration: weddingObj.ceremony_link ? 'underline' : 'none'
              }}
              >
                See Location
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeddingCeremony
