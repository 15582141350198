import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateOldWedding, updateTimeline } from '../../../features/wedding-state/wedding-state';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip';
import TimeLineEvent from '../TimelineEvent';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const successAlert = () => {
  let mensaje = 'Cambios guardados correctamente.'
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = () => {
  let mensaje = 'Todos los campos son obligatorios.'
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const AdminSidebarTimeline = () => {

  const saveTimeline = async () => {
    for (let i = 0; i < timelineSeccion.timeline_file_info.length; i++) {
      if (
        timelineSeccion.timeline_file_info[i].title === "" ||
        timelineSeccion.timeline_file_info[i].date === "" ||
        timelineSeccion.timeline_file_info[i].speech === "") {
        errorAlert()
        return
      }
    }
    const updateObj = await docdbMethods.getDifferenceObj(timelineSeccion, timelineSeccion.previousdoc)
    docdbMethods.updateDocument(timelineSeccion.wedding, updateObj).then(res => {
      dispatch(updateOldWedding(updateObj))
      successAlert()
    })
  }

  const addNewEventToStory = () => {
    if (timelineSeccion.timeline_file_info.length < 6) {
      var copyArray = [...timelineSeccion.timeline_file_info];
      copyArray.push(
        {
          filename: "",
          url: "",
          contenttype: "",
          tittle: "",
          date: "",
          speech: "",
          description: "Nuevo evento"
        }
      )
      dispatch(updateTimeline(copyArray));
    }
  }

  const removeNewEventToStory = () => {
    var copyArray = [...timelineSeccion.timeline_file_info];
    copyArray.pop()
    dispatch(updateTimeline(copyArray));
  }

  const timelineSeccion = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  return (
    <div>
      {timelineSeccion.timeline_file_info.map((component, index) => (
        <TimeLineEvent 
        key={index} 
        index={index} 
        component={component}  
        />
      ))
      }
      <div className="text-center">
        <Tooltip
          title={timelineSeccion.timeline_file_info.length < 6 ? "Agregar evento": "Máximo de eventos alcanzado"}
        >
          <AddCircleOutlineIcon
            onClick={() => addNewEventToStory()}
            style={
              {
                cursor: timelineSeccion.timeline_file_info.length < 6 ? 'pointer' : 'not-allowed',
                color: '#649E93', fontSize: '45px'
              }
            }
          />
        </Tooltip>
        <Tooltip
          title="Remover evento"
        >
          <RemoveCircleOutlineIcon
            onClick={() => removeNewEventToStory()}
            style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
          />
        </Tooltip>
      </div>

      <div className="text-center">
        <Tooltip
          title="Guardar cambios"
        >
          <CloudUploadTwoToneIcon
            onClick={() => saveTimeline()}
            style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
          />
        </Tooltip>
      </div>
    </div >
  )
}

export default AdminSidebarTimeline
