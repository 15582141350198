import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '../../app/hooks';

const SummaryForm = () => {

  const userInformation = useAppSelector((state) => (state.user));
  const weddingInformation = useAppSelector((state) => (state.wedding));
  const paymentInformation = useAppSelector((state) => (state.pay));

  const products = [
    {
      name: 'Invitacion de Boda',
      desc: weddingInformation.boy_name + ' & ' + weddingInformation.girl_name,
      price: 'Q' + weddingInformation.price_amount,
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Resumen de la orden
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {'Q' + weddingInformation.price_amount}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping
          </Typography>
          <Typography gutterBottom>{
            userInformation.billingInformation.firstName 
            + ' ' + userInformation.billingInformation.lastName
          }</Typography>
          <Typography gutterBottom>{
            userInformation.billingInformation.address1
            + ', ' + userInformation.billingInformation.city
            + ', ' + userInformation.billingInformation.state
            + ', ' + userInformation.billingInformation.country
          }</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment details
          </Typography>
          <Grid container>
            
              <React.Fragment key="card-type">
                <Grid item xs={6}>
                  <Typography gutterBottom>{"Card Type"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{paymentInformation.cardType}</Typography>
                </Grid>
              </React.Fragment>
              <React.Fragment key="card-hold">
                <Grid item xs={6}>
                  <Typography gutterBottom>{"Card holder"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{paymentInformation.nameOnCard}</Typography>
                </Grid>
              </React.Fragment>
              <React.Fragment key="card-number">
                <Grid item xs={6}>
                  <Typography gutterBottom>{"Card number"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{
                  paymentInformation.ccNumber.slice(0, -4).replace(/./g, '*').replace(/(.{4})/g, '$1 ') + paymentInformation.ccNumber.slice(-4)
                  }</Typography>
                </Grid>
              </React.Fragment>
              <React.Fragment key="expiry-date">
                <Grid item xs={6}>
                  <Typography gutterBottom>{"Expiry date"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{paymentInformation.ccExpiration}</Typography>
                </Grid>
              </React.Fragment>              
            
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SummaryForm;