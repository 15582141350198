import React, { useState } from 'react';
import './style.css'
import { useAppDispatch } from '../../app/hooks';
import { setStoreWedding, restartReduxStateToDefault } from '../../features/wedding-state/wedding-state';
import { incremented } from '../../features/step_counter/step-slice';
import docdbrequest from '../../features/apis/docdb'
import alertMethods from '../../features/alerts/alerts.js'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { setPushWedding } from '../../features/user-state/user-state';
import { Link } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';

const successAlert = () => {
  let mensaje = 'Tu evento fue creado exitosamente. Puedes completar tu información en el lado izquierdo'
  alertMethods.generalAlert('Felicidades!', 'success', mensaje, 5000, true)
}

const CreateNew = () => {

  const [wedding, setWedding] = useState({
    boy_name: '',
    boy_last: '',
    girl_name: '',
    girl_last: '',
    wedding_date: '',
    store: 1,
    step: 2,
    template: 1,
    error: false
  });

  const { user } = useAuthenticator((context) => [context.user]);
  const { route } = useAuthenticator(context => [context.route]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (Object.values(wedding).includes('')) {
      setWedding({ ...wedding, error: true })
      return
    }
    dispatch(restartReduxStateToDefault()) // Limpiar state si creo nuevo evento
    let bodyRequest = (JSON.parse(JSON.stringify(wedding)))
    delete bodyRequest.error
    docdbrequest.createDocument(user.username, bodyRequest)
      .then(res => {
        if (res.wedding_id && res.wedding_id !== '') {
          bodyRequest.wedding = res.wedding_id
          dispatch(setStoreWedding(bodyRequest))
          dispatch(setPushWedding({
            "weddingId": res.wedding_id,
            "name": wedding.boy_name + "&" + wedding.girl_name
          }))
          dispatch(incremented())
          successAlert()
        }
      })
  }

  const dispatch = useAppDispatch();
  const text_fields = [
    { name: "boy_name", placeholder: "Nombre del novio" },
    { name: "boy_last", placeholder: "Apellido del novio" },
    { name: "girl_name", placeholder: "Nombre de la novia" },
    { name: "girl_last", placeholder: "Apellido de la novia" },
  ];

  const date_fields = ["wedding_date",]


  return (
    <div id="CreateNew" className="row create-new-event">
      <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
        <div className="personal-form-wrap">
          <div className="col-12">
            <div className="section-title section-title4 text-center">
              <h2>Creemos tu evento juntos</h2>
              <p>Llenemos la siguiente información.</p>
            </div>
          </div>
          <form
            title={(route === 'authenticated') ? "" : "Por favor registrate para crear un evento"}
            onSubmit={submitHandler}
            autoComplete='off'
          >
            <div className="contact-form form-style">
              <div className="row">
                {
                  text_fields.map((newText) => (
                    <div
                      style={(route === 'authenticated') ? {} : { display: 'inline-block', cursor: 'not-allowed' }}
                      key={'div_' + newText.name}
                      className="col-12  col-sm-6">
                      <input type="text"
                        placeholder={newText.placeholder}
                        onChange={(e) => { setWedding({ ...wedding, [newText.name]: e.target.value }) }}
                        value={wedding[newText.name]}
                        id={newText.name}
                        name={newText.name}
                        disabled={(route === 'authenticated') ? false : true}
                      />
                      <p>
                        {
                          (wedding.error && wedding[newText.name] === "")
                            ? "complete " + newText.placeholder + " field" : ''
                        }
                      </p>
                    </div>
                  ))
                }
                {
                  date_fields.map((newDate) => (
                    <div key={'div_' + newDate} className="col-12  col-sm-6">
                      <input type="date"
                        placeholder={newDate}
                        onChange={(e) => { setWedding({ ...wedding, [newDate]: e.target.value }) }}
                        value={wedding[newDate]}
                        id={newDate}
                        name={newDate} />
                      <p>
                        {
                          (wedding.error && wedding[newDate] === "")
                            ? "complete date field" : ''
                        }
                      </p>
                    </div>
                  ))
                }
              </div>
              <div className="col-12 text-center">
                {
                  (route === 'authenticated') ?
                    <button id="submit" type="submit" className="submit">Crear evento</button>
                    : <Link className='mobile-login-account' to='/login'><LoginIcon />{'Login'}</Link>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateNew;
