import imgGallery from '../../../images/gallery-1.jpg';
import imgGallery2 from '../../../images/gallery-2.jpg';
import imgGallery3 from '../../../images/gallery-3.jpg';
import imgGallery4 from '../../../images/gallery-4.jpg';
import imgGallery5 from '../../../images/gallery-5.jpg';
import imgGallery6 from '../../../images/gallery-6.jpg';
import imgGallery7 from '../../../images/gallery-7.jpg';
import imgGallery8 from '../../../images/gallery-8.jpg';
import imgGallery9 from '../../../images/gallery-9.jpg';

const GalleryExample = () => {
  return (
    <div id="fh5co-gallery" className="fh5co-section-gray">
      <div className="container">
        <div className="row row-bottom-padded-md">
          <div className="col-md-12">
            <ul id="fh5co-gallery-list">
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery2})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery3})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery4})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery5})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery6})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery7})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery8})` }}>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn"
                style={{ backgroundImage: `url(${imgGallery9})` }}>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryExample;
