import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom'
import Sectiontitle from '../section-title'
import bride1 from '../../images/groomsmen-bridesmaid/1.jpg'
import './style.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  updateGroomsman,
  updateBridemaids,
  deleteGroomsmanObjet,
  deleteBridemaidsObject,
  updateOldWedding,
  loadingComponent
} from '../../features/wedding-state/wedding-state';
import s3request from '../../features/apis/s3api'
import docdbMethods from '../../features/apis/docdb';

const People = () => {

  const arrPeopleGroomsman = [
    {
      imageSource: bride1,
      name: "Mr Abraham",
      relationship: "Best Friend"
    },
    {
      imageSource: bride1,
      name: "Rohan Mithy",
      relationship: "Brother"
    },
    {
      imageSource: bride1,
      name: "Lily Brown",
      relationship: "Sister"
    },
    {
      imageSource: bride1,
      name: "Mr Abraham",
      relationship: "Best Friend"
    },
    {
      imageSource: bride1,
      name: "Rohan Mithy",
      relationship: "Brother"
    },
    {
      imageSource: bride1,
      name: "Lily Brown",
      relationship: "Sister"
    },
  ]

  const arrPeopleBridesmaids = [
    {
      imageSource: bride1,
      name: "Mr Abraham2",
      relationship: "Best Friend"
    },
    {
      imageSource: bride1,
      name: "Rohan Mithy",
      relationship: "Brother"
    },
    {
      imageSource: bride1,
      name: "Lily Brown",
      relationship: "Sister"
    },
    {
      imageSource: bride1,
      name: "Mr Abraham",
      relationship: "Best Friend"
    },
    {
      imageSource: bride1,
      name: "Rohan Mithy",
      relationship: "Brother"
    },
    {
      imageSource: bride1,
      name: "Lily Brown",
      relationship: "Sister"
    },
  ]

  const saveImages = async (finalname, file) => {
    await s3request.uploadImage(
      finalname,
      file.type,
      file
    )

    const updateObject = await docdbMethods.getDifferenceObj(filePeopleInformation, filePeopleInformation.previousdoc)
    docdbMethods.updateDocument(filePeopleInformation.wedding, updateObject).then(res => {
      dispatch(updateOldWedding(updateObject))
    })
  }

  const handleFileChange = async (e) => {
    try {
      dispatch(loadingComponent(true));
      var obj_file = await s3request.buildObjImage(e, filePeopleInformation.wedding);
      var isBride = e.target.id.includes("bridemaids-fileupload");
      var inputId = (isBride) ? String(e.target.id).replace("bridemaids-fileupload", "") : String(e.target.id).replace("people-fileupload", "");
      var copyArray = (isBride) ? [...filePeopleInformation.bridemaids_file_info] : [...filePeopleInformation.groomsman_file_info];
      copyArray[inputId] = { ...copyArray[inputId], ...obj_file };
      (isBride) ? dispatch(updateBridemaids(copyArray)) : dispatch(updateGroomsman(copyArray));
      saveImages(obj_file.filename, e.target.files[0]);
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(loadingComponent(false));
    }
  }

  const handleChangeText = (e) => {
    var isBride = e.target.id.includes("Bride");
    if (!isBride) {
      let index = 0;
      var arrPeople = [...filePeopleInformation.groomsman_file_info];
      if (e.target.id.includes("Groomsname_")) {
        index = String(e.target.id).replace("Groomsname_", '');
        arrPeople[index] = { ...arrPeople[index], name: e.target.value };
        dispatch(updateGroomsman(arrPeople));
      }
      else {
        index = String(e.target.id).replace("Groomship_", '');
        arrPeople[index] = { ...arrPeople[index], relationship: e.target.value };
        dispatch(updateGroomsman(arrPeople));
      }
    } else {
      let arrPeople = [...filePeopleInformation.bridemaids_file_info];
      if (e.target.id.includes("Bridesname_")) {
        let index = String(e.target.id).replace("Bridesname_", '');
        arrPeople[index] = { ...arrPeople[index], name: e.target.value };
        dispatch(updateBridemaids(arrPeople))
      }
      else {
        let index = String(e.target.id).replace("Brideship_", '');
        arrPeople[index] = { ...arrPeople[index], relationship: e.target.value };
        dispatch(updateBridemaids(arrPeople))
      }
    }
  }

  const addingNewGroomsman = () => {
    let counter = filePeopleInformation.groomsman_file_info.length;
    let copyArray = [...filePeopleInformation.groomsman_file_info]
    let newGroomsmanObject = {
      filename: "",
      url: "",
      contenttype: "",
      name: "",
      relationship: "",
      index: counter
    }
    copyArray.push(newGroomsmanObject)
    dispatch(updateGroomsman(copyArray))

  }

  const addingNewBridesmaids = () => {
    let counterb = filePeopleInformation.bridemaids_file_info.length;
    let copyArray = [...filePeopleInformation.bridemaids_file_info]
    let newBridesmaidsObject = {
      filename: "",
      url: "",
      contenttype: "",
      name: "",
      relationship: "",
      index: counterb
    }
    copyArray.push(newBridesmaidsObject)
    dispatch(updateBridemaids(copyArray))
  }

  const deletePeople = (index) => {
    dispatch(deleteGroomsmanObjet(index))
  }

  const deleteBridesmaids = (index) => {
    dispatch(deleteBridemaidsObject(index))
  }

  const location = useLocation()

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const dispatch = useAppDispatch();
  const filePeopleInformation = useAppSelector((state) => state.wedding)

  return (

    <div id="people" className="person-area section-padding pb-70">
      <div className="container">
        <Sectiontitle section={"Caballeros y Damas de Honor"} />
        <div className="person-area-menu">
          <div className="person-btn h-groomsmen-bridesmaid-mob">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  Caballeros
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  Damas
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="Groomsman-wrap h-groomsmen-bridesmaid">
                {filePeopleInformation.groomsman_file_info[0].filename !== "" || location.pathname === '/Admin' ? (
                  <div className="row">
                    {filePeopleInformation.groomsman_file_info.map((objeto, index) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={`peopleboys-${index}`}>
                        <div className="person-wrap">
                          <div className="person-img">
                            <img src={(objeto.url) ? objeto.url : bride1}
                              alt="groomimg"
                            />
                            <div className="social-list">
                              <ul>
                                {location.pathname === '/Admin' ? (
                                  <li>
                                    <label htmlFor={"people-fileupload" + index}
                                      title="Selecciona la foto">
                                      <i className="picload fa fa-upload"></i>
                                    </label>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id={'people-fileupload' + index}
                                      onChange={(e) => handleFileChange(e)}
                                    />
                                  </li>) : ""}
                                <li><Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                                <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                {location.pathname === '/Admin' ? (
                                  <li>
                                    <Link
                                      title="Agrega a un nuevo padrino"
                                      onClick={() => addingNewGroomsman()}>
                                      <i className="picload"></i>+</Link>
                                  </li>) : ""}
                                {location.pathname === '/Admin' ? (
                                  <li>
                                    <Link
                                      title="Eliminar padrino"
                                      onClick={() => deletePeople(index)}>
                                      <i className="picload"></i>x</Link>
                                  </li>) : ""}
                              </ul>
                            </div>
                          </div>
                          <div className="person-content">

                            {location.pathname === '/Admin' ? (
                              <input
                                autoComplete="off"
                                placeholder="Nombre del Padrino"
                                type='text'
                                id={`Groomsname_${index}`}
                                onChange={(e) => handleChangeText(e)}
                                value={(objeto.name) ? objeto.name : ''}
                                className="form-control"
                              />) :
                              (<div className="person-content">
                                <h3>{objeto.name}</h3>
                              </div>)}
                            {location.pathname === '/Admin' ? (<input
                              autoComplete="off"
                              placeholder="Que parentesco tiene con el prometido"
                              type="text"
                              id={`Groomship_${index}`}
                              onChange={(e) => handleChangeText(e)}
                              value={(objeto.relationship) ? objeto.relationship : ''}
                              className="form-control"
                            />) :
                              (<div className="person-content">
                                <span>{objeto.relationship}</span>
                              </div>)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (<div className="row">
                  {arrPeopleGroomsman.map((objeto, index) => (
                    <div className="col-lg-4 col-md-6" key={`peopleboys-${index}`}>
                      <div className="person-wrap">
                        <div className="person-img">
                          <img src={objeto.imageSource}
                            alt="arraygrooms" />
                          <div className="social-list">
                            <ul>
                              <li><Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                              <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                            </ul>
                          </div>
                        </div>
                        <div className="person-content">
                          <h3>{objeto.name}</h3>
                          <span>{objeto.relationship}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>)}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="Ceremony-wrap">
                {filePeopleInformation.bridemaids_file_info[0].filename !== "" || location.pathname === '/Admin' ? (
                  <div className="row">
                    {filePeopleInformation.bridemaids_file_info.map((objeto, index) => (
                      <div className="col-lg-4 col-md-6" key={`peoplegirls-${index}`}>
                        <div className="person-wrap">
                          <div className="person-img">
                            <img src={(objeto.url) ? objeto.url : bride1}
                              alt="brideimg" />
                            <div className="social-list">
                              <ul>
                                {location.pathname === '/Admin' ? (<li>
                                  <label htmlFor={"bridemaids-fileupload" + index}
                                    title="Selecciona la foto">
                                    <i className="picload fa fa-upload"></i>
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id={"bridemaids-fileupload" + index}
                                    onChange={(e) => handleFileChange(e)}
                                  />
                                </li>) : ""}
                                <li><Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                                <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                {location.pathname === '/Admin' ? (<li>
                                  <Link
                                    title="Agrega a una nueva madrina"
                                    onClick={() => addingNewBridesmaids()}>
                                    <i className="pickload"></i>+</Link>
                                </li>) : ""}
                                {location.pathname === '/Admin' ? (<li>
                                  <Link
                                    title="Eliminar madrina"
                                    onClick={() => deleteBridesmaids(index)}>
                                    <i className="picload"></i>x</Link>
                                </li>) : ""}
                              </ul>
                            </div>
                          </div>
                          <div className="person-content">
                            {location.pathname === '/Admin' ? (<input
                              autoComplete='off'
                              placeholder='Nombre de la Madrina'
                              type='text'
                              id={`Bridesname_${index}`}
                              onChange={(e) => handleChangeText(e)}
                              value={(objeto.name) ? objeto.name : ''}
                              className='form-control'
                            />) :
                              (<div className="person-content">
                                <h3>{objeto.name}</h3>
                              </div>)
                            }
                            {location.pathname === '/Admin' ? (<input
                              autoComplete='off'
                              placeholder='Que parentesco tiene con la prometida'
                              type='text'
                              id={`Brideship_${index}`}
                              onChange={(e) => handleChangeText(e)}
                              value={(objeto.relationship) ? objeto.relationship : ''}
                              className="form-control"
                            />) :
                              (<div className="person-content">
                                <span>{objeto.relationship}</span>
                              </div>)
                            }
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (<div className="row">
                  {arrPeopleBridesmaids.map((objeto, index) => (
                    <div className="col-lg-4 col-md-6" key={`peoplegirls-${index}`}>
                      <div className="person-wrap">
                        <div className="person-img">
                          <img src={objeto.imageSource}
                            alt="arraybrides" />
                          <div className="social-list">
                            <ul>
                              <li><Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                              <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                            </ul>
                          </div>
                        </div>
                        <div className="person-content">
                          <h3>{objeto.name}</h3>
                          <span>{objeto.relationship}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>)}
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>

  )
}

export default People;
