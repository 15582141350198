import { useAppSelector } from '../../app/hooks';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material'

const BestWishes = () => {
  const [fechaActual, setFechaActual] = useState('');

  useEffect(() => {
    const fecha = new Date();
    const opcionesFormato = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const fechaFormateada = new Intl.DateTimeFormat('en-US', opcionesFormato).format(fecha);
    setFechaActual(fechaFormateada);
  }, []);

  const weddingWishes = useAppSelector((state) => state.wedding);

  return (
    <div id="home" className="BlogPage-br">
      <div className="blog-pg-section blog-single-section">
        <div className="container">
          <div className="row">
            <div className="col col-lg-8 col-12 col-12">
              <div className="blog-posts blog-posts-2 clearfix">
                <div className="comments-area">
                  <div className="comments-section">
                    <h3
                      className="comments-title"
                      style={{ color: 'white' }}
                    >Mejores deseos</h3>
                    <ol className="comments">
                      <Carousel>
                        {
                          weddingWishes.guests.filter(guest => guest.confirmed === true).map((guest, index) =>
                            <Paper key={`wish_${index}`}>
                              <li key={`wish_${index}`} className="comment even thread-even depth-1" id="comment-1">
                                <div id="div-comment-1">
                                  <div className="comment-theme">
                                    <div className="comment-image">
                                      <Stack direction="row" spacing={2}>
                                        <Avatar alt="Remy Sharp" src={`https://robohash.org/${guest.name}?set=set4`} />
                                      </Stack>
                                    </div>
                                  </div>
                                  <div className="comment-main-area">
                                    <div className="comment-wrapper">
                                      <div className="comments-meta">
                                        <h4>{guest.name} <span className="comments-date">{fechaActual}</span></h4>
                                      </div>
                                      <div className="comment-area">
                                        <p>{guest.notes}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </Paper>
                          )
                        }
                      </Carousel>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BestWishes;
