import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GuestState {
  name: string;
  rsvp: string;
  confirmed: boolean;
  notes: string;
  phone: string;
  error: {},
  celebration: boolean,
  guestNames: object[],
}

const initialState: GuestState = {
  name: '',
  rsvp: '',
  confirmed: false,
  notes: '',
  phone: '',
  error: {},
  celebration: false,
  guestNames: [],
}

interface SetGuest {
  key: string;
  value: string | number | boolean | object[];
}

const guestStateSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    setGuestInfo(state, action: PayloadAction<SetGuest>) {
      const key = action.payload.key;
      if (key === 'name') {
        state.name = action.payload.value as string;
      }else if (key === 'rsvp') {
        state.rsvp = action.payload.value as string;
      }else if (key === 'confirmed') {
        state.confirmed = action.payload.value as boolean;
      }else if (key === 'notes') {
        state.notes = action.payload.value as string;
      }else if (key === 'phone') {
        state.phone = action.payload.value as string;
      }else if (key === 'guestNames') {
        state.guestNames = action.payload.value as object[];
      }
    },
    updateCelebration: (state, action: PayloadAction<boolean>) => {
      state.celebration = action.payload;
    },
  },
});

export const {
  setGuestInfo,
  updateCelebration,
} = guestStateSlice.actions;
export default guestStateSlice.reducer;
