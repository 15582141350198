
const StoryComponent = ({ storyObject, index }) => {
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formatDate = (dateString) => {
    const arrDates = dateString.split('-');
    return `${monthNames[parseInt(arrDates[1]) - 1]} ${arrDates[2]}, ${arrDates[0]}`
  }

  return (
    <div id={'storyComponente' + index} key={'storyComponente' + index} className={`row timeline-element separline ${(!(index % 2)) ? 'reverse' : ''}`}>
      <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
        <div className="time-line-date-content">
          <p className="mbr-timeline-date mbr-fonts-style display-font">
            {
              (storyObject.date)
                ? formatDate(storyObject.date)
                : formatDate("04-04-2025")
            }
          </p>
        </div>
      </div>
      <span className="iconBackground"></span>
      <div className={`col-xs-12 col-md-6 ${(!(index % 2)) ? 'align-right' : 'align-left'}`}>
        <div className="timeline-text-content">
          <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-font">
            {(storyObject.tittle) ? storyObject.tittle : 'Primera Cita'}
          </h4>
          <p className="mbr-timeline-text mbr-fonts-style display-7">
            {
              (storyObject.speech)
                ? storyObject.speech
                : 'We met at the wedding of our close friends and immediately found a common language, so a year later our first date happened.'
            }
          </p>
        </div>
      </div>
    </div>
  )
};

export default StoryComponent;