import {
  updateCoupleBoyfriendImage,
  updateCoupleGirlfriendImage,
  updateCouplepictureimg,
  updateFrontImage,
  updateTimeline,
  updateCeremonyImage,
  updateReceptionImage,
  updateGiftImage,
  updateWelcomeImage,
  updateFootImage,
  updateCountdownImage,
  updateAlbumImage,
  updateGroomsman,
  updateBridemaids,
  loadingComponent,
} from '../../features/wedding-state/wedding-state';

const getImagesFromArray = async (fileArray, methodDispatch, dispatch) => {
  var copyFileArray = await Promise.all(fileArray.map(async (component, index) => (
    await getObjFile(
      component.filename,
      methodDispatch,
      component.url,
      dispatch,
      index,
      { ...component }
    ))));
  if (
    fileArray[0].filename !== "" 
    && (!fileArray[0].url || !fileArray[fileArray.length-1].url) 
    ) {
    dispatch(methodDispatch(copyFileArray));
  }
}

const getSectionByURL = async (fileInformation, amount, dispatch) => {
  switch (amount) {
    case 2:
      await getImagesFromArray(fileInformation.front_file_info, updateFrontImage, dispatch);
      break;
    case 4:
      await getImagesFromArray(fileInformation.couple_boy_file, updateCoupleBoyfriendImage, dispatch);
      await getImagesFromArray(fileInformation.couple_girl_file, updateCoupleGirlfriendImage, dispatch);
      await getImagesFromArray(fileInformation.couple_pick_file, updateCouplepictureimg, dispatch);
      break;
    case 5:
      await getImagesFromArray(fileInformation.welcome_file, updateWelcomeImage, dispatch);
      break;
    case 6:
      await getImagesFromArray(fileInformation.timeline_file_info, updateTimeline, dispatch);
      break;
    case 7:
      await getImagesFromArray(fileInformation.groomsman_file_info, updateGroomsman, dispatch);
      await getImagesFromArray(fileInformation.bridemaids_file_info, updateBridemaids, dispatch);
      break;
    case 9:
      await getImagesFromArray(fileInformation.album_file_info, updateAlbumImage, dispatch);
      break;
    case 12:
      await getObjFile(
        fileInformation.foot_filename,
        updateFootImage,
        fileInformation.foot_file_url,
        dispatch,
      )
      break;
    case 14:
      await getImagesFromArray(fileInformation.countdown_file, updateCountdownImage, dispatch);
      break;
    case 15:
      await getImagesFromArray(fileInformation.ceremony_file, updateCeremonyImage, dispatch);
      break;
    case 16:
      await getImagesFromArray(fileInformation.reception_file, updateReceptionImage, dispatch);
      break;
    case 17:
      await getImagesFromArray(fileInformation.gift_file, updateGiftImage, dispatch);
      break;
    default:
      break;
  }
  dispatch(loadingComponent(false))
}

const getObjFile = async (filename, dispatchMethod, urlresult, dispatchSource, index = -1, obj = {}) => {
  if (filename === "" || (urlresult !== "" && urlresult !== undefined)) return obj;
  let fileUrl = "https://img.invitegt.com/" + filename;
  obj.filename = filename;
  obj.url = fileUrl;
  if (index !== -1) return obj;
  obj.index = index;
  dispatchSource(dispatchMethod(obj));
}

const getAllSections = async (fileInformation, dispatch) => {
  for (const amount of [2, 4, 5, 6, 7, 8, 9, 12, 14, 15, 16, 17]) {
    await getSectionByURL(fileInformation, amount, dispatch);
  }
}

const sectionsImages = { getSectionByURL, getAllSections };

export default sectionsImages;
