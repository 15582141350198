import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateAlbumImage, updateOldWedding, loadingComponent } from '../../../features/wedding-state/wedding-state';
import s3request from '../../../features/apis/s3api';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import Tooltip from '@mui/material/Tooltip'

const successAlert = () => {
  let mensaje = 'Cambios guardados correctamente.'
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const AdminSidebarAlbum = () => {

  const saveAlbum = async () => {
    const updateObj = await docdbMethods.getDifferenceObj(albumSection, albumSection.previousdoc)
    docdbMethods.updateDocument(albumSection.wedding, updateObj).then(res => {
      dispatch(updateOldWedding(updateObj))
      successAlert()
    })
  }

  const uploadImageWedding = async (filename, file) => {
    await s3request.uploadImage(
      filename,
      file.type,
      file
    )
    const updateObject = await docdbMethods.getDifferenceObj(albumSection, albumSection.previousdoc)
    docdbMethods.updateDocument(albumSection.wedding, updateObject).then(res => {
      dispatch(updateOldWedding(updateObject))
    })
  }

  const albumUpdateFile = async (e) => {
    try {
      dispatch(loadingComponent(true))
      var obj_file = await s3request.buildObjImage(e, albumSection.wedding);
      var inputId = String(e.target.id).replace("album-fileupload", "");
      var copyArray = [...albumSection.album_file_info];
      copyArray[inputId] = { ...copyArray[inputId], ...obj_file };
      dispatch(updateAlbumImage(copyArray));
      uploadImageWedding(obj_file.filename, e.target.files[0]);
    } catch (error) {
      console.log(error);
      errorAlert('Error al subir la imagen, intente de nuevo.');
    } finally {
      dispatch(loadingComponent(false));
    }
  }

  const addingNewPhoto = () => {
    let counter = albumSection.album_file_info.length;
    let copyArray = [...albumSection.album_file_info]
    let newAlbumObject = {
      filename: "",
      url: "",
      contenttype: "",
      index: counter
    }
    copyArray.push(newAlbumObject)
    dispatch(updateAlbumImage(copyArray))
  }

  const albumSection = useAppSelector((state) => (state.wedding));
  const dispatch = useAppDispatch();

  return (
    <div className="form-wrap">
      <form defaultValue="Valor predeterminado">
        {
          albumSection.album_file_info.map((component, index) => (
            <div style={{ display: 'flex' }} key={'add-album-img' + index}>
              <input
                style={{ width: '185px' }}
                type="text" disabled className="form-control" placeholder="Select image for album"
                value={(component.filename)
                  ? component.filename.split('\\').pop().split('/').pop()
                  : ""}
              />
              <label htmlFor={"album-fileupload" + index} className="custom-file-upload">
                <i className="fa fa-upload"></i>
              </label>
              <input id={"album-fileupload" + index} type="file" accept="image/*" className="form-control"
                onChange={(e) => albumUpdateFile(e)}
              />
            </div>
          ))
        }
        <br></br>
        <div className="text-center">
          <button
            title="Agregar nueva foto"
            id="newPhoto"
            type="button"
            style={{ position: "relative", borderRadius: "50%" }}
            onClick={() => addingNewPhoto()}
            disabled={albumSection.album_file_info.length >= 12 ? true : false}
          ><i className="i-btn fa fa-plus"></i>
          </button>
        </div>
      </form>
      <br></br>
      <div className="text-center">
        <Tooltip
          title="Guardar cambios"
        >
          <CloudUploadTwoToneIcon
            onClick={() => saveAlbum()}
            style={{ cursor: 'pointer', color: '#649E93', fontSize: '45px' }}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default AdminSidebarAlbum
